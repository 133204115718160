import { twMerge } from 'tailwind-merge';

export const IconTable = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.1796 8.82031H8.82025C7.30228 8.82031 6.0603 10.0623 6.0603 11.5803V36.4197C6.0603 37.9377 7.30228 39.1797 8.82025 39.1797H39.1796C40.6976 39.1797 41.9396 37.9377 41.9396 36.4197V11.5803C41.9396 10.0623 40.6976 8.82031 39.1796 8.82031Z" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.0603 14.3401H41.9396" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.0603 22.6201H41.9396" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.0603 30.8999H41.9396" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.48 14.3401V39.1796" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.8997 14.3401V39.1796" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

