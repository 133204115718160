import ImagesView from './ImagesView';
import ImagesEdit from './ImagesEdit';
import imageMosaicIcon from '../../assets/icons/imageMosaic.svg';

const config = {
  id: 'imageMosaic',
  title: 'Image Mosaic',
  group: 'media',
  view: ImagesView,
  edit: ImagesEdit,
  icon: imageMosaicIcon,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
};

export default config;
