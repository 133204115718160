//@ts-check
import React from 'react';
import {
  EnsinoInstitucionalContainer,
  PesquisaExtensaoContainer,
  PesquisaInstitucionalContainer,
} from './ContainerTypes';
import { twMerge } from 'tailwind-merge';

/**
 * @typedef {import('./types').Sizes} ContainerSize
 * @typedef {object} CustomContainerProps
 * @returns {React.JSX.Element}
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {React.HTMLAttributes<HTMLDivElement>["className"]} props.className
 * @param {ContainerSize} props.size - the size of container (its based in the nav bar)
 * @param {boolean} [props.alignCenter]
 * @param {boolean} [props.insideContainerFull] - if it's true, the custom-container width will be set to 100% if the custom-container is inside another custom-container
 */
export const CustomContainer = (props) => {
  const className = twMerge(
    props.className,
    props.insideContainerFull
      ? 'custom-container-inside-custom-container-always-w-full'
      : '',
  );

  switch (props.size) {
    case 'ensino-institucional':
      return (
        <EnsinoInstitucionalContainer
          className={className}
          alignCenter={props.alignCenter}
        >
          {props.children}
        </EnsinoInstitucionalContainer>
      );
    case 'pesquisa-extensao':
      return (
        <PesquisaExtensaoContainer
          className={className}
          alignCenter={props.alignCenter}
        >
          {props.children}
        </PesquisaExtensaoContainer>
      );
    case 'pesquisa-institucional':
      return (
        <PesquisaInstitucionalContainer
          className={className}
          alignCenter={props.alignCenter}
        >
          {props.children}
        </PesquisaInstitucionalContainer>
      );
    case 'full-width':
      return (
        <div className={twMerge('w-full max-w-[100vw]', className)}>
          {props.children}
        </div>
      );
  }
  return (
    <EnsinoInstitucionalContainer
      className={className}
      alignCenter={props.alignCenter}
    >
      {props.children}
    </EnsinoInstitucionalContainer>
  );
};
