export const geralSchema = () => ({
  title: 'Botão de destaque',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text', 'link', 'container'],
    },
  ],
  properties: {
    text: {
      title: 'Texto do botão:',
      type: 'string',
      widget: 'richtext',
    },
    link: {
      title:
        'Link associado (opcional; coloque // no começo para que o caminho seja absoluto):',
      mode: 'link',
    },
    container: {
      title: 'tamanho do botão',
      widget: 'select',
      choices: [
        ['ensino-institucional', '1'],
        ['pesquisa-institucional', '2'],
        ['pesquisa-extensao', '3'],
      ],
    },
  },
  required: ['text'],
});
