import { twMerge } from 'tailwind-merge';

export const IconR = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.574 27.0112H21.2379C19.581 27.0112 18.2379 28.3544 18.2379 30.0112V34.2846C18.2379 35.9414 16.8948 37.2846 15.2379 37.2846H13.7986C12.1417 37.2846 10.7986 35.9414 10.7986 34.2846V24.3432C10.7986 22.6863 12.1417 21.3432 13.7986 21.3432H19.3007H27.0941M21.0719 27.3655L29.7359 38.2757C30.305 38.9924 31.1701 39.4101 32.0853 39.4101H33.5537C36.137 39.4101 37.5124 36.3623 35.8032 34.4252L29.574 27.3655M24.2602 21.3432L22.4394 19.2011C20.7832 17.2526 22.168 14.2581 24.7252 14.2581H26.3856M16.1124 21.3432L11.3986 15.0581C11.0091 14.5388 10.7986 13.9072 10.7986 13.2581V11.5901C10.7986 9.93323 12.1417 8.59009 13.7986 8.59009H20.7177H25.6772H28.157" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M28.1501 27.0112C33.824 27.0112 38.4235 22.8875 38.4235 17.8007C38.4235 12.7138 33.824 8.59009 28.1501 8.59009" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.0872 21.3431C29.4349 21.3431 31.3382 19.7571 31.3382 17.8006C31.3382 15.8441 29.4349 14.2581 27.0872 14.2581" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

