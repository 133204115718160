import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import { DividerView } from './View';
import { DividerData } from './Data';

export const DividerEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <div className="p-8">
        <DividerView {...props} isEditMode={true} />
      </div>
      <SidebarPortal selected={selected}>
        <DividerData {...props} />
      </SidebarPortal>
    </>
  );
};