import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import SubscribeButtonView from './SubscribeButtonView';
import SubscribeButtonBlockData from './SubscribeButtonData';

const SubscribeButtonEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <div className="p-8 bg-slate-300">
        <SubscribeButtonView {...props} isEditMode={true} />
      </div>
      <SidebarPortal selected={selected}>
        <SubscribeButtonBlockData {...props} />
      </SidebarPortal>
    </>
  );
};

export default SubscribeButtonEdit;