import { twMerge } from 'tailwind-merge';

export const IconLoader = ({
  className,
  changeColorOnHover = false,
  color = '#005DB7',
  size = '48',
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={twMerge(className, 'dark:[&_path]:stroke-[#A9C7FF]')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m24,9.33l0,-7.33"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="m34.39,13.62l5.26,-5.26"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="m38.67,24l7.33,0"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="m34.39,34.39l5.26,5.26"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="m24,38.67l0,7.33"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="m13.61,34.39l-5.26,5.26"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="m9.33,24l-7.33,0"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="m13.61,13.62l-5.26,-5.26"
        stroke={color}
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};
