import { SearchWidget } from '@plone/volto/components';

export const SearchMobile = ({ onSearch }) => {



  return (
    <div className="w-full max-w-lg mx-auto">
      < SearchWidget className="border-solid border-[#FFBE00] border-2" setFn={onSearch} />
    </div>
  )
}