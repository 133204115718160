//@ts-check
import React from 'react';

/**
 *
 * @param {{hidden:boolean}} props
 */
export const InstagramWindow = (props) => {
  return (
    <>
      <iframe
        width={500}
        height={535}
        src="https://www.instagram.com/utfpr_/embed"
        className={`${props.hidden ? 'hidden' : ''} mx-auto`}
      ></iframe>
    </>
  );
};
