import View from './View.jsx';
import Edit from './Edit';
import icon from '@plone/volto/icons/spacebar.svg';

export default {
  id: 'allCampis',
  title: 'All Campis',
  group: 'media',
  view: View,
  edit: Edit,
  icon: icon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
