//@ts-check
import React from 'react';
import { CellTypesConfig } from './CellTypes';
/**
 * @param {import('./types').CellProps} props
 */
export const CellView = (props) => {
  if (CellTypesConfig[props.type]) {
    // getting cell View
    const View = CellTypesConfig[props.type].View;

    return <View {...props}></View>;
  }

  // um retorno padrão
  return <CellTypesConfig.text.View {...props}></CellTypesConfig.text.View>;
};
