import React, { useState } from 'react';
import { Checkbox, Header, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { clearFilters } from './FilterList';
import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from './base';
import minimize from '../../../../../icons/minimize.svg';
import maximize from '../../../../../icons/maximize.svg';
import { height } from 'dom7';

/**
 * A facet that uses radio/checkboxes to provide an explicit list of values for
 * filtering
 */
const CheckboxFacet = (props) => {
  const { facet, choices, isMulti, onChange, value, isEditMode } = props;
  const facetValue = value;
  const [hidden, setHidden] = useState(false);

  const toggleContent = () => {
    setHidden(!hidden);
  };

  return (
    <div className="checkbox-facet dark:[&_*]:text-white dark:[&_path]:stroke-white dark:hover:[&_button.button]:bg-[inherit]">
      <div className="flex row w-full justify-between mb-[14px]">
        <h4 className="text-[14px] font-bold">
          {facet.title ?? facet?.field?.label}{' '}
        </h4>
        <button onClick={toggleContent}>
          <Icon
            style={{ width: '12px', height: '12px' }}
            name={hidden ? maximize : minimize}
          />
        </button>
      </div>
      {!hidden && (
        <div className="entries flex flex-col gap-2.5">
          {choices.map(({ label, value }, i) => (
            <div
              className="entry text-[14px] font-normal dark:[&_label]:text-white hover:dark:[&_label]:text-white"
              key={value}
            >
              <Checkbox
                disabled={isEditMode}
                label={label}
                radio={!isMulti}
                checked={
                  isMulti
                    ? !!facetValue?.find((f) => f.value === value)
                    : facetValue && facetValue.value === value
                }
                onChange={(e, { checked }) =>
                  onChange(
                    facet.field.value,
                    isMulti
                      ? [
                          ...facetValue
                            .filter((f) => f.value !== value)
                            .map((f) => f.value),
                          ...(checked ? [value] : []),
                        ]
                      : checked
                      ? value
                      : null,
                  )
                }
              />
            </div>
          ))}
          <div
            className="w-full h-px"
            style={{ backgroundColor: '#DFE0E7' }}
          ></div>
        </div>
      )}
    </div>
  );
};

CheckboxFacet.schemaEnhancer = selectFacetSchemaEnhancer;
CheckboxFacet.stateToValue = selectFacetStateToValue;
CheckboxFacet.valueToQuery = selectFacetValueToQuery;

export default CheckboxFacet;
