import { twMerge } from 'tailwind-merge';

export const IconRecycling = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.5834 35.0832L36.5243 35.0832C36.8251 35.0845 37.1209 35.0065 37.3819 34.8569C37.6429 34.7074 37.8598 34.4916 38.0107 34.2315C38.1617 33.9713 38.2414 33.6759 38.2417 33.3751C38.242 33.0743 38.163 32.7787 38.0126 32.5182L34.5134 26.6124" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4501 23.0341L9.98758 32.5341C9.83736 32.7911 9.75749 33.0831 9.75602 33.3807C9.75455 33.6784 9.83154 33.9712 9.97922 34.2296C10.1269 34.4881 10.3401 34.7031 10.5973 34.8529C10.8545 35.0028 11.1466 35.0822 11.4442 35.0833L17.6667 35.0041" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.9033 20.0891L25.425 10.5891C25.2748 10.3294 25.059 10.1137 24.7991 9.96381C24.5393 9.81388 24.2446 9.73495 23.9446 9.73495C23.6446 9.73495 23.3498 9.81388 23.09 9.96381C22.8301 10.1137 22.6143 10.3294 22.4642 10.5891L19.1075 16.5899" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.75 38.25L25.5833 35.0833L28.275 31.9167" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.1274 24.1901L15.4499 23.0343L16.8433 26.9609" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.0591 15.7666L30.9032 20.0891L26.8182 19.3449" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

