//@ts-check
import React, { useEffect } from 'react';

/**
 *
 * @param {{hidden:boolean,postLink?:string,editable}} props
 */
export const XWindow = (props) => {
  useEffect(() => {
    const xScript = createXScript();

    document.body.appendChild(xScript);

    return () => {
      document.body.removeChild(xScript);
    };
  }, []);

  const statusID = getLinkStatus(props.postLink),
    profile = getLinkProfile(props.postLink);

  // src for the "main" code https://publish.twitter.com/#

  return (
    <div
      className={`w-full h-full flex justify-center ${
        props.hidden ? '!hidden' : ''
      }`}
    >
      <blockquote className="twitter-tweet ">
        <p lang="pt" dir="ltr"></p>
        &mdash; {profile} (@{profile})
        <a
          href={`https://twitter.com/${profile}/status/${statusID}?ref_src=twsrc%5Etfw`}
        ></a>
      </blockquote>
    </div>
  );
};

/**
 * @param {string|undefined} link
 * @returns {string}
 */
function getLinkStatus(link) {
  const defaultStatus = '1744701479909224599';

  if (!link) return defaultStatus;

  const splitLink = link.split('/status/');
  if (splitLink.length != 2) return defaultStatus;

  const linkEnd = splitLink[1];

  const status = linkEnd.match(/^[0-9]+/);

  return status[0];
}

/**
 *
 * @param {string|undefined} link
 * @returns {string}
 */
function getLinkProfile(link) {
  const defaultProfile = 'utfpr_';

  if (!link) return defaultProfile;

  const splitLink = link.split('.com/');

  if (splitLink.length != 2) return defaultProfile;

  const linkEnd = splitLink[1];

  const profile = linkEnd.split('/')[0];

  return profile;
}

function createXScript() {
  const xScript = document.createElement('script');

  xScript.src = 'https://platform.twitter.com/widgets.js';

  xScript.async = true;

  xScript.crossOrigin = '';

  return xScript;
}
