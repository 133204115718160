import { twMerge } from 'tailwind-merge';

export const IconInternet = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.9999 39.149C32.3665 39.149 39.149 32.3665 39.149 23.9999C39.149 15.6333 32.3665 8.85083 23.9999 8.85083C15.6333 8.85083 8.85083 15.6333 8.85083 23.9999C8.85083 32.3665 15.6333 39.149 23.9999 39.149Z" stroke={color} strokeWidth="2.5" />
      <path d="M24 8.66821C26.2161 10.6044 27.9956 12.9894 29.2205 15.6651C30.4454 18.3408 31.0879 21.2463 31.1053 24.189C31.1053 34.0548 26.6691 36.9859 24 38.9602" stroke={color} strokeWidth="2.5" />
      <path d="M24.0001 8.66821C21.7806 10.6027 19.9977 12.9869 18.7696 15.6627C17.5415 18.3386 16.8962 21.2449 16.8765 24.189C16.8765 34.0548 21.3127 36.9859 23.9818 38.9602" stroke={color} strokeWidth="2.5" />
      <path d="M10.8193 16.5352H37.254" stroke={color} strokeWidth="2.5" />
      <path d="M24 8.66821V38.9602" stroke={color} strokeWidth="2.5" />
      <path d="M11.2336 32.123H36.7847" stroke={color} strokeWidth="2.5" />
      <path d="M8.85083 24.1888L39.149 23.811" stroke={color} strokeWidth="2.5" />
    </svg>
  )
}

