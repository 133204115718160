import React, { useEffect } from 'react';

export default function BarraGov() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://barra.brasil.gov.br/barra_2.0.js';
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div
      id="barra-brasil"
      className="container-fluid"
      style={{
        backgroundColor: '#7F7F7F',
        height: '20px',
        padding: '0 0 0 10px',
        display: 'block',
      }}
    >
      <ul id="menu-barra-temp" style={{ listStyle: 'none' }}>
        <li
          style={{
            display: 'inline',
            float: 'left',
            paddingRight: '10px',
            marginRight: '10px',
            borderRight: '1px solid #EDEDED',
          }}
        >
          <a
            href="http://brasil.gov.br"
            style={{
              fontFamily: 'sans, sans-serif',
              textDecoration: 'none',
              color: 'white',
            }}
          >
            Portal do Governo Brasileiro
          </a>
        </li>
      </ul>
    </div>
  );
}
