import { twMerge } from 'tailwind-merge';

export const IconMicrophone = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 33.9643C20.95 33.9643 18.0249 32.6034 15.8683 30.1811C13.7116 27.7587 12.5 24.4733 12.5 21.0476M24 33.9643C27.05 33.9643 29.9751 32.6034 32.1317 30.1811C34.2884 27.7587 35.5 24.4733 35.5 21.0476M24 33.9643V39.5M19.0714 14.0357C19.0714 12.5676 19.5907 11.1595 20.515 10.1214C21.4393 9.08323 22.6929 8.5 24 8.5C25.3071 8.5 26.5607 9.08323 27.485 10.1214C28.4093 11.1595 28.9286 12.5676 28.9286 14.0357V23.2619C28.9286 24.7301 28.4093 26.1381 27.485 27.1762C26.5607 28.2144 25.3071 28.7976 24 28.7976C22.6929 28.7976 21.4393 28.2144 20.515 27.1762C19.5907 26.1381 19.0714 24.7301 19.0714 23.2619V14.0357Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

