import React from 'react';
import { CustomContainer } from '../general';

export const DividerView = (props) => {
  const { data } = props;

  return (
    <CustomContainer
      size={translateToSize(data.container)}
      alignCenter={false}
      className="mx-auto"
    >
      <hr className={`border-t-[#C3C6CF] border-t w-full`} />
    </CustomContainer>
  );
};

/**
 *
 * @param {string} size
 * @returns {'ensino-institucional'|'full-width'|'pesquisa-extensao'|'pesquisa-institucional'}
 */
function translateToSize(size) {
  switch (size) {
    case '0':
      return 'full-width';
    case '1':
      return 'ensino-institucional';
    case '2':
      return 'pesquisa-institucional';
    case '3':
      return 'pesquisa-institucional';
  }
  return 'ensino-institucional';
}
