import React from 'react';
import { Button } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import { compose } from 'redux';
import { Icon } from '@plone/volto/components';
import {
  Option,
  DropdownIndicator,
} from '@plone/volto/components/manage/Widgets/SelectStyling';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { selectTheme, sortOnSelectStyles } from './SelectStyling';

import upSVG from '@plone/volto/icons/sort-up.svg';
import { IconFilterUp } from '../../../../../../addons/utfpr_components/src/assets/icons/buttons/IconFilterUp';

import { IconFilterDown } from '../../../../../../addons/utfpr_components/src/assets/icons/buttons/IconFilterDown';
import downSVG from '@plone/volto/icons/sort-down.svg';

const messages = defineMessages({
  noSelection: {
    id: 'No selection',
    defaultMessage: 'No selection',
  },
  sortOn: {
    id: 'Sort on',
    defaultMessage: 'Sort on',
  },
  ascending: {
    id: 'Ascending',
    defaultMessage: 'Ascending',
  },
  descending: {
    id: 'Descending',
    defaultMessage: 'Descending',
  },
});

const SortOn = (props) => {
  const {
    data = {},
    reactSelect,
    sortOn = null,
    sortOrder = null,
    setSortOn,
    setSortOrder,
    isEditMode,
    querystring = {},
    intl,
  } = props;
  const { sortable_indexes } = querystring;
  const Select = reactSelect.default;

  const activeSortOn = sortOn || data?.query?.sort_on || '';

  const { sortOnOptions = [] } = data;
  const value = {
    value: activeSortOn || intl.formatMessage(messages.noSelection),
    label:
      activeSortOn && sortable_indexes
        ? sortable_indexes[activeSortOn]?.title
        : activeSortOn || intl.formatMessage(messages.noSelection),
  };

  return (
    <div className="flex flex-wrap flex-row gap-2 items-center mb-2">
      <div className="flex flex-row gap-2 items-center">
        <span className="dark:text-white">Ordenar por</span>
        <Select
          id="select-search-sort-on"
          name="select-searchblock-sort-on"
          className="search-react-select-container"
          classNamePrefix="react-select"
          placeholder={'Ordem Cronológica'}
          styles={sortOnSelectStyles}
          theme={selectTheme}
          components={{ DropdownIndicator, Option }}
          options={[
            ...sortOnOptions.map((k) => ({
              value: k,
              label: sortable_indexes[k]?.title || k,
            })),
          ]}
          isSearchable={false}
          value={value}
          onChange={(data) => {
            !isEditMode && setSortOn(data.value);
          }}
        />
      </div>
      <div className="flex flex-row gap-2 items-center">
        <button
          title={intl.formatMessage(messages.ascending)}
          onClick={() => {
            !isEditMode && setSortOrder('ascending');
          }}
          className={`group p-1.5 rounded-lg transition-all duration-300 ${
            sortOrder == 'ascending'
              ? 'border-solid border border-dsBlue-500 [&_path]:stroke-dsBlue-500 dark:border-dsBlue-200 dark:[&_path]:stroke-dsBlue-200'
              : 'dark:[&_path]:stroke-white'
          } `}
        >
          <IconFilterUp className=" dark:group-hover:[&_path]:stroke-dsBlue-200 group-hover:[&_path]:stroke-dsBlue-500 w-7 h-7"></IconFilterUp>
        </button>
        <button
          title={intl.formatMessage(messages.descending)}
          onClick={() => {
            !isEditMode && setSortOrder('descending');
          }}
          className={`group p-1.5 rounded-lg transition-all duration-300 ${
            sortOrder == 'descending'
              ? 'border-solid border border-dsBlue-500 [&_path]:stroke-dsBlue-500 dark:border-dsBlue-200 dark:[&_path]:stroke-dsBlue-200'
              : 'dark:[&_path]:stroke-white'
          } `}
        >
          <IconFilterDown className=" dark:group-hover:[&_path]:stroke-dsBlue-200 group-hover:[&_path]:stroke-dsBlue-500 w-7 h-7" />
        </button>
      </div>
    </div>
  );
};

export default compose(injectIntl, injectLazyLibs(['reactSelect']))(SortOn);
