import { twMerge } from 'tailwind-merge';

export const IconArchive = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {

  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.5455 17.1111V37.5C39.5455 38.6045 38.6501 39.5 37.5455 39.5H10.4546C9.35002 39.5 8.45459 38.6045 8.45459 37.5V17.1111" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M41 8.5H7C5.89543 8.5 5 9.39543 5 10.5V15.1111C5 16.2157 5.89543 17.1111 7 17.1111H41C42.1046 17.1111 43 16.2157 43 15.1111V10.5C43 9.39543 42.1046 8.5 41 8.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.5454 24H27.4545" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}