import React from 'react';
import { FormattedMessage } from 'react-intl';

const DefaultNoResultsComponent = (props) => {
  return (
    <div className="flex items-center justify-center dark:text-white">
      <FormattedMessage
        id="No results found."
        defaultMessage="Nenhum resultado encontrado."
      />
    </div>
  );
};

export default DefaultNoResultsComponent;
