import React from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { BlockDataForm, ObjectListWidget } from '@plone/volto/components';
import {
  GlobalConfigSchema,
  IndividualConfigSchema,
  IndividualPersonSchema,
  GlobalPersonSchema,
  TestimonyPersonSchema,
  TestimonyConfigSchema,
} from './schema';

const OurPeopleData = (props) => {
  const { onChangeBlock, data } = props;

  const togglePersonSchema = () => {
    if (data?.layout == 'testimony') {
      return TestimonyPersonSchema;
    }

    if (data?.globalLinkOption === '0') {
      return IndividualPersonSchema;
    }
    return GlobalPersonSchema;
  };

  const toggleConfigSchema = () => {
    if (data?.layout == 'testimony') {
      return TestimonyConfigSchema;
    }

    if (data?.globalLinkOption === '0') {
      return IndividualConfigSchema;
    }
    return GlobalConfigSchema(props);
  };

  const schemaExtender = (schema, value) => {
    if (
      data?.globalLinkOption === '0' &&
      value.individualLinkOptSchemaExtenderion === '0'
    ) {
      const schemaCopy = structuredClone(schema);
      schemaCopy.fieldsets[0].fields.push('individualLinkURL');
      schemaCopy.properties.individualLinkURL = {
        title: 'URL do link',
        type: 'string',
      };
      return schemaCopy;
    }
    return schema;
  };

  return (
    <Segment.Group raised>
      <Form>
        <BlockDataForm
          schema={toggleConfigSchema()}
          title={toggleConfigSchema().title}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
          block={props.block}
          onChangeBlock={onChangeBlock}
        />
        <ObjectListWidget
          block={props.block}
          id="people"
          fieldSet={'default'}
          schema={togglePersonSchema()}
          schemaExtender={schemaExtender}
          onChange={(id, value) => {
            props.onChangeBlock(props.block, { ...props.data, [id]: value });
          }}
          value={props.data.people}
        />
      </Form>
    </Segment.Group>
  );
};

export default OurPeopleData;
