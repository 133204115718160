export const geralSchema = (props) => ({

  title: 'Text Customization',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text', 'align', 'container'],
    },
  ],

  properties: {
    text: {
      widget: 'textarea',
      title: 'Texto:',
    },
    align: {
      widget: 'align',
      title: 'Alinhamento do texto:',
      actions: ['left', 'right', 'center', 'full'],
      default: 'full'
    },
    container: {
      title: 'Escolha o container para o componente:',
      widget: 'select',
      choices: [
        ['0', 'Tela inteira'],
        ['1', 'Ensino-Institucional'],
        ['2', 'Pesquisa-Extensao'],
        ['3', 'Pesquisa-Institucional'],
      ]
    },
  },
  required: ['text', 'align', 'container'],

});