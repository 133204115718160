import { useContext } from 'react';
import { IconSun } from '../../assets/icons/IconSun';
import { IconMoon } from '../../assets/icons/IconMoon';
import { twJoin, twMerge } from 'tailwind-merge';

export const DarkModeButton = ({ className, darkMode, setDarkMode }) => {

  const toggleDarkMode = () => {
    if (darkMode === 'on') {
      setDarkMode('off');
    } else {
      setDarkMode('on');
    }
  }

  return (
    <button
      className={twMerge(
        'transition w-14 h-8 bg-[#73b2ec] rounded-full p-[3px]',
        darkMode === 'on' && 'bg-[#345d85]', className
      )}
      onClick={toggleDarkMode}
    >
      <div
        className={twJoin(
          'shadow-[0px_0px_5px_0px_rgba(0,0,0,0.7)] p-[1px] flex justify-center items-center rounded-full bg-white w-1/2 h-full transition',
          darkMode === 'on' && 'translate-x-full'
        )}
      >
        {darkMode === 'on' ?  <IconMoon color={'#518dc5'} /> : <IconSun color={'#73b2ec'} />}
      </div>
    </button>
  );
};
