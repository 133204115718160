import { twMerge } from 'tailwind-merge';

export const IconMobileDevice = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.2779 10.2222H25.7223M24.0001 32.6111V32.6283M13.6667 11.9444C13.6667 11.0309 14.0296 10.1548 14.6756 9.50885C15.3216 8.8629 16.1977 8.5 17.1112 8.5H30.889C31.8025 8.5 32.6786 8.8629 33.3246 9.50885C33.9705 10.1548 34.3334 11.0309 34.3334 11.9444V36.0556C34.3334 36.9691 33.9705 37.8452 33.3246 38.4911C32.6786 39.1371 31.8025 39.5 30.889 39.5H17.1112C16.1977 39.5 15.3216 39.1371 14.6756 38.4911C14.0296 37.8452 13.6667 36.9691 13.6667 36.0556V11.9444Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.6667 32.5V32.5161" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

