import { twMerge } from 'tailwind-merge';

export const IconSquare = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6666 38.3979L13.6924 35.9569" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.1776 38.4343L17.1929 36.8992" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.4848 38.5L25.5 36.9648" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.4742 38.5L21.5 36.0602" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.06016 23.4742L9.5 23.5" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.0169 27.9882L9.55322 28.0046" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.06016 32.4742L9.5 32.5" stroke={color} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.81988 8.5L6.5 39.1333L41.5 39.5L6.81988 8.5ZM13.8865 22.1524L25.2795 32.3377L13.7813 32.2165L13.8865 22.1524Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

