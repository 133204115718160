//@ts-check
import React, { useState } from 'react';
//@ts-ignore
import { SidebarPortal, Field } from '@plone/volto/components';
import { EditTableContext } from './store';
import { defaultCell, defaultTableData } from './const';
import { Table } from './Table/Table';

/**
 *
 * @param {{data:import("./type.d.ts").TableData,selected:any,onChangeBlock:any,block:any}} props
 * @returns
 *
 * ## Como adicionar mais propriedades a tabela ?
 *
 * Você deve adicionar a propriedade a defaultTableData com um valor padrão e adicionar a propridade com seus possiveis valores em "CompleteTable/type.d.ts"
 * Use como exemplo a altura das linhas.
 */
export const CompleteTableEdit = (props) => {
  const { selected, data, onChangeBlock, block } = props;

  const [table, setTable] = useState(insertTableDataDefaultValues(data));

  const [selectedCell, setSelectedCell] = useState(null);

  const [cols, setCols] = useState(data.cols ? data.cols : 2);

  const [rows, setRows] = useState(data.rows ? data.rows : 2);

  const [isLimited, setProtoIsLimited] = useState(
    typeof data.isLimited == 'undefined' ? true : data.isLimited,
  );

  const [cheatCounter, setCheatCounter] = useState(data.isLimited ? 0 : 7);

  /**
   *
   * @param {boolean} val
   */
  const setIsLimited = (val) => {
    table.isLimited = val;

    setProtoIsLimited(val);
    onChangeBlock(block, { ...data, ...table });
  };

  /**
   *
   * @param {number} rows
   * @param {boolean} isLimited
   */
  function setRowsInTable(rows, isLimited) {
    if (isLimited) {
      table.rows = Math.max(Math.min(rows, 20), 2);
    } else {
      table.rows = rows;
    }

    setTable(resizeTable(table));
    onChangeBlock(block, { ...data, ...table });
  }

  /**
   *
   * @param {number} cols
   * @param {boolean} isLimited
   */
  function setColsInTable(cols, isLimited) {
    if (isLimited) {
      table.cols = Math.max(Math.min(cols, 6), 2);
    } else {
      table.cols = cols;
    }

    setTable(resizeTable(table));
    onChangeBlock(block, { ...data, ...table });
  }

  return (
    <>
      <EditTableContext.Provider
        value={{
          table: table,
          setTable: (table) => {
            //saving alterations on block
            onChangeBlock(block, {
              ...data,
              ...table,
            });
            // setting table on provider
            setTable({ ...table });
          },
          selectedCell: selectedCell,
          //@ts-ignore
          setSelectedCell: setSelectedCell,
        }}
      >
        <Table {...table} mode="edit" className=""></Table>
        <SidebarPortal selected={selected}>
          <Field
            id="rows"
            type="number"
            title="Linhas"
            value={rows}
            onChange={(id, value) => {
              // resize table if needed

              setRows(parseInt(value));

              setRowsInTable(parseInt(value), isLimited ? isLimited : false);
            }}
          ></Field>

          <Field
            id="cols"
            type="number"
            title="Colunas"
            value={cols}
            onChange={(id, value) => {
              // resize table if needed
              setCols(parseInt(value));
              setColsInTable(parseInt(value), isLimited ? isLimited : false);
            }}
          ></Field>
          <div className="grid grid-cols-3 gap-4 mb-6 mt-6">
            <label>Altura das linhas : </label>
            <select
              defaultValue={table.linesHeight}
              onChange={(el) => {
                const inputValue = el.target.value;

                // um uso justificavel de ts-ignore , já que o valor sempre sera valido
                //@ts-ignore
                table.linesHeight = inputValue;

                // o tamanho das linhas sera renderizado na tabela
                //save changes
                onChangeBlock(block, { ...data, ...table });
              }}
              className="col-span-2"
            >
              <option value={'md'}>24px</option>
              <option value={'lg'}>36px</option>
            </select>
          </div>
          <div
            className={`${
              rows > 20 || rows < 2 || cols < 2 || cols > 6 ? '' : 'hidden'
            } border-2 border-solid border-[#ff8a00] text-[#ff8a00] bg-[#ffebaf] p-6 rounded-lg mx-auto w-[90%]`}
          >
            <p className="w-full text-center text-xl">
              Deve ter entre 20 e{' '}
              <span
                onClick={() => {
                  setCheatCounter(cheatCounter + 1);

                  if (!((cheatCounter + 1) % 7)) {
                    setIsLimited(false);
                    setColsInTable(cols, false);
                    setRowsInTable(rows, false);
                    return;
                  }

                  setIsLimited(true);
                  setColsInTable(cols, true);
                  setRowsInTable(rows, true);
                }}
                className={`${isLimited ? '' : 'font-bold'} `}
              >
                2
              </span>{' '}
              linhas e entre 6 e 2 colunas
            </p>
          </div>
        </SidebarPortal>
      </EditTableContext.Provider>
    </>
  );
};

/**
 * @param {import("./type.d.ts").TableData} data
 */
function insertTableDataDefaultValues(data) {
  const defaultData = defaultTableData;

  const defaultDataKeys = Object.keys(defaultData);

  for (let i = 0; i < defaultDataKeys.length; i++)
    if (!data[defaultDataKeys[i]])
      data[defaultDataKeys[i]] = defaultData[defaultDataKeys[i]];

  return data;
}

/**
 * @param {import("./type.d.ts").TableData} table
 * @returns {import("./type.d.ts").TableData}
 */
function resizeTable(table) {
  const tableCols = table.cols ? table.cols : 0;
  const tableRows = table.rows ? table.rows : 0;

  if (
    table.cells &&
    table.cells.length >= tableRows &&
    table.cells[0].length >= tableCols
  )
    return table;

  const output = { ...table };
  output.rows = output.rows ? output.rows : 2;
  output.cols = output.cols ? output.cols : 2;

  output.cells = output.cells ? output.cells : [];

  // create rows and cols if needed

  for (let i = 0; i < output.rows; i++) {
    if (!output.cells[i]) output.cells[i] = [];

    for (let j = 0; j < output.cols; j++)
      if (!output.cells[i][j]) {
        output.cells[i][j] = { ...defaultCell };
        output.cells[i][j].position = { col: j, row: i };
      }
  }

  return output;
}
