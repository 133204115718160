import { twMerge } from 'tailwind-merge';

export const IconFilledCircle = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:fill-[#FCBC00] group-hover:[&_path]:fill-[#FCBC00] dark:[&_path]:fill-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1608_86)">
        <path d="M14 6.67998C17.7889 4.4926 22.1905 3.60793 26.5303 4.16152C30.8701 4.71512 34.9086 6.67645 38.0271 9.74494C41.1455 12.8134 43.1717 16.8198 43.7953 21.1501C44.4189 25.4804 43.6054 29.8957 41.4795 33.7194C39.3535 37.5431 36.0325 40.5642 32.0252 42.3198C28.0179 44.0754 23.5456 44.4686 19.2934 43.4392C15.0413 42.4098 11.2441 40.0144 8.48354 36.6204C5.72303 33.2263 4.15158 29.0206 4.01 24.648L4 24L4.01 23.352C4.12033 19.9529 5.09559 16.6381 6.84363 13.7209C8.59166 10.8036 11.0547 8.38032 14 6.67998Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1608_86">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}