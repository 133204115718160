import titleComponentIcon from '../../assets/icons/titleComponent.svg';

import { DividerView } from './View';
import { DividerEdit } from './Edit';

export default {
  id: 'divider',
  title: 'Divider',
  group: 'text',
  view: DividerView,
  edit: DividerEdit,
  icon: titleComponentIcon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};