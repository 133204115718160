//@ts-check
//@ts-ignore
import { IconTextComponent } from '../../../../../assets/icons/buttons/IconTextComponent';
import { TextCellEdit } from './TextCellEdit';
import { TextCellView } from './TextCellView';

// aqui o "view", o "edit", um "icone" e "CellAttributes padrão" devem ser adicionados, siga o modelo do "text" ou do "img"
/**@type {import('../../types').CellConfigProps} */
export const TextCell = {
  View: TextCellView,
  Edit: TextCellEdit,
  defaultAttributes: { el: 'p' },
  Icon: IconTextComponent,
};
