//@ts-check
import {
  flattenToAppURL,
  isInternalURL,
  //@ts-ignore
} from '@plone/volto/helpers';
/**
 *
 * @param {string} url
 * @returns
 */
export function urlImageHandler(url) {
  return isInternalURL(url) ? `${flattenToAppURL(url)}/@@images/image` : url;
}
