import { twMerge } from 'tailwind-merge';

export const IconMoon = ({ className = "", size = "32", changeColorOnHover = false, color = '#2E3132' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'group-hover:[&_path]:stroke-[#005DB7]', className)} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_3146)">
        <path
          d="M12 3C12.132 3 12.263 3 12.393 3C11.1083 4.19371 10.2826 5.79985 10.0593 7.53923C9.83598 9.2786 10.2293 11.0412 11.1707 12.5207C12.1122 14.0002 13.5424 15.103 15.2126 15.6375C16.8828 16.1719 18.6875 16.1042 20.313 15.446C19.6877 16.9505 18.6658 18.257 17.3562 19.2263C16.0466 20.1955 14.4984 20.791 12.8769 20.9494C11.2554 21.1077 9.62126 20.823 8.14888 20.1254C6.67651 19.4279 5.42111 18.3437 4.51658 16.9886C3.61206 15.6335 3.09235 14.0583 3.01288 12.431C2.93341 10.8037 3.29718 9.1853 4.06537 7.74852C4.83356 6.31174 5.97736 5.11043 7.37476 4.27274C8.77216 3.43505 10.3707 2.9924 12 2.992V3Z"
          stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2_3146">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

// #005DB7