import { twMerge } from 'tailwind-merge';

export const IconVideoCamera = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.3333 19.8333L39.9452 15.0917C40.267 14.933 40.6245 14.8581 40.9839 14.874C41.3432 14.89 41.6925 14.9963 41.9985 15.1829C42.3045 15.3695 42.5572 15.6302 42.7324 15.9401C42.9077 16.2501 42.9998 16.5992 43 16.9542V31.0458C42.9998 31.4008 42.9077 31.7499 42.7324 32.0598C42.5572 32.3698 42.3045 32.6305 41.9985 32.8171C41.6925 33.0036 41.3432 33.11 40.9839 33.1259C40.6245 33.1419 40.267 33.067 39.9452 32.9083L30.3333 28.1667V19.8333Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 15.6667C5 14.5616 5.44484 13.5018 6.23666 12.7204C7.02848 11.939 8.10242 11.5 9.22222 11.5H26.1111C27.2309 11.5 28.3049 11.939 29.0967 12.7204C29.8885 13.5018 30.3333 14.5616 30.3333 15.6667V32.3333C30.3333 33.4384 29.8885 34.4982 29.0967 35.2796C28.3049 36.061 27.2309 36.5 26.1111 36.5H9.22222C8.10242 36.5 7.02848 36.061 6.23666 35.2796C5.44484 34.4982 5 33.4384 5 32.3333V15.6667Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

