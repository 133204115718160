import { twMerge } from 'tailwind-merge';

export const IconGoogleChrome2 = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 39.5C32.5604 39.5 39.5 32.5604 39.5 24C39.5 15.4396 32.5604 8.5 24 8.5C15.4396 8.5 8.5 15.4396 8.5 24C8.5 32.5604 15.4396 39.5 24 39.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 30.6429C27.6688 30.6429 30.6429 27.6688 30.6429 24C30.6429 20.3313 27.6688 17.3572 24 17.3572C20.3313 17.3572 17.3572 20.3313 17.3572 24C17.3572 27.6688 20.3313 30.6429 24 30.6429Z" stroke={color} strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38.1714 17.9476L24.4429 17.3572" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.1572 15.5857L18.2429 27.1" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.6714 38.6144L29.7571 27.1001" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

