import { twMerge } from 'tailwind-merge';

export const IconCarouselVertical = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {

  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1608_78)">
        <path d="M38 16V32C38 32.5304 37.7893 33.0391 37.4142 33.4142C37.0391 33.7893 36.5304 34 36 34H12C11.4696 34 10.9609 33.7893 10.5858 33.4142C10.2107 33.0391 10 32.5304 10 32V16C10 15.4696 10.2107 14.9609 10.5858 14.5858C10.9609 14.2107 11.4696 14 12 14H36C36.5304 14 37.0391 14.2107 37.4142 14.5858C37.7893 14.9609 38 15.4696 38 16Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.9995 44V42C13.9995 41.4696 14.2102 40.9609 14.5853 40.5858C14.9604 40.2107 15.4691 40 15.9995 40H31.9995C32.5299 40 33.0387 40.2107 33.4137 40.5858C33.7888 40.9609 33.9995 41.4696 33.9995 42V44" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33.9995 4V6C33.9995 6.53043 33.7888 7.03914 33.4137 7.41421C33.0387 7.78929 32.5299 8 31.9995 8H15.9995C15.4691 8 14.9604 7.78929 14.5853 7.41421C14.2102 7.03914 13.9995 6.53043 13.9995 6V4" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1608_78">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}