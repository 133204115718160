import React from 'react';
import { twMerge } from 'tailwind-merge';

export const IconTextComponent = ({ className, size = '48' }) => {
  return (
    <svg
      className={twMerge(className)}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M41.0236 19.2768L17.9336 19.3125"
        stroke="#3F4047"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M42.0996 28.2734H8.26953"
        stroke="#3F4047"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M42.0996 37.5645H8.26953"
        stroke="#3F4047"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M10.5557 10.4375V20.1903M10.5557 10.4375H14.213V12.063M10.5557 10.4375H6.89844V12.063M8.98833 20.1903H12.1232"
        stroke="#3F4047"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
