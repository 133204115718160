//@ts-check
import React from 'react';
//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { IconsName } from '../../../assets/icons/buttons';
import { twJoin } from 'tailwind-merge';
import { CustomContainer } from '../../general';
import { NavLink } from 'react-router-dom';
import { fixLink } from './fixLink';

const CheckInternal = ({ className, newTab, href, children }) => {
  if (isInternalURL(href) && !href.includes("#")) {
    return (
      <NavLink
        className={className}
        to={flattenToAppURL(href)}
        target={twJoin(newTab ? '_blank' : '_self')}
        rel="noreferrer"
      >
        {children}
      </NavLink>
    );
  } else {
    return (
      <a
        target={twJoin(newTab ? '_blank' : '_self')}
        className={className}
        href={fixLink(href)}
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
};

const Button = ({ icon, href, color, size, newTab, isEditMode }) => {
  const IconComponente = IconsName[icon];

  const iconColor = {
    black: '#000000',
    blue: '#005DB7',
  };

  return (
    <CheckInternal
      className={twJoin(
        'group',
        (isEditMode || href === '#') && 'cursor-default pointer-events-none',
      )}
      newTab={newTab}
      href={href}
    >
      <IconComponente
        color={iconColor[color]}
        changeColorOnHover={true}
        className={twJoin(
          'min-w-max w-[32px] h-[32px] shrink-0',
          size === '48px' && 'md:w-[48px] md:h-[48px]',
        )}
      />
    </CheckInternal>
  );
};

export const FlexButtonsView = (props) => {
  const { data, isEditMode } = props;

  return (
    <CustomContainer
      size="pesquisa-extensao"
      alignCenter={true}
      className="px-4 sm:px-0 flex justify-center gap-x-10 gap-y-8 flex-wrap"
    >
      {data.buttons?.map((btn, index) => {
        return (
          <Button
            key={index}
            color={data.color ?? 'black'}
            size={data.size ?? '48px'}
            icon={btn.icon ?? 'Default'}
            href={btn.link ?? '#'}
            newTab={btn.newTab ?? false}
            isEditMode={isEditMode}
          />
        );
      })}
    </CustomContainer>
  );
};
