import { Layouts } from './layouts';

export const posSchema = () => ({
  title: 'Post it',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'color',
        'layout',
        'label',
        'timeSpan',
        'presence',
        'campus',
        'modality',
        'concept',
        'selection',
        'bolsa',
      ],
    },
  ],
  properties: {
    color: {
      title: 'Escolha a cor',
      type: 'select_widget',
      choices: [
        ['#B9DDFF', 'Azul'],
        ['#FFEBAF', 'Amarelo'],
        ['#C5EBC0', 'Verde'],
        ['#FFCCD1', 'Rosa'],
        ['#C2A6E4', 'Roxo'],
      ],
    },
    layout: {
      title: 'Escolha o layout',
      type: 'select_widget',
      choices: Layouts,
    },
    label: {
      title: 'Adicionar rótulo',
      widget: 'object_list',
      schema: labelSchema,
    },
    timeSpan: {
      title: 'Duração',
      widget: 'textarea',
    },
    presence: {
      title: 'Presença',
      type: 'select_widget',
      choices: [
        ['Presencial', 'Presencial'],
        ['EAD', 'EAD'],
        ['Híbrido', 'Híbrido'],
      ],
      default: 'presencial',
    },
    campus: {
      title: 'Campus',
      widget: 'textarea',
    },
    modality: {
      title: 'Modalidade',
      widget: 'textarea',
    },
    concept: {
      title: 'Conceito',
      widget: 'textarea',
    },
    selection: {
      title: 'Seleção',
      widget: 'textarea',
    },
    bolsa: {
      title: 'Bolsa',
      widget: 'textarea',
    },
  },
  required: ['color', 'layout'],
});

export const labelSchema = () => ({
  title: 'Rótulo customizável',
  addMessage: 'Adicionar',
  addItem: '01',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text'],
    },
  ],
  properties: {
    text: {
      title: 'Escreva um rótulo',
      widget: 'textarea',
    },
  },
  required: ['text'],
});
