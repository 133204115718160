import { TwoColumnsEdit } from './TwoColumnsEdit';
import { TwoColumnsView } from './TwoColumnsView';
import titleComponentIcon from '../../assets/icons/titleComponent.svg';
import { getSchema } from './schema';

export default {
  id: 'twoColumns',
  title: 'Duas colunas',
  group: 'common',
  view: TwoColumnsView,
  edit: TwoColumnsEdit,
  blockSchema: getSchema,
  icon: titleComponentIcon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  allowedBlocks: ['singleColumn'],
};
