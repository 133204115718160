import { twMerge } from 'tailwind-merge';

export const IconImage = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3076 28.4576L22.1937 23.7678C22.588 23.127 23.522 23.1348 23.9056 23.7821L24.7037 25.1289C25.0911 25.7825 26.037 25.7825 26.4243 25.1289L29.5343 19.8809C29.9572 19.1672 31.015 19.2491 31.3231 20.0193L34.323 27.5191" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M36.0155 8.75H16.677C15.0201 8.75 13.677 10.0931 13.677 11.75V31.5577C13.677 33.2145 15.0202 34.5577 16.677 34.5577H36.0155C37.6723 34.5577 39.0155 33.2145 39.0155 31.5577V11.75C39.0155 10.0931 37.6723 8.75 36.0155 8.75Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M8.98462 15.7886V36.2501C8.98462 37.907 10.3278 39.2501 11.9846 39.2501H31.0385" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  )
}

