import { twMerge } from 'tailwind-merge';

export const IconSun = ({ className = "", changeColorOnHover = false, size = '32', color = '#2E3132' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'group-hover:[&_path]:stroke-[#005DB7] group-hover:[&_circle]:stroke-[#005DB7]', className)} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.9984" cy="11.9496" r="4.48671" stroke={color} strokeWidth="1.5" />
      <path d="M19.334 11.9492L21.9523 11.9492" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12.0017 4.61841L12.0017 2.00005" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M19.0898 19.0361L17.2384 17.1847" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M19.0898 4.61816L17.2384 6.46962" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.52539 17.1846L4.67393 19.036" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.52539 6.46875L4.67393 4.61729" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12.0017 21.9998L12.0017 19.3814" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2.05273 11.9492L4.67109 11.9492" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}