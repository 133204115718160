import { twMerge } from 'tailwind-merge';

export const IconGoogleChrome = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.5 16.3333C22.8703 16.3333 24.1844 16.8777 25.1534 17.8466C26.1223 18.8156 26.6667 20.1297 26.6667 21.5C26.6667 22.8703 26.1223 24.1844 25.1534 25.1534C24.1844 26.1223 22.8703 26.6667 21.5 26.6667C20.1297 26.6667 18.8156 26.1223 17.8466 25.1534C16.8777 24.1844 16.3333 22.8703 16.3333 21.5C16.3333 20.1297 16.8777 18.8156 17.8466 17.8466C18.8156 16.8777 20.1297 16.3333 21.5 16.3333ZM21.5 16.3333H35.9667M25.9742 24.0833L18.7409 36.6125M17.0257 24.0833L9.79238 11.5542M6 21.5C6 23.5355 6.40092 25.551 7.17987 27.4316C7.95881 29.3121 9.10054 31.0208 10.5398 32.4602C11.9792 33.8995 13.6879 35.0412 15.5684 35.8201C17.449 36.5991 19.4645 37 21.5 37C23.5355 37 25.551 36.5991 27.4316 35.8201C29.3121 35.0412 31.0208 33.8995 32.4602 32.4602C33.8995 31.0208 35.0412 29.3121 35.8201 27.4316C36.5991 25.551 37 23.5355 37 21.5C37 19.4645 36.5991 17.449 35.8201 15.5684C35.0412 13.6879 33.8995 11.9792 32.4602 10.5398C31.0208 9.10054 29.3121 7.95882 27.4316 7.17987C25.551 6.40092 23.5355 6 21.5 6C19.4645 6 17.449 6.40092 15.5684 7.17987C13.6879 7.95882 11.9792 9.10054 10.5398 10.5398C9.10054 11.9792 7.95881 13.6879 7.17987 15.5684C6.40092 17.449 6 19.4645 6 21.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

