//@ts-check

/**
 * @type {import('./type').SchemaI<import('./dataSubjectType').DataSubjectType>}
 */
export const subjectSchema = {
  id: 'subject',
  title: 'Assunto',
  fieldsets: [
    {
      id: 'default',
      title: 'Padrão',
      fields: ['subject'],
    },
  ],
  properties: {
    subject: { title: 'Assunto', widget: 'text' },
  },
  required: ['subject'],
};
