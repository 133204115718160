//@ts-check
//@ts-ignore

/**
 *
 * @param {string} rawLink
 */
export function fixLink(rawLink = '') {
  const link = rawLink.replace(' ', '');

  if (isValidLink(link)) return link;

  const isWWW = Boolean(link.match(/^www\./));

  if (isWWW) return `http://${link}`;

  const haveADot = Boolean(link.match(/^[a-z0-9-]+\.[a-z]{2,}/));

  if (haveADot) return `http://${link}`;

  return link;
}

/**
 *  @param {string} link
 *  @returns {boolean}
 */
function isValidLink(link) {
  try {
    const testingURL = new URL(link);
    return true;
  } catch {
    return false;
  }
}
