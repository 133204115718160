import React from 'react';

const Campi = ({ name, img, link }) => {
  return (
    <a
      href={link}
      className="relative max-md:w-2/6 w-[120px] h-[200px] group cursor-pointer overflow-hidden"
    >
      <img
        src={img}
        className="absolute w-full h-full object-cover"
        alt={name}
      />
      <div className="font-bold absolute w-full text-white bg-black/60 group-hover:bg-[#FFBE00] group-hover:text-black text-[15px] h-[60px] bottom-0 flex justify-center items-center text-center">
        {name}
      </div>
    </a>
  );
};

export default Campi;
