import { twMerge } from 'tailwind-merge';

export const IconVerification = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8333 24L22.2778 27.4444L29.1667 20.5556M24 8.5L27.2896 11.7231L31.75 10.5766L32.9872 15.0127L37.4233 16.25L36.2769 20.7104L39.5 24L36.2769 27.2896L37.4233 31.75L32.9872 32.9872L31.75 37.4233L27.2896 36.2769L24 39.5L20.7104 36.2769L16.25 37.4233L15.0127 32.9872L10.5766 31.75L11.7231 27.2896L8.5 24L11.7231 20.7104L10.5766 16.25L15.0127 15.0127L16.25 10.5766L20.7104 11.7231L24 8.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

