import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import OurPeopleView from './OurPeopleView';
import OurPeopleData from './OurPeopleData';


const OurPeopleEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <OurPeopleView {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <OurPeopleData {...props} />
      </SidebarPortal>
    </>
  );
};


export default OurPeopleEdit;