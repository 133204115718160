//@ts-check
/**
 *
 * @param {'left'|'center'|'right'} [align]
 * @returns
 */
export const alignToCssClass = (align) => {
  if (!align) return 'text-left';
  switch (align) {
    case 'center':
      return 'text-center';
    case 'right':
      return 'text-right';
  }
};
