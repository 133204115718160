import PostItPesquisaLayout from '../../../../addons/utfpr_components/src/components/PostIt/layouts/Pesquisa';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

const PostItSeartch = ({ items, linkTitle, linkHref, isEditMode }) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  function openLink(link) {
    window.open(urlHandler(link), '_blank');
  }

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[24px] pb-5 border justify-start">
      {items.map((item) => (
        <PostItPesquisaLayout
          onClick={() => openLink(item.getURL)}
          title={item.title}
          color="#B9DDFF"
          text={item.Autores}
          fomento={<p>CNPQ</p>}
          labels={
            item.Subject.length &&
            item.Subject.map((subject, idx) => ({
              text: subject
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            }))
          }
          key={item['@id']}
        />
      ))}
    </div>
  );
};
export default PostItSeartch;
