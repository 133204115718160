import { twMerge } from 'tailwind-merge';

export const IconPet = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.3449 23.2441C23.2362 23.2432 22.0344 23.637 20.9042 24.2407C19.7571 24.8534 18.5997 25.7267 17.5652 26.7921C15.5092 28.9097 13.8232 31.9128 13.8232 35.3195C13.8232 36.8489 14.0642 38.1954 14.9613 39.01C15.9199 39.8806 17.168 39.7494 18.0497 39.5607C18.6307 39.4364 19.3337 39.2147 20.0237 38.9971C20.3869 38.8826 20.7466 38.7692 21.0829 38.6717C22.149 38.3627 23.2372 38.123 24.3443 38.123C25.4514 38.123 26.5396 38.3627 27.6057 38.6717C27.942 38.7692 28.3017 38.8826 28.6649 38.9971C29.3549 39.2147 30.0579 39.4364 30.6389 39.5607C31.5206 39.7494 32.7686 39.8806 33.7273 39.01C34.6244 38.1954 34.8653 36.8489 34.8653 35.3195C34.8653 31.9127 33.1793 28.9104 31.1233 26.7932C30.0888 25.728 28.9315 24.8549 27.7845 24.2421C26.6544 23.6383 25.453 23.2443 24.3449 23.2441ZM24.3449 23.2441C24.3447 23.2441 24.3445 23.2441 24.3443 23.2441V24.4941L24.3455 23.2441C24.3453 23.2441 24.3451 23.2441 24.3449 23.2441Z" stroke={color} strokeWidth="2.5" />
      <path d="M41.4667 17.1868L41.4664 17.1866C40.3856 16.5657 39.154 16.6648 38.1287 17.1223C37.1012 17.5808 36.1811 18.4303 35.5461 19.5238L35.546 19.5239C34.9105 20.6184 34.6297 21.8389 34.7446 22.9579C34.8593 24.0756 35.3914 25.1909 36.4735 25.8132L36.4739 25.8134C37.5547 26.4344 38.7865 26.3352 39.812 25.8773C40.8396 25.4183 41.7596 24.5681 42.3946 23.4734L42.3948 23.4731C43.0293 22.3783 43.3093 21.1583 43.1943 20.04C43.0794 18.9232 42.5479 17.8084 41.4667 17.1868Z" stroke={color} strokeWidth="2.5" />
      <path d="M6.53303 19.1868L6.53338 19.1866C7.61411 18.5657 8.84579 18.6648 9.87107 19.1223C10.8985 19.5808 11.8186 20.4303 12.4537 21.5238L12.4537 21.5239C13.0893 22.6184 13.3701 23.8389 13.2552 24.9579C13.1404 26.0756 12.6083 27.1909 11.5263 27.8132L11.5258 27.8134C10.4451 28.4344 9.21327 28.3352 8.18779 27.8773C7.16019 27.4183 6.24011 26.5681 5.60519 25.4734L5.60499 25.4731C4.97047 24.3783 4.69047 23.1583 4.80547 22.04C4.92031 20.9232 5.45183 19.8084 6.53303 19.1868Z" stroke={color} strokeWidth="2.5" />
      <path d="M16.4504 9.9504L16.4508 9.9503C17.6228 9.63646 18.7519 10.0411 19.593 10.7256C20.4355 11.4115 21.0772 12.434 21.3931 13.61L21.3931 13.6101C21.7092 14.7873 21.6653 15.9947 21.2787 17.0106C20.8928 18.0246 20.117 18.9402 18.9448 19.2546L18.9443 19.2547C17.7722 19.5686 16.6429 19.1639 15.8019 18.4789C14.9592 17.7926 14.3178 16.7693 14.0023 15.5923L14.0022 15.5919C13.6872 14.4147 13.7316 13.208 14.1182 12.1928C14.504 11.1797 15.2791 10.2644 16.4504 9.9504Z" stroke={color} strokeWidth="2.5" />
      <path d="M31.0152 9.32243L31.0156 9.32253C32.2157 9.64431 33.0124 10.5819 33.4101 11.6257C33.8085 12.6714 33.8549 13.9161 33.53 15.1314L33.53 15.1315C33.2047 16.348 32.5422 17.4038 31.6741 18.111C30.8077 18.8167 29.6483 19.2307 28.4478 18.9094L28.4473 18.9093C27.2472 18.5875 26.4503 17.6498 26.0528 16.6055C25.6547 15.5594 25.6088 14.3142 25.9346 13.0981L25.9347 13.0978C26.2609 11.8818 26.9236 10.8268 27.7913 10.1203C28.6572 9.41524 29.8157 9.00121 31.0152 9.32243Z" stroke={color} strokeWidth="2.5" />
    </svg>
  )
}

