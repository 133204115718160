import config from '@plone/volto/registry';
import { defineMessages } from 'react-intl';
import { addStyling } from '@plone/volto/helpers';

const messages = defineMessages({
  Accordion: {
    id: 'Accordion',
    defaultMessage: 'Accordion',
  },
  Options: {
    id: 'Options',
    defaultMessage: 'Options',
  },
  Default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  AccordionTitle: {
    id: 'Accordion title',
    defaultMessage: 'Accordion title',
  },
  AccordionBlock: {
    id: 'Accordion block',
    defaultMessage: 'Accordion block',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  friendly_name: {
    id: 'Friendly name',
    defaultMessage: 'Friendly name',
  },
  title_size_description: {
    id: 'Accordion Title size',
    defaultMessage: 'Accordion Title size',
  },
  collapsed: {
    id: 'Sanfona fechada por padrão',
    defaultMessage: 'Sanfona fechada por padrão',
  },
  non_exclusive: {
    id: 'Não exclusivo',
    defaultMessage: 'Não exclusivo',
  },
  non_exclusive_description: {
    id: 'Permite várias abas abertas ao mesmo tempo',
    defaultMessage: 'Permite várias abas abertas ao mesmo tempo',
  },
  filtering: {
    id: 'Ativar filtro',
    defaultMessage: 'Ativar filtro',
  },
  Theme: {
    id: 'Theme',
    defaultMessage: 'Theme',
  },
  ThemeHelp: {
    id: 'Accordion theme',
    defaultMessage: 'Accordion theme',
  },
  ThemePrimary: {
    id: 'Primary',
    defaultMessage: 'Primary',
  },
  ThemeSecondary: {
    id: 'Secondary',
    defaultMessage: 'Secondary',
  },
  ThemeTertiary: {
    id: 'Tertiary',
    defaultMessage: 'Tertiary',
  },
  headline: {
    id: 'Título',
    defaultMessage: 'Título',
  },
});

export const AccordionSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.Accordion),
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: ['panel_title'],
      },
    ],
    properties: {
      panel_title: {
        title: intl.formatMessage(messages.AccordionTitle),
      },
    },
    required: [],
  };
};

export const AccordionBlockSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.AccordionBlock),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage(messages.Default),
      fields: ['data'],
    },
    {
      id: 'options',
      title: intl.formatMessage(messages.Options),
      fields: [
        'headline',
        'collapsed',
        'non_exclusive',
        'filtering',
      ],
    },
  ],
  properties: {
    headline: {
      title: intl.formatMessage(messages.headline),
    },
    data: {
      title: intl.formatMessage(messages.Accordion),
      type: 'panels',
      schema: AccordionSchema(intl),
    },
    collapsed: {
      title: intl.formatMessage(messages.collapsed),
      type: 'boolean',
      default: true,
    },
    non_exclusive: {
      title: intl.formatMessage(messages.non_exclusive),
      description: intl.formatMessage(messages.non_exclusive_description),
      type: 'boolean',
      default: true,
    },
    filtering: {
      title: intl.formatMessage(messages.filtering),
      type: 'boolean',
      default: false,
    },
  },
  required: [],
});

export const AccordionStylingSchema = (props) => {
  const { intl, schema } = props;
  // JOE: Desabilitando o campo "Styling" no modo Edit do Accordion
  /* const schema = addStyling(props);
  schema.properties.styles.schema = {
    title: intl.formatMessage(messages.Accordion),
    block: 'accordion',
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: ['theme'],
      },
    ],
    properties: {
      theme: {
        title: intl.formatMessage(messages.Theme),
        description: intl.formatMessage(messages.ThemeHelp),
        widget: 'theme_picker',
        colors: [
          ...(config.settings && config.settings.themeColors
            ? config.settings.themeColors.map(({ value, title }) => ({
              name: value,
              label: title,
            }))
            : []),
        ],
      },
    },
    required: [],
  }; */
  return schema;
};
