import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import TextComponentView from './TextComponentView';
import TextComponentBlockData from './TextComponentData';


const TextComponentEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <TextComponentView {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <TextComponentBlockData {...props} />
      </SidebarPortal>
    </>
  );
};


export default TextComponentEdit;