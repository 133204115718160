import { twMerge } from 'tailwind-merge';

export const IconHeart = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.1805 8.57739C32.8472 8.57242 31.5351 8.92857 30.3702 9.61162C29.2054 10.2947 28.2268 11.2817 27.5285 12.4779C27.4813 12.5524 27.4341 12.627 27.3712 12.7098C26.9852 13.2611 26.4817 13.7093 25.9014 14.0186C25.321 14.3278 24.6799 14.4893 24.0295 14.4903C23.3643 14.4928 22.7084 14.3254 22.1182 14.0023C21.5279 13.6792 21.0209 13.2101 20.6406 12.6353L20.5069 12.4779C19.9393 11.4874 19.1798 10.6341 18.278 9.97355C17.3761 9.31301 16.3522 8.8601 15.273 8.64437C14.1938 8.42864 13.0836 8.45494 12.0147 8.72153C10.9458 8.98813 9.94225 9.48904 9.06952 10.1916C8.19678 10.8942 7.47446 11.7826 6.94967 12.7989C6.42487 13.8153 6.10938 14.9368 6.02377 16.0902C5.93816 17.2436 6.08437 18.403 6.45284 19.4929C6.82131 20.5828 7.40378 21.5787 8.16226 22.4155L8.46891 22.7468L24.0295 39.5L39.6294 22.7054C39.7238 22.6143 39.8181 22.5066 39.8968 22.4155C40.9365 21.2438 41.6281 19.7774 41.8867 18.1969C42.1452 16.6164 41.9592 14.9907 41.3517 13.5201C40.7443 12.0494 39.7417 10.798 38.4675 9.9198C37.1934 9.0416 35.7032 8.57499 34.1805 8.57739Z" stroke={color} strokeWidth="2.5" />
    </svg>
  )
}

