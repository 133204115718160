import React from 'react';
import { IconTemplate } from '../../assets/icons/IconTemplate';
import { CustomContainer } from '../general';
import { twJoin, twMerge } from 'tailwind-merge';

const SubscribeButtonView = (props) => {
  const { data } = props;

  let mode = data.mode ?? '1';

  return (
    <CustomContainer
      size={translateContainerSize(data.container)}
      alignCenter={true}
    >
      <a
        href="#"
        target="_blank"
        className={twJoin(props.isEditMode && 'pointer-events-none',
          '!py-2 rounded-md flex justify-center items-center gap-x-2 cursor-pointer transition',
          mode === '1' ? 'group border border-solid border-dsBlue-600 hover:border-dsYellow-600 transition dark:bg-[#001B3D] dark:hover:bg-[#003063] dark:border-[#FCBC00] dark:hover:border-dsBlue-50' : 'bg-dsBlue-700 hover:bg-dsBlue-800')}
      >
        <p
          className={twJoin(mode === '1'
            ? 'group-hover:text-dsYellow-600 dark:text-[#F8FAFA] dark:group-hover:text-[#F8FAFA] text-dsBlue-700 transition'
            : 'text-[#F8FAFA]',
            'font-dsButton-2 font-normal leading-7 tracking-tight')}
        >
          Inscreva-se
        </p>
        <IconTemplate
          className={
            mode === '1'
              ? '[&_path]:fill-[#005DB7] group-hover:[&_path]:fill-dsYellow-600 transition dark:[&_path]:fill-[#F8FAFA] dark:group-hover:[&_path]:fill-dsBlue-50'
              : '[&_path]:fill-[#F8FAFA]'
          }
          size={'24'}
        />
      </a>
    </CustomContainer >
  );
};

/**
 * @param {"1","2","3"} [container]
 * @returns {import('../general/CustomContainers/CustomContainer').ContainerSize}
 */
function translateContainerSize(container) {
  switch (container) {
    case '1':
      return 'ensino-institucional';
    case '2':
      return 'pesquisa-extensao';
    case '3':
      return 'pesquisa-institucional';
  }
  return 'pesquisa-extensao';
}

export default SubscribeButtonView;
