import { twMerge } from 'tailwind-merge';

export const IconMathDivide = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.9998 16.4023C26.1415 16.4023 27.8777 14.6661 27.8777 12.5244C27.8777 10.3827 26.1415 8.64648 23.9998 8.64648C21.858 8.64648 20.1218 10.3827 20.1218 12.5244C20.1218 14.6661 21.858 16.4023 23.9998 16.4023Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.4272 24H37.5728" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.9998 39.3533C26.1415 39.3533 27.8777 37.6171 27.8777 35.4753C27.8777 33.3336 26.1415 31.5974 23.9998 31.5974C21.858 31.5974 20.1218 33.3336 20.1218 35.4753C20.1218 37.6171 21.858 39.3533 23.9998 39.3533Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

