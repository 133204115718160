import { twMerge } from 'tailwind-merge';

export const IconCarouselHorizontal = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {

  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1608_79)">
        <path d="M13.9995 12C13.9995 11.4696 14.2102 10.9609 14.5853 10.5858C14.9604 10.2107 15.4691 10 15.9995 10H31.9995C32.5299 10 33.0387 10.2107 33.4137 10.5858C33.7888 10.9609 33.9995 11.4696 33.9995 12V36C33.9995 36.5304 33.7888 37.0391 33.4137 37.4142C33.0387 37.7893 32.5299 38 31.9995 38H15.9995C15.4691 38 14.9604 37.7893 14.5853 37.4142C14.2102 37.0391 13.9995 36.5304 13.9995 36V12Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M44 34H42C41.4696 34 40.9609 33.7893 40.5858 33.4142C40.2107 33.0391 40 32.5304 40 32V16C40 15.4696 40.2107 14.9609 40.5858 14.5858C40.9609 14.2107 41.4696 14 42 14H44" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 34H6C6.53043 34 7.03914 33.7893 7.41421 33.4142C7.78929 33.0391 8 32.5304 8 32V16C8 15.4696 7.78929 14.9609 7.41421 14.5858C7.03914 14.2107 6.53043 14 6 14H4" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1608_79">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}