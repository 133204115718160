import { Layouts } from './layouts';

export const pesquisaSchema = () => ({
  title: 'Post it',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['color', 'layout', 'text', 'title', 'href', 'fomento', 'label'],
    },
  ],
  properties: {
    color: {
      title: 'Escolha a cor',
      type: 'select_widget',
      choices: [
        ['#B9DDFF', 'Azul'],
        ['#FFEBAF', 'Amarelo'],
        ['#C5EBC0', 'Verde'],
        ['#FFCCD1', 'Rosa'],
        ['#C2A6E4', 'Roxo'],
      ],
    },
    layout: {
      title: 'Escolha o layout',
      type: 'select_widget',
      choices: Layouts,
    },
    title: {
      title: 'Escreva o titulo',
      widget: 'link',
    },
    href: {
      title: 'Link do titulo:',
      mode: 'link',
    },
    text: {
      title: 'Orientador',
      widget: 'text',
    },
    fomento: {
      title: 'Fomento',
      widget: 'text',
    },
    label: {
      title: 'Adicionar rótulo',
      widget: 'object_list',
      schema: labelSchema,
    },
  },
  required: ['color', 'layout', 'title', 'text', 'fomento'],
});

export const labelSchema = () => ({
  title: 'Rótulo customizável',
  addMessage: 'Adicionar',
  addItem: '01',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text'],
    },
  ],
  properties: {
    text: {
      title: 'Escreva um rótulo',
      widget: 'textarea',
    },
  },
  required: ['text'],
});
