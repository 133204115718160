import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SwiperSlide } from 'swiper/react';
import CarouselContainer from '../../CarouselContainer/CarouselContainer';
import MainNewsItemRow from './Items/Row';
import { CiImageOff } from 'react-icons/ci';
import { formatDate } from '../../../../../../helpers/Utils/Utils';

const YoutubeTabContent = ({ dataFiles }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (!window) return;

    let response = {};

    async function fetchVideos() {
      try {
        response = await axios.get(
          `${window.env.RAZZLE_MICROSSERVICE_URL}/youtube/videos`,
        );

        /* only for test https://portal2.utfpr.edu.br/++microservices++//youtube/videos */
        /*         response = await axios.get(
          'https://portal2.utfpr.edu.br/++microservices++//youtube/videos',
        ); */
      } catch (err) {
        console.log(err);
      }

      if (!response.data) return;

      setVideos(response.data);
    }

    fetchVideos();
  }, []);

  return (
    <CarouselContainer>
      {videos.map((video_data, index) => {
        return (
          <SwiperSlide key={video_data.id}>
            <MainNewsItemRow
              img={video_data.thumbnail}
              tags={['youtube', video_data.channel]}
              title={video_data.title}
              link={video_data.link}
              youtubeState={video_data.liveBroadcastContent}
              youtube
              date={formatDate(video_data.date, 'dd/MM/yyyy HHhmm')}
            />
          </SwiperSlide>
        );
      })}
      {videos.length === 0 && (
        <div className="w-full h-[400px] flex flex-col justify-center items-center gap-4 text-[20px] opacity-60">
          Não há vídeos disponíveis no momento.
          <CiImageOff className="text-[200px]" />
        </div>
      )}
    </CarouselContainer>
  );
};

export default YoutubeTabContent;
