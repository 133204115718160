//@ts-check
import { ImgCellEdit } from './ImgCellEdit';
import { ImgCellView } from './ImgCellView';
import { IconImage } from '../../../../../assets/icons/buttons/IconImage';
/**@type {import('../../types').CellConfigProps} */
const ImgCell = {
  View: ImgCellView,
  Edit: ImgCellEdit,
  Icon: IconImage,
  defaultAttributes: { url: '' },
};
export { ImgCell };
