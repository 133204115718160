import React from 'react';
import NewsTabContent from './type/NewsTabContent';
import YoutubeTabContent from './type/YoutubeTabContent';

// ** Props **
// props.data.columnModeLimit: it can be positive, negative or fractional number

const View = (props) => {

  //console.log('%c props', 'background-color: lightgreen', props)

  // In case of negative or decimal numbers
  props.data.columnItemLimit = Math.trunc(
    Math.max(0, Math.abs(props.data.columnModeLimit)),
  );

  //so it is compatible with current teaser component
  props.data.showImages =
    typeof props.data.showImages == 'undefined' ? true : props.data.showImages;

  if (props.data.type === 'news') {
    return (
      <NewsTabContent
        dataFiles={props.data.noticias}
        editable={Boolean(props.editable)}
        colModeLimit={props.data.columnModeLimit}
        token={props.token}
        showImages={props.data.showImages}
      />
    );
  } else if (props.data.type === 'media') {
    return <YoutubeTabContent />;
  }
  return (
    <div className="w-full h-[400px] flex flex-col justify-center items-center gap-4 text-[20px] opacity-60">
      Escolha um tipo de conteúdo
    </div>
  );
};

export default View;
