import { twMerge } from 'tailwind-merge';

export const IconInstagram = ({ className, color = "#000000", size = '24' }) => {
  return (
    <svg className={twMerge('dark:[&_path]:fill-[#A9C7FF] dark:[&:hover_path]:fill-[#FCBC00] [&:hover_path]:fill-[#FCBC00]', className)} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M8 2H5.33333C3.49239 2 2 3.49239 2 5.33333V10.6667C2 12.5076 3.49239 14 5.33333 14H10.6667C12.5076 14 14 12.5076 14 10.6667V5.33333C14 3.49239 12.5076 2 10.6667 2H8ZM7.99948 5.83281C6.80286 5.83281 5.83281 6.80286 5.83281 7.99948C5.83281 9.19607 6.80287 10.1661 7.99948 10.1661C9.1961 10.1661 10.1661 9.1961 10.1661 7.99948C10.1661 6.80286 9.1961 5.83281 7.99948 5.83281ZM4.83281 7.99948C4.83281 6.25058 6.25058 4.83281 7.99948 4.83281C9.74838 4.83281 11.1661 6.25058 11.1661 7.99948C11.1661 9.74838 9.74838 11.1661 7.99948 11.1661C6.25057 11.1661 4.83281 9.74835 4.83281 7.99948ZM12.4008 4.39961C12.4008 3.95778 12.0426 3.59961 11.6008 3.59961C11.159 3.59961 10.8008 3.95778 10.8008 4.39961C10.8008 4.84143 11.159 5.19961 11.6008 5.19961C12.0426 5.19961 12.4008 4.84144 12.4008 4.39961Z"
        fill={color} />
    </svg>
  )
}