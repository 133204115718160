export const geralSchema = (props) => ({

  title: 'Title Customization',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'fontSize', 'travessao', 'container'],
    },
  ],

  properties: {
    title: {
      widget: 'text',
      title: 'Título:',
    },
    fontSize: {
      type: 'select_widget',
      placeholder: 'Escolha um tamanho de fonte...',
      title: 'Tamanho da fonte do texto: ',
      choices:
        [
          ['20px', '20px'],
          ['22px', '22px'],
          ['24px', '24px'],
          ['32px', '32px'],
          ['48px', '48px'],
          ['56px', '56px']]
    },
    container: {
      title: 'Escolha o container para o componente',
      widget: 'select',
      choices: [
        ['0', 'Tela inteira'],
        ['1', 'Ensino-Institucional'],
        ['2', 'Pesquisa-Extensao'],
        ['3', 'Pesquisa-Institucional'],
      ]
    },
    travessao: {
      type: 'boolean',
      title: 'Travessão',
    },
  },
  required: ['title', 'container'],

});