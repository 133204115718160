import { twMerge } from 'tailwind-merge';

export const IconPhone = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1471 8.5H19.4412L23.0882 17.6176L18.5294 20.3529C20.4823 24.3128 23.6872 27.5177 27.6471 29.4706L30.3824 24.9118L39.5 28.5588V35.8529C39.5 36.8202 39.1158 37.7478 38.4318 38.4318C37.7478 39.1158 36.8202 39.5 35.8529 39.5C28.7398 39.0677 22.0309 36.0471 16.9919 31.0081C11.9529 25.9691 8.93227 19.2602 8.5 12.1471C8.5 11.1798 8.88424 10.2522 9.5682 9.5682C10.2522 8.88424 11.1798 8.5 12.1471 8.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

