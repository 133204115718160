//@ts-check
//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
/**
 *
 * @param {string} url
 * @returns
 */
export const urlImageHandler = (url) =>
  isInternalURL(url) ? `${flattenToAppURL(url)}/@@images/image` : url;
