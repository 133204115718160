import { twMerge } from 'tailwind-merge';

export const IconMail = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 13.6429C5.5 12.5441 5.93313 11.4904 6.70412 10.7134C7.4751 9.93648 8.52078 9.5 9.61111 9.5H38.3889C39.4792 9.5 40.5249 9.93648 41.2959 10.7134C42.0669 11.4904 42.5 12.5441 42.5 13.6429M5.5 13.6429V34.3571C5.5 35.4559 5.93313 36.5096 6.70412 37.2866C7.4751 38.0635 8.52078 38.5 9.61111 38.5H38.3889C39.4792 38.5 40.5249 38.0635 41.2959 37.2866C42.0669 36.5096 42.5 35.4559 42.5 34.3571V13.6429M5.5 13.6429L24 26.0714L42.5 13.6429" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

