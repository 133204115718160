import postItView from './View';
import postItEdit from './Edit';
import postItIcon from '../../assets/icons/postIt.svg';

const postIt = {
  id: 'postit',
  title: 'post it',
  edit: postItEdit,
  view: postItView,
  icon: postItIcon,
  group: 'common',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};

export default postIt;
