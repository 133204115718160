import { IconChoices } from '../../../assets/icons/buttons'

const itemSchema = () => {

  return {
    title: 'Botão',
    addItem: '01',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'icon', 'newTab', 'link'],
      },
    ],

    properties: {
      title: {
        title: 'Texto:',
        widget: 'text',
      },
      newTab: {
        title: 'Abrir em uma nova aba',
        type: 'boolean',
      },
      link: {
        title: 'Link',
        mode: 'link',
      },
      icon: {
        title: 'Ícone:',
        choices: IconChoices,
        default: 'Default'
      }
    },
    required: ['title', 'icon'],
  };
};

export const geralSchema = () => ({

  title: 'Botões em grid',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['layout', 'color', 'size', 'buttons'],
    },
  ],
  properties: {
    buttons: {
      widget: 'object_list',
      title: 'Adicionar botão',
      schema: itemSchema,
    },
    color: {
      title: "Cor dos ícones:",
      widget: 'color_picker',
      colors: [
        { name: 'black', label: 'preto' },
        { name: 'blue', label: 'azul' },
      ],
    },
    size: {
      title: "Tamanho dos ícones:",
      choices: [
        ['48px', '48px'],
        ['32px', '32px'],
      ],
    },
    layout: {
      title: "Escolha o layout dos botões:",
      choices: [
        ['0', 'Grid buttons'],
        ['1', 'Group buttons sem descrição'],
        ['2', 'Group buttons com descrição'],
        ['3', 'Flex buttons']
      ],
    }
  },
  required: ['layout', 'color', 'size'],
})
