//@ts-check
import React from 'react';
import { Segment } from 'semantic-ui-react';
//@ts-ignore
import { BlockDataForm } from '@plone/volto/components';
import { SocialMediaSchema } from './SocialMediaSchema';

export const SocialMediaData = (props) => {
  const { data, block, onChangeBlock } = props;
  const Schema = SocialMediaSchema;

  return (
    <Segment.Group raised>
      <BlockDataForm
        schema={Schema}
        title={Schema.title}
        onChangeField={(id, value) => {
          onChangeBlock(block, {
            ...data,
            [id]: value,
          });
        }}
        formData={data}
        block={block}
      ></BlockDataForm>
    </Segment.Group>
  );
};
