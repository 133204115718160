import HighlightButtonView from './View';
import HighlighButtonEdit from './Edit';
import icon from '@plone/volto/icons/list-bullet.svg';

export default {
  id: 'highlightButton',
  title: 'Highligh Button',
  group: 'utfprButtons',
  view: HighlightButtonView,
  edit: HighlighButtonEdit,
  icon: icon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};