import { twMerge } from 'tailwind-merge';

export const IconTool = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5779 19.6858C15.1405 20.2484 15.9036 20.5645 16.6992 20.5645H17.5645C19.2213 20.5645 20.5645 19.2213 20.5645 17.5645V16.6992C20.5645 15.9036 20.2484 15.1405 19.6858 14.5779L15.6053 10.4974C15.1172 10.0094 15.2616 9.18668 15.9195 8.97803C17.4688 8.48664 19.1201 8.36998 20.7354 8.64713C22.8176 9.00437 24.7378 9.99833 26.2316 11.4921C27.7254 12.986 28.7194 14.9061 29.0766 16.9883C29.4338 19.0704 29.1368 21.2121 28.2263 23.1184L38.4421 33.3342C39.1195 34.0116 39.5 34.9303 39.5 35.8882C39.5 36.8461 39.1195 37.7648 38.4421 38.4421C37.7648 39.1195 36.8461 39.5 35.8882 39.5C34.9303 39.5 34.0116 39.1195 33.3342 38.4421L23.1184 28.2263C21.2121 29.1368 19.0704 29.4338 16.9883 29.0766C14.9061 28.7194 12.986 27.7254 11.4921 26.2316C9.99833 24.7378 9.00437 22.8176 8.64713 20.7354C8.36998 19.1201 8.48664 17.4688 8.97803 15.9195C9.18668 15.2616 10.0094 15.1172 10.4974 15.6053L14.5779 19.6858Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

