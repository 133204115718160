//@ts-check
import React, { useMemo } from 'react';
import { Cell } from '../Cell/Cell';
import { twMerge } from 'tailwind-merge';
import { CustomContainer } from '../../general';
import './css/completeTable.css';

/**
 * @param {import("../type").TableData & {mode:"edit"|"view",className:React.HTMLAttributes<HTMLTableElement>['className']}} props
 */
export const Table = (props) => {
  // length limiting table
  const lengthLimitedTable = limitSizeTable(
    //@ts-ignore
    props.cells,
    props.rows,
    props.cols,
  );

  const table = useMemo(() => {
    return (
      <CustomContainer
        size="ensino-institucional"
        alignCenter={true}
        className=""
      >
        <table
          className={twMerge(
            `table [&>tr]:flex [&>tr>td]:basis-full [&>tr>td]:w-full [&>tr>td]:relative [&>tr>td_*]:!break-words [&>tr>td]:border-b [&>tr>td]:border-slate-300 w-full complete-table ${
              props.linesHeight == 'sm'
                ? '[&>tr>td]:min-h-[1.5rem] '
                : props.linesHeight == 'md'
                ? '[&>tr>td]:min-h-[3rem]'
                : props.linesHeight == 'lg'
                ? '[&>tr>td]:min-h-[4.5rem]'
                : '[&>tr>td]:min-h-[3rem]'
            }`,
            props.className,
          )}
        >
          {lengthLimitedTable?.map((rows, nRows) => (
            <tr key={'tr' + nRows.toString()}>
              {rows.map((cols, nCols) => {
                const cell = { ...cols };

                cell.position = { col: nCols, row: nRows };

                return (
                  <td
                    className={`${
                      (props.cols ? props.cols : 0) / 2 > nCols
                        ? 'td-left'
                        : 'td-right'
                    }`}
                    key={'tr' + nRows.toString() + 'td' + nCols.toString()}
                  >
                    <Cell {...cell} mode={props.mode}></Cell>
                  </td>
                );
              })}
            </tr>
          ))}
        </table>
      </CustomContainer>
    );
  }, undefined);

  return table;
};

/**
 *
 * @param {import('../Cell/types').CellProps[][] | null} table
 * @param {number | null} rows
 * @param {number | null} cols
 */
function limitSizeTable(table, rows, cols) {
  if (!table || !rows || !cols) return [];

  /** @type {import('../Cell/types').CellProps[][]} */
  const resizedTable = [];

  for (let i = 0; i < table.length && i < rows; i++) {
    const newCol = [];

    for (let j = 0; j < table[0].length && j < cols; j++)
      newCol.push(table[i][j]);

    resizedTable.push(newCol);
  }

  return resizedTable;
}
