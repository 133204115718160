//@ts-check
import React, { useState } from 'react';

export const PosPostItLayout = (props) => {
  const { data, onChangeBlock, editable, block } = props;

  return (
    <div
      className="grid grid-cols-2 p-4 gap-4 w-full"
      style={{ backgroundColor: data.color }}
    >
      <div className="flex flex-col gap-4">
        <div>
          <h6 className="font-bold">Duração</h6>
          <EditableField
            desc=""
            block={block}
            onChangeBlock={onChangeBlock}
            data={data}
            field="timeSpan"
            editable={editable}
            className=""
          ></EditableField>
        </div>
        <div>
          <h6 className="font-bold">{data.presence}</h6>
          <EditableField
            desc=""
            block={block}
            onChangeBlock={onChangeBlock}
            data={data}
            field="campus"
            editable={editable}
            className=""
          ></EditableField>
        </div>
      </div>
      <div className="flex flex-col gap-4 [&_em]:font-bold">
        <EditableField
          desc="Modalidade: "
          block={block}
          onChangeBlock={onChangeBlock}
          data={data}
          field="modality"
          editable={editable}
          className=""
        ></EditableField>
        <EditableField
          desc="Conceito: "
          block={block}
          onChangeBlock={onChangeBlock}
          data={data}
          field="concept"
          editable={editable}
          className=""
        ></EditableField>
        <EditableField
          desc="Seleção: "
          block={block}
          onChangeBlock={onChangeBlock}
          data={data}
          field="selection"
          editable={editable}
          className=""
        ></EditableField>
        <EditableField
          desc="Disponibilidade de Bolsa: "
          block={block}
          onChangeBlock={onChangeBlock}
          data={data}
          field="bolsa"
          editable={editable}
          className=""
        ></EditableField>

        {data.label ? (
          data.label[0] ? (
            data.label.map((tag) => {
              if (!tag.text) return <></>;

              return (
                <span className="text-center border rounded-2xl border-black">
                  {tag.text}
                </span>
              );
            })
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

/**
 *
 * @param {object} props
 * @param {any} props.onChangeBlock
 * @param {any} props.editable
 * @param {string} props.field
 * @param {any} props.block
 * @param {string} props.desc
 * @param {any} props.data
 * @param {string} props.className
 */
const EditableField = ({
  data,
  block,
  className,
  editable,
  field,
  onChangeBlock,
  desc,
}) => {
  if (!editable)
    return (
      <span className={className}>
        {desc ? <em>{desc}</em> : ''}
        {data[field]}
      </span>
    );

  return (
    <div className={className}>
      {desc ? <em>{desc}</em> : <></>}
      <input
        type="text"
        className="bg-[#fff8]"
        placeholder={desc ? desc : undefined}
        value={data[field]}
        onChange={(ev) => {
          onChangeBlock(block, {
            ...data,
            [field]: ev.target.value,
          });
        }}
      ></input>
    </div>
  );
};
