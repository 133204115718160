//@ts-check
import React from 'react';
import { CellEdit } from './CellEdit';
import { CellView } from './CellView';

/**
 *
 * @param {import('./types').CellProps & {mode:"view"|"edit"}} props
 */
export const Cell = (props) => {
  // chooses if will use cell view or cell edit
  if (props.mode == 'edit')
    return (
      <CellEdit
        type={props.type}
        position={props.position}
        text={props.text}
        attributes={props.attributes}
      ></CellEdit>
    );

  return (
    <CellView
      type={props.type}
      position={props.position}
      text={props.text}
      attributes={props.attributes}
    ></CellView>
  );
};
