
export const geralSchema = (props) => ({

  title: 'Botão Inscrever-se',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['mode', 'container'],
    },
  ],

  properties: {
    mode: {
      type: 'select_widget',
      title: 'Estilo do Botão',
      choices: [
        ["1", 'Outline'],
        ["2", 'Solid']
      ],
    },
    container: {
      title: 'Escolha o container para o componente:',
      widget: 'select',
      choices: [
        ['1', 'Ensino-Institucional'],
        ['2', 'Pesquisa-Extensao'],
        ['3', 'Pesquisa-Institucional'],
      ]
    },
  },
  required: ['mode', 'container'],
});