import { twMerge } from 'tailwind-merge';

export const IconNotePen = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 8.5H13.8889C13.1227 8.5 12.3879 8.8629 11.8461 9.50885C11.3044 10.1548 11 11.0309 11 11.9444V36.0556C11 36.9691 11.3044 37.8452 11.8461 38.4911C12.3879 39.1371 13.1227 39.5 13.8889 39.5H34.1111C34.8773 39.5 35.6121 39.1371 36.1539 38.4911C36.6956 37.8452 37 36.9691 37 36.0556V24" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.7998 10.9636C33.2223 10.5551 33.7953 10.3257 34.3928 10.3257C34.9903 10.3257 35.5634 10.5551 35.9859 10.9636C36.4084 11.372 36.6457 11.926 36.6457 12.5037C36.6457 13.0813 36.4084 13.6353 35.9859 14.0437L25.8966 23.7974L21.6484 24.8241L22.7105 20.7173L32.7998 10.9636Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

