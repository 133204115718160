import React from 'react';

//@ts-check
export const IconFilterUp = ({ className }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.5 4.21289L4.875 0.83789L8.25 4.21289"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.875 0.837891L4.875 14.3379"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.375 12.0879L19.5 12.0879"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 7.58789L18.375 7.58789"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.75 3.08789L16.125 3.08789"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
