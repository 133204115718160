import React from 'react';

const Chip = ({ text, children }) => {
  return (
    <span className="text-[14px] text-[#0D1117] dark:text-[#F8FAFA] border border-[#C1C1C1] rounded-full px-3 text-center flex justify-center items-center gap-2">
      {text}
      {children}
    </span>
  );
};

export default Chip;
