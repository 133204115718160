import { twMerge } from 'tailwind-merge';

export const IconSearch = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.5 39.5L29.1667 29.1667M8.5 20.5556C8.5 22.1387 8.81183 23.7064 9.41767 25.169C10.0235 26.6317 10.9115 27.9607 12.031 29.0801C13.1505 30.1996 14.4794 31.0876 15.9421 31.6934C17.4047 32.2993 18.9724 32.6111 20.5556 32.6111C22.1387 32.6111 23.7064 32.2993 25.169 31.6934C26.6317 31.0876 27.9607 30.1996 29.0801 29.0801C30.1996 27.9607 31.0876 26.6317 31.6934 25.169C32.2993 23.7064 32.6111 22.1387 32.6111 20.5556C32.6111 18.9724 32.2993 17.4047 31.6934 15.9421C31.0876 14.4794 30.1996 13.1505 29.0801 12.031C27.9607 10.9115 26.6317 10.0235 25.169 9.41767C23.7064 8.81183 22.1387 8.5 20.5556 8.5C18.9724 8.5 17.4047 8.81183 15.9421 9.41767C14.4794 10.0235 13.1505 10.9115 12.031 12.031C10.9115 13.1505 10.0235 14.4794 9.41767 15.9421C8.81183 17.4047 8.5 18.9724 8.5 20.5556Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

