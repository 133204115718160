import cx from 'classnames';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Accordion, Input } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { defineMessages, injectIntl } from 'react-intl';
import { IconChevronDown } from '../../../../../../../addons/utfpr_components/src/assets/icons/buttons/IconChevronDown'

const messages = defineMessages({
  EnterTitle: {
    id: 'Enter Title',
    defaultMessage: 'Enter Title',
  },
});

const AccordionEdit = (props) => {
  const {
    children,
    handleTitleChange,
    handleTitleClick,
    uid,
    panel,
    data,
    index,
    intl,
  } = props;
  const [activeIndex, setActiveIndex] = React.useState([0]);
  const accordionConfig = config.blocks.blocksConfig.accordion;
  const isActive = activeIndex.includes(index);

  const handleClick = (e, itemProps) => {
    const { index } = itemProps;
    if (data.non_exclusive) {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [...activeIndex, index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    } else {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    }
  };

  React.useEffect(() => {
    return data.collapsed ? setActiveIndex([]) : setActiveIndex([0]);
  }, [data.collapsed]);

  return (
    <Accordion
      className={
        data.styles ? data.styles.theme : accordionConfig?.defaults?.theme
      }
      {...accordionConfig.options}
    >
      <React.Fragment>
        <Accordion.Title
          /* as={data.title_size} */
          active={isActive}
          index={index}
          onClick={handleClick}
          className={cx('accordion-title !bg-transparent !p-4 align-arrow-right')}
        >
          <IconChevronDown className={`${isActive && '-rotate-90'} transition`} />
          {!data.readOnlyTitles ? (
            <Input
              fluid
              className="input-accordion-title !bold !text-xl !text-black before:content-[''] before:inline-block before:border-t-4 before:w-7 before:mr-3 before:mb-1 before:border-yellow-400"
              transparent
              placeholder={intl.formatMessage(messages.EnterTitle)}
              value={panel?.title || ''}
              onClick={(e) => {
                handleTitleClick();
                e.stopPropagation();
              }}
              onChange={(e) => handleTitleChange(e, [uid, panel])}
            />
          ) : (
            <span className="!bold !text-xl !text-black before:content-[''] before:inline-block before:border-t-4 before:w-7 before:mr-3 before:mb-1 before:border-yellow-400">{panel?.title}</span>
          )}
        </Accordion.Title>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={isActive ? 'auto' : 0}
        >
          <Accordion.Content active={isActive}>{children}</Accordion.Content>
        </AnimateHeight>
      </React.Fragment>
    </Accordion>
  );
};

export default injectIntl(AccordionEdit);
