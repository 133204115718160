import { getChoices } from '../services.js';
import { Layouts } from './layouts.js';

export const sdgSchema = () => {
  return {
    title: 'Ícone SDG',
    addItem: '01',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['icon', 'link'],
      },
    ],

    properties: {
      icon: {
        title: 'Ícone do Botão:',
        isMulti: false,
        type: 'select_widget',
        choices: getChoices(),
      },
      link: {
        title: 'Link do Botão:',
        mode: 'link',
      },
    },
    required: ['icon', 'link'],
  };
};

export const iniciacaoCientificaSchema = () => ({
  title: 'Post it',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'color',
        'layout',
        'text',
        'periodo',
        'orientador',
        'carga',
        'sdg',
      ],
    },
  ],
  properties: {
    color: {
      title: 'Escolha a cor',
      type: 'select_widget',
      choices: [
        ['#B9DDFF', 'Azul'],
        ['#FFEBAF', 'Amarelo'],
        ['#C5EBC0', 'Verde'],
        ['#FFCCD1', 'Rosa'],
        ['#C2A6E4', 'Roxo'],
      ],
    },
    layout: {
      title: 'Escolha o layout',
      type: 'select_widget',
      choices: Layouts,
    },
    sdg: {
      widget: 'object_list',
      title: 'Adicionar botão',
      schema: sdgSchema,
    },
    orientador: {
      title: 'Orientador',
      widget: 'text',
    },
    text: {
      title: 'Periodo',
      widget: 'text',
    },
    periodo: {
      title: 'Duração',
      widget: 'text',
    },
    carga: {
      title: 'Carga',
      widget: 'text',
    },
    label: {
      title: 'Adicionar rótulo',
      widget: 'object_list',
      schema: labelSchema,
    },
  },
  required: ['color', 'layout', 'carga', 'orientador', 'periodo', 'text'],
});

export const labelSchema = () => ({
  title: 'Rótulo customizável',
  addMessage: 'Adicionar',
  addItem: '01',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text'],
    },
  ],
  properties: {
    text: {
      title: 'Escreva um rótulo',
      widget: 'textarea',
    },
  },
  required: ['text'],
});
