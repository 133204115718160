import { twMerge } from 'tailwind-merge';

export const IconPen = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.6667 11.25C31.0169 10.8779 31.4327 10.5828 31.8902 10.3814C32.3478 10.1801 32.8382 10.0764 33.3334 10.0764C33.8287 10.0764 34.3191 10.1801 34.7766 10.3814C35.2342 10.5828 35.6499 10.8779 36.0001 11.25C36.3503 11.6221 36.6281 12.0638 36.8176 12.55C37.0071 13.0361 37.1047 13.5572 37.1047 14.0834C37.1047 14.6096 37.0071 15.1306 36.8176 15.6167C36.6281 16.1029 36.3503 16.5446 36.0001 16.9167L18.0001 36.0417L10.6667 38.1667L12.6667 30.375L30.6667 11.25Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

