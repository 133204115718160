import { twMerge } from 'tailwind-merge';

export const IconLaboratoryFlask = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8133 6H29.1868M20.5422 16.3333H27.4578M20.5422 16.3333L20.5422 6M20.5422 16.3333L13.6265 35.2778C13.5431 35.4449 13.4998 35.6291 13.5 35.8158C13.5002 36.0025 13.544 36.1866 13.6278 36.3535C13.7116 36.5205 13.8332 36.6658 13.983 36.778C14.1328 36.8902 14.3067 36.9662 14.491 37H33.509C33.6933 36.9662 33.8672 36.8902 34.017 36.778C34.1668 36.6658 34.2884 36.5205 34.3722 36.3535C34.456 36.1866 34.4998 36.0025 34.5 35.8158C34.5002 35.6291 34.4569 35.4449 34.3735 35.2778L27.4578 16.3333M27.4578 16.3333L27.4578 6" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

