import { SingleColumnEdit } from './SingleColumnEdit';
import { SingleColumnView } from './SingleColumnView';
import titleComponentIcon from '../../assets/icons/titleComponent.svg';
import { getSchema } from './schema';

export default {
  id: 'singleColumn',
  title: 'Coluna',
  group: 'common',
  view: SingleColumnView,
  edit: SingleColumnEdit,
  blockSchema: getSchema,
  icon: titleComponentIcon,
  restricted: (data) => {
    const { properties } = data;

    return properties['@type'] == 'Document';
  },
  mostUsed: true,
  sidebarTab: 1,
  allowedBlocks: ['slate', 'image'],
};
