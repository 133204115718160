import { twMerge } from 'tailwind-merge';

export const IconDataChart = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 10.4375C8.5 9.92364 8.70413 9.43083 9.06748 9.06748C9.43083 8.70413 9.92364 8.5 10.4375 8.5H37.5625C38.0764 8.5 38.5692 8.70413 38.9325 9.06748C39.2959 9.43083 39.5 9.92364 39.5 10.4375V37.5625C39.5 38.0764 39.2959 38.5692 38.9325 38.9325C38.5692 39.2959 38.0764 39.5 37.5625 39.5H10.4375C9.92364 39.5 9.43083 39.2959 9.06748 38.9325C8.70413 38.5692 8.5 38.0764 8.5 37.5625V10.4375Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.0346 24.0694L30.0902 28.0139L22.2014 20.125L14.3125 28.0139" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}