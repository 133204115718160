//@ts-check
/**
 *
 * @param {string|null|undefined} tel
 * @returns {string}
 */
export function clearTel(tel) {
  if (!tel) return '';
  if (typeof tel != 'string') return '';

  const cleanTel = tel.replace(/[^0-9+\-()]/g, '');

  if (cleanTel.startsWith('+')) return cleanTel;

  return '+55' + cleanTel;
}
