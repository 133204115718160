import ImagesView from './ImagesView'
import { SidebarPortal } from '@plone/volto/components';
import MultipleImagesData from './Data';
import FileWidget from '@plone/volto/components/manage/Widgets/FileWidget';

const ImagesEdit = (props) => {
  return (
    <div>
      <ImagesView {...props} isEditMode={true} />
      <SidebarPortal selected={props.selected}>
        <MultipleImagesData {...props} />
      </SidebarPortal>
    </div>
  )
}

export default ImagesEdit;