// @ts-check
import {
  flattenToAppURL,
  isInternalURL,
  //@ts-ignore
} from '@plone/volto/helpers';
/**
 *
 * @param {string} url
 * @returns {string}
 */
export function urlHandler(url) {
  return isInternalURL(url) ? flattenToAppURL(url) : url;
}
