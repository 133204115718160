export const IconDoubleArrows = ({ size = '20', className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_405_4105)">
        <path
          d="M17 3.13757V21.1376"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 18.1376L7 21.1376L4 18.1376"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 21.1376V3.13757"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 6.13757L17 3.13757L14 6.13757"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_405_4105">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.137573)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
