import { twMerge } from 'tailwind-merge';

export const IconSisu = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:fill-[#FCBC00] group-hover:[&_path]:fill-[#FCBC00] dark:[&_path]:fill-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.7163 30.5057C36.0583 30.5057 34.7572 30.0394 33.813 29.1068C32.8804 28.1741 32.4141 26.827 32.4141 25.0653V18.2432H34.6593V24.9789C34.6593 26.2225 34.9241 27.1263 35.4538 27.6905C35.995 28.2547 36.7549 28.5368 37.7336 28.5368C38.7123 28.5368 39.4665 28.2547 39.9961 27.6905C40.5258 27.1263 40.7906 26.2225 40.7906 24.9789V18.2432H43.0013V25.0653C43.0013 26.827 42.5292 28.1741 41.5851 29.1068C40.6524 30.0394 39.3628 30.5057 37.7163 30.5057Z" fill={color} />
      <path d="M25.4019 30.5067C24.4577 30.5067 23.5538 30.3743 22.6903 30.1094C21.8267 29.8331 21.1416 29.4819 20.635 29.0559L21.4122 27.3115C21.8958 27.6915 22.4945 28.0081 23.2084 28.2614C23.9223 28.5147 24.6534 28.6414 25.4019 28.6414C26.0351 28.6414 26.5475 28.5723 26.939 28.4341C27.3305 28.296 27.6183 28.1117 27.8026 27.8815C27.9868 27.6397 28.0789 27.3691 28.0789 27.0697C28.0789 26.7013 27.9465 26.4076 27.6817 26.1889C27.4168 25.9586 27.0714 25.7801 26.6454 25.6535C26.2309 25.5153 25.7646 25.3886 25.2464 25.2735C24.7398 25.1584 24.2274 25.026 23.7093 24.8763C23.2027 24.7151 22.7363 24.5136 22.3103 24.2718C21.8958 24.0185 21.5561 23.6846 21.2913 23.27C21.0265 22.8555 20.8941 22.3259 20.8941 21.6811C20.8941 21.0248 21.0668 20.4261 21.4122 19.8849C21.7692 19.3322 22.3046 18.8947 23.0184 18.5723C23.7438 18.2384 24.6592 18.0714 25.7646 18.0714C26.49 18.0714 27.2096 18.1635 27.9235 18.3478C28.6373 18.532 29.2591 18.7968 29.7888 19.1422L29.0806 20.8866C28.5395 20.5642 27.981 20.3282 27.4053 20.1785C26.8296 20.0173 26.2769 19.9367 25.7473 19.9367C25.1255 19.9367 24.6189 20.0115 24.2274 20.1612C23.8475 20.3109 23.5654 20.5067 23.3811 20.7484C23.2084 20.9902 23.1221 21.2666 23.1221 21.5775C23.1221 21.9459 23.2487 22.2453 23.502 22.4756C23.7669 22.6943 24.1065 22.8671 24.521 22.9937C24.9471 23.1204 25.4191 23.247 25.9373 23.3737C26.4554 23.4888 26.9678 23.6212 27.4744 23.7709C27.9925 23.9206 28.4589 24.1163 28.8734 24.3581C29.2994 24.5999 29.6391 24.9281 29.8924 25.3426C30.1572 25.7571 30.2896 26.281 30.2896 26.9143C30.2896 27.5591 30.1111 28.1578 29.7542 28.7105C29.4088 29.2516 28.8734 29.6892 28.148 30.0231C27.4226 30.3455 26.5072 30.5067 25.4019 30.5067Z" fill={color} />
      <path d="M16.6725 30.3334V21.1105H18.8314V30.3334H16.6725ZM17.7606 19.5907C17.3576 19.5907 17.0237 19.464 16.7589 19.2107C16.5056 18.9574 16.3789 18.6523 16.3789 18.2953C16.3789 17.9269 16.5056 17.6218 16.7589 17.38C17.0237 17.1267 17.3576 17 17.7606 17C18.1636 17 18.4918 17.1209 18.7451 17.3627C19.0099 17.593 19.1423 17.8866 19.1423 18.2435C19.1423 18.6235 19.0156 18.9459 18.7623 19.2107C18.509 19.464 18.1751 19.5907 17.7606 19.5907Z" fill={color} />
      <path d="M9.76685 30.5067C8.82269 30.5067 7.91883 30.3743 7.05527 30.1094C6.19171 29.8331 5.50662 29.4819 5 29.0559L5.7772 27.3115C6.2608 27.6915 6.85953 28.0081 7.57341 28.2614C8.28729 28.5147 9.01844 28.6414 9.76685 28.6414C10.4001 28.6414 10.9125 28.5723 11.304 28.4341C11.6955 28.296 11.9833 28.1117 12.1676 27.8815C12.3518 27.6397 12.4439 27.3691 12.4439 27.0697C12.4439 26.7013 12.3115 26.4076 12.0467 26.1889C11.7818 25.9586 11.4364 25.7801 11.0104 25.6535C10.5959 25.5153 10.1295 25.3886 9.61141 25.2735C9.10479 25.1584 8.59241 25.026 8.07428 24.8763C7.56765 24.7151 7.10133 24.5136 6.67531 24.2718C6.2608 24.0185 5.92113 23.6846 5.65631 23.27C5.39148 22.8555 5.25907 22.3259 5.25907 21.6811C5.25907 21.0248 5.43178 20.4261 5.7772 19.8849C6.13414 19.3322 6.66955 18.8947 7.38343 18.5723C8.10882 18.2384 9.02419 18.0714 10.1295 18.0714C10.8549 18.0714 11.5746 18.1635 12.2885 18.3478C13.0023 18.532 13.6241 18.7968 14.1537 19.1422L13.4456 20.8866C12.9045 20.5642 12.346 20.3282 11.7703 20.1785C11.1946 20.0173 10.6419 19.9367 10.1123 19.9367C9.49051 19.9367 8.98389 20.0115 8.59241 20.1612C8.21245 20.3109 7.93035 20.5067 7.74612 20.7484C7.57341 20.9902 7.48705 21.2666 7.48705 21.5775C7.48705 21.9459 7.61371 22.2453 7.86702 22.4756C8.13185 22.6943 8.47151 22.8671 8.88602 22.9937C9.31205 23.1204 9.78413 23.247 10.3023 23.3737C10.8204 23.4888 11.3328 23.6212 11.8394 23.7709C12.3575 23.9206 12.8239 24.1163 13.2384 24.3581C13.6644 24.5999 14.0041 24.9281 14.2574 25.3426C14.5222 25.7571 14.6546 26.281 14.6546 26.9143C14.6546 27.5591 14.4761 28.1578 14.1192 28.7105C13.7738 29.2516 13.2384 29.6892 12.513 30.0231C11.7876 30.3455 10.8722 30.5067 9.76685 30.5067Z" fill={color} />
    </svg>
  )
}