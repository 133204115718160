export const itemSchema = (props) => {
  return {
    title: 'Marca',
    addItem: '01',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'link', 'icon'],
      },
    ],

    properties: {
      title: {
        title: 'Título da marca:',
      },
      link: {
        title: 'Link da marca:',
        mode: 'link',
      },
      icon: {
        title: 'Ícone da marca:',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
    },
    required: ['title', 'icon', 'link'],
  };
};

export const geralSchema = (props) => ({
  title: 'Marcas',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['brands'],
    },
  ],

  properties: {
    brands: {
      widget: 'object_list',
      title: 'Adicionar Marca',
      schema: itemSchema,
    },
  },
  required: [],
});
