import { twMerge } from 'tailwind-merge';

export const IconPenTool = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 34.5L34.7917 24L39.4167 28.5L28.625 39L24 34.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.2499 25.5L30.9374 14.25L8.58325 9L13.9791 30.75L25.5416 33L33.2499 25.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.58325 9L20.2783 20.379" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.4583 25.5C24.1612 25.5 25.5417 24.1569 25.5417 22.5C25.5417 20.8431 24.1612 19.5 22.4583 19.5C20.7555 19.5 19.375 20.8431 19.375 22.5C19.375 24.1569 20.7555 25.5 22.4583 25.5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

