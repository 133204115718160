export const ContactSchema = (props) => {
  return {
    title: 'Bloco de texto',
    addItem: '01',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['iconOption', 'input'],
      },
    ],
    properties: {
      iconOption: {
        title: "Incluir ícone",
        type: 'boolean',
      },
      input: {
        widget: 'text',
        title: 'Texto:',
      },
    },
  }
}

export const LinkSchema = (props) => {
  return {
    title: 'Link',
    addItem: '01',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['newTab', 'title', 'url'],
      },
    ],
    properties: {
      title: {
        title: 'Título',
        type: 'string',
      },
      url: {
        title: 'URL',
        type: 'string',
      },
      newTab: {
        title: 'Abrir em uma nova aba',
        type: 'boolean',
      }
    },
    required: ['title', 'url'],
  }
}

export const geralSchema = (props) => {

  const { data } = props;

  const schema = {

    title: "Customização geral",
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['containerOption', 'titleOption', 'urlOption'],
      },
    ],
    properties: {
      containerOption: {
        title: "Escolha o tamanho do container",
        default: "ensino-institucional",
        choices: [
          ['full-width', 'Cheio'],
          ['ensino-institucional', 'Ensino-Institucional'],
          ['pesquisa-extensao', 'Pesquisa-Extensao'],
          ['pesquisa-institucional', 'Pesquisa-Institucional'],
        ]
      },
      titleOption: {
        title: 'Incluir título',
        type: 'boolean',
      },
      urlOption: {
        title: 'Incluir links',
        type: 'boolean',
      },
    },
    required: ['containerOption'],
  }

  if (data?.titleOption) {
    const index = schema.fieldsets[0].fields.indexOf('titleOption')
    schema.fieldsets[0].fields.splice(index + 1, 0, 'title')
    schema.properties.title = {
      title: 'Título',
      placeholder: "Insira um título...",
      type: 'string',
    }
  }

  return schema;
};