export const itemSchema = (props) => {
  return {
    title: 'Notícia',
    addItem: '01',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['noticia', 'isFolder'],
      },
    ],

    properties: {
      noticia: {
        title: 'Notícia:',
        widget: 'object_browser',
        allowExternals: false,
        mode: 'link',
      },
      isFolder: {
        title: 'Mostrar noticias desta pasta automaticamente?',
        type: 'boolean',
      },
    },
    required: ['noticia'],
  };
};

export const geralSchema = (props) => ({
  title: 'Painel de notícias',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['type', 'columnModeLimit', 'showImages', 'noticias'],
    },
  ],

  properties: {
    type: {
      title: 'Tipo do conteúdo:',
      widget: 'select',
      choices: [
        ['news', 'Notícias'],
        ['media', 'Vídeos'],
      ],
      default: 'news',
    },
    noticias: {
      widget: 'object_list',
      title: 'Adicionar',
      schema: itemSchema,
    },
    showImages: {
      title: 'Mostrar imagens?',
      type: 'boolean',
      default: true,
    },
    columnModeLimit: {
      title: 'Limite do modo coluna (insira números positivos)',
      type: 'number',
      minimum: 1.0, /* JOE: sadly, doesn't really prevent user from inserting a negative or a decimal number */
    },
  },
  required: ['type'],
});
