import { twMerge } from 'tailwind-merge';

export const IconPlone = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 39.25C32.4223 39.25 39.25 32.4223 39.25 24C39.25 15.5777 32.4223 8.75 24 8.75C15.5777 8.75 8.75 15.5777 8.75 24C8.75 32.4223 15.5777 39.25 24 39.25Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.0164 27.3153C22.6643 27.3153 24.0001 28.6512 24.0001 30.299C24.0001 31.9469 22.6643 33.2827 21.0164 33.2827C19.3686 33.2827 18.0327 31.9469 18.0327 30.299C18.0327 28.6512 19.3686 27.3153 21.0164 27.3153Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.962 20.6847C32.6098 20.6847 33.9457 22.0206 33.9457 23.6684C33.9457 25.3163 32.6098 26.6521 30.962 26.6521C29.3141 26.6521 27.9783 25.3163 27.9783 23.6684C27.9783 22.0206 29.3141 20.6847 30.962 20.6847Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.0164 14.7174C22.6643 14.7174 24.0001 16.0533 24.0001 17.7011C24.0001 19.349 22.6643 20.6848 21.0164 20.6848C19.3686 20.6848 18.0327 19.349 18.0327 17.7011C18.0327 16.0533 19.3686 14.7174 21.0164 14.7174Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

