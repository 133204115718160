//@ts-check
/**
 * @typedef {import("./type").SocialMediaData} SocialMediaData
 * @typedef {keyof SocialMediaData } SocialMediaDataKeys
 */
export const SocialMediaSchema = {
  id: 'SocialMedia',
  title: 'Midias Sociais',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      /** @type {(SocialMediaDataKeys)[]} */
      fields: [
        'showFacebook',
        'showInstagram',
        'showX',
        'xPostLink',
        'showYoutube',
      ],
    },
  ],
  /** @type {{[k in SocialMediaDataKeys]:{title:string, type?: 'boolean' , widget?: 'link'}}} */
  properties: {
    showFacebook: {
      title: 'Mostrar Facebook ?',
      type: 'boolean',
    },
    showInstagram: {
      title: 'Mostrar Instagram ?',
      type: 'boolean',
    },
    showX: {
      title: 'Mostrar X/Twitter ?',
      type: 'boolean',
    },
    xPostLink: {
      title: 'Link do post do twitter:',
      widget: 'link',
    },
    showYoutube: {
      title: 'Mostrar Youtube?',
      type: 'boolean',
    },
  },
  required: [],
};
