//@ts-check
import React from 'react';

/**
 *
 * @param {{hidden:boolean}} props
 */
export const YoutubeWindow = (props) => {
  return (
    <iframe
      className={`w-full aspect-video ${props.hidden ? 'hidden' : ''}`}
      src="https://www.youtube.com/embed/?listType=user_uploads&list=canaldaUTFPR"
    ></iframe>
  );
};
