import React, { useContext } from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { SidebarPortal, Field } from '@plone/volto/components';
import View from './View.jsx';
import { isImageObjectBrowser } from '../../helpers/isImageObjectBrowser.js';

const HeroEdit = (props) => {
  const { selected, data, onChangeBlock, block } = props;

  return (
    <div>
      <View {...props} />
      <SidebarPortal selected={selected}>
        <Segment.Group raised>
          <header className="header pulled">
            <h2>Notícia em destaque</h2>
          </header>
          <Form>
            <Field
              id="files"
              widget="object_browser"
              title="Notícia"
              value={data.files}
              onChange={(id, value) => {
                onChangeBlock(block, { ...data, [id]: value });
              }}
            />

            {data.files &&
              data.files.length > 0 &&
              data.files.map((file, index) => {
                return (
                  <div key={index} className="h-full">
                    <header className="header pulled">
                      <h2>{file.title}</h2>
                    </header>
                    {/** if the object browsed is a image, the editor will give the option of putting a custom title and a custom link*/}
                    {isImageObjectBrowser(file) ? (
                      <>
                        <Field
                          id={`imageLink${index}`}
                          title={`link`}
                          widget="url"
                          value={data[`imageLink${index}`]}
                          onChange={(id, value) => {
                            onChangeBlock(block, { ...data, [id]: value });
                          }}
                        />
                        <Field
                          id={`imageTitle${index}`}
                          title={`titulo`}
                          widget="text"
                          value={data[`imageTitle${index}`]}
                          onChange={(id, value) => {
                            onChangeBlock(block, { ...data, [id]: value });
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <Field
                      id={`hide_title_${index}`}
                      title={`Esconder título`}
                      type="boolean"
                      value={data[`hide_title_${index}`]}
                      onChange={(id, value) => {
                        onChangeBlock(block, { ...data, [id]: value });
                      }}
                    />
                    <Field
                      id={`imageVerticalOffset${index}`}
                      title={`Ajuste vertical`}
                      widget="select"
                      choices={[
                        ['top', 'cima'],
                        ['center', 'meio'],
                        ['bottom', 'baixo'],
                      ]}
                      value={data[`imageVerticalOffset${index}`]}
                      onChange={(id, value) => {
                        onChangeBlock(block, { ...data, [id]: value });
                      }}
                    />
                  </div>
                );
              })}
          </Form>
        </Segment.Group>
      </SidebarPortal>
    </div>
  );
};

export default HeroEdit;
