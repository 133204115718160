//@ts-check

import React from 'react';
import { CustomContainer } from '../general/CustomContainers/CustomContainer';

/**
 *
 * @param {{data:import('./dataSubjectType').DataSubjectType,isEditMode:boolean}} props
 * @returns
 */
export const SubjectView = (props) => {
  return (
    <CustomContainer
      className="first:top-8 only:top-0 relative -my-4 [.block-editor-subject:first-child_&]:top-8"
      size="ensino-institucional"
      alignCenter={true}
    >
      <p className=" font-bold text-slate-600 uppercase dark:text-slate-600 min-h-4">
        {props.data.subject ? props.data.subject : 'Insira o assunto'}
      </p>
      <p className="text-red-400 hidden [.block-editor-subject_&]:block [.block-editor-subject:first-child_&]:hidden ">
        Esse texto deve ser inserido por primeiro na página
      </p>
    </CustomContainer>
  );
};
