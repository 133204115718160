//@ts-check
import { CompleteTableEdit } from './CompleteTableEdit';
import { CompleteTableView } from './CompleteTableView';
//@ts-ignore
import icon from '../../assets/icons/svg/table.svg';

export default {
  id: 'completeTable',
  title: 'Tabela completa',
  icon: icon,
  group: 'common',
  view: CompleteTableView,
  edit: CompleteTableEdit,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
