import { twMerge } from 'tailwind-merge';

export const IconLayoutList = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1608_81)">
        <path d="M7.99951 12C7.99951 10.9391 8.42094 9.92172 9.17108 9.17157C9.92123 8.42143 10.9386 8 11.9995 8H35.9995C37.0604 8 38.0778 8.42143 38.8279 9.17157C39.5781 9.92172 39.9995 10.9391 39.9995 12V16C39.9995 17.0609 39.5781 18.0783 38.8279 18.8284C38.0778 19.5786 37.0604 20 35.9995 20H11.9995C10.9386 20 9.92123 19.5786 9.17108 18.8284C8.42094 18.0783 7.99951 17.0609 7.99951 16V12Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.99951 32C7.99951 30.9391 8.42094 29.9217 9.17108 29.1716C9.92123 28.4214 10.9386 28 11.9995 28H35.9995C37.0604 28 38.0778 28.4214 38.8279 29.1716C39.5781 29.9217 39.9995 30.9391 39.9995 32V36C39.9995 37.0609 39.5781 38.0783 38.8279 38.8284C38.0778 39.5786 37.0604 40 35.9995 40H11.9995C10.9386 40 9.92123 39.5786 9.17108 38.8284C8.42094 38.0783 7.99951 37.0609 7.99951 36V32Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1608_81">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

