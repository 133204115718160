import View from './View.jsx';
import Edit from './Edit';
import icon from '@plone/volto/icons/nav.svg';

export default {
  id: 'brandsPanel',
  title: 'Brands Panel',
  group: 'media',
  view: View,
  edit: Edit,
  icon: icon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
