import React from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { CustomContainer } from '../../../../addons/utfpr_components/src/components/general';

const ReportErrorButton = () => {
  return (
    <CustomContainer
      size="ensino-institucional"
      className="mx-auto flex justify-end items-center mt-16 mb-9"
      alignCenter={true}
    >
      <Link
        to="/relatar-erros"
        className="flex justify-center items-center gap-3 border border-[#8D9199] text-[#8D9199] text-[14px] py-1 px-4 rounded hover:bg-[#FF5451] hover:text-white transition-all"
      >
        <RiCloseCircleLine className="text-[20px]" /> Reportar erro
      </Link>
    </CustomContainer>
  );
};

export default ReportErrorButton;
