export const geralSchema = (props) => ({

  title: 'Customização do divider',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['container'],
    },
  ],

  properties: {
    container: {
      title: 'Escolha o container para o componente',
      widget: 'select',
      choices: [
        ['0', 'Tela inteira'],
        ['1', 'Ensino-Institucional'],
        ['2', 'Pesquisa-Extensao'],
        ['3', 'Pesquisa-Institucional'],
      ]
    },
  },
  required: ['container'],

});