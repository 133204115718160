import { Layouts } from './layouts';

export const cursoSchema = () => ({
  title: 'Post it',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'color',
        'layout',
        'text',
        'periodo',
        'title',
        'fomento',
        'campus',
        'camp',
        'campi',
        'campy',
        'turno',
        'titulacao',
        'pontuacao',
        'label',
      ],
    },
  ],
  properties: {
    color: {
      title: 'Escolha a cor',
      type: 'select_widget',
      choices: [
        ['#B9DDFF', 'Azul'],
        ['#FFEBAF', 'Amarelo'],
        ['#C5EBC0', 'Verde'],
        ['#FFCCD1', 'Rosa'],
        ['#C2A6E4', 'Roxo'],
      ],
    },
    layout: {
      title: 'Escolha o layout',
      type: 'select_widget',
      choices: Layouts,
    },
    title: {
      title: 'Campus 1',
      widget: 'text',
    },
    text: {
      title: 'Duração',
      widget: 'text',
    },
    periodo: {
      title: 'Regime',
      widget: 'text',
    },
    fomento: {
      title: 'Campus 2',
      widget: 'text',
    },
    campus: {
      title: 'Campus 3',
      widget: 'text',
    },
    camp: {
      title: 'Campus 4',
      widget: 'text',
    },
    campi: {
      title: 'Campus 5',
      widget: 'text',
    },
    campy: {
      title: 'Campus 6',
      widget: 'text',
    },
    turno: {
      title: 'Turno',
      widget: 'text',
    },
    titulacao: {
      title: 'Titulação',
      widget: 'text',
    },
    pontuacao: {
      title: 'Pontuação',
      widget: 'text',
    },
    label: {
      title: 'Adicionar rótulo',
      widget: 'object_list',
      schema: labelSchema,
    },
  },
  required: [
    'color',
    'layout',
    'title',
    'periodo',
    'text',
    'campus',
    'camp',
    'campi',
    'campy',
    'fomento',
    'turno',
    'titulacao',
    'pontuacao',
  ],
});

export const labelSchema = () => ({
  title: 'Rótulo customizável',
  addMessage: 'Adicionar',
  addItem: '01',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text'],
    },
  ],
  properties: {
    text: {
      title: 'Escreva um rótulo',
      widget: 'textarea',
    },
  },
  required: ['text'],
});
