//@ts-check
/**
 * @param {{
 * setTable:(props:import("../../../type").TableData)=>any,
 * table:import("../../../type").TableData,
 * cell:import("../../types").CellProps}} props
 */
export function setCell(props) {
  const table = props.table;

  table.cells[props.cell.position.row][props.cell.position.col] = props.cell;

  props.setTable(table);
}
