import { twMerge } from 'tailwind-merge';

export const IconFoodDish = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.5482 36.8321C37.0299 37.8193 36.0069 38.4376 34.892 38.4376H13.1086C11.9937 38.4376 10.9707 37.8193 10.4524 36.8321L8.26343 32.6626H39.7372L37.5482 36.8321Z" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.2312 32.6626H42.7687" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.1189 31.9405C8.1189 22.7005 15.1933 15.3374 24.0001 15.3374C32.807 15.3374 39.8814 22.7005 39.8814 31.9405" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.9998 15.3375C25.5945 15.3375 26.8873 14.0447 26.8873 12.45C26.8873 10.8553 25.5945 9.5625 23.9998 9.5625C22.4051 9.5625 21.1123 10.8553 21.1123 12.45C21.1123 14.0447 22.4051 15.3375 23.9998 15.3375Z" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

