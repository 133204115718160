import { SubjectEdit } from './SubjectEdit';
import { SubjectView } from './SubjectView';
import icon from '@plone/volto/icons/list-bullet.svg';

export default {
  id: 'subject',
  title: 'Assunto',
  group: 'text',
  view: SubjectView,
  edit: SubjectEdit,
  icon: icon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
