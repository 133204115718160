import { twMerge } from 'tailwind-merge';

export const IconMultipleUsers = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.822 36.6406V34.3073C16.822 33.0696 17.3137 31.8826 18.1889 31.0075C19.064 30.1323 20.251 29.6406 21.4887 29.6406H26.1554C27.393 29.6406 28.58 30.1323 29.4552 31.0075C30.3304 31.8826 30.822 33.0696 30.822 34.3073V36.6406M19.1554 20.3073C19.1554 21.545 19.647 22.732 20.5222 23.6071C21.3974 24.4823 22.5843 24.974 23.822 24.974C25.0597 24.974 26.2467 24.4823 27.1219 23.6071C27.997 22.732 28.4887 21.545 28.4887 20.3073C28.4887 19.0696 27.997 17.8826 27.1219 17.0075C26.2467 16.1323 25.0597 15.6406 23.822 15.6406C22.5843 15.6406 21.3974 16.1323 20.5222 17.0075C19.647 17.8826 19.1554 19.0696 19.1554 20.3073Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.97021 29.6947V27.6575C4.97021 26.5768 5.40642 25.5405 6.18286 24.7764C6.95931 24.0122 8.0124 23.583 9.11046 23.583H13.2507C14.3488 23.583 15.4019 24.0122 16.1783 24.7764M7.04034 15.4339C7.04034 16.5145 7.47654 17.5509 8.25299 18.315C9.02943 19.0791 10.0825 19.5084 11.1806 19.5084C12.2786 19.5084 13.3317 19.0791 14.1082 18.315C14.8846 17.5509 15.3208 16.5145 15.3208 15.4339C15.3208 14.3533 14.8846 13.3169 14.1082 12.5528C13.3317 11.7887 12.2786 11.3594 11.1806 11.3594C10.0825 11.3594 9.02943 11.7887 8.25299 12.5528C7.47654 13.3169 7.04034 14.3533 7.04034 15.4339Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M43.03 29.6947V27.6575C43.03 26.5768 42.5938 25.5405 41.8174 24.7764C41.0409 24.0122 39.9878 23.583 38.8898 23.583H34.7495C33.6515 23.583 32.5984 24.0122 31.8219 24.7764M40.9599 15.4339C40.9599 16.5145 40.5237 17.5509 39.7473 18.315C38.9708 19.0791 37.9177 19.5084 36.8197 19.5084C35.7216 19.5084 34.6685 19.0791 33.8921 18.315C33.1156 17.5509 32.6794 16.5145 32.6794 15.4339C32.6794 14.3533 33.1156 13.3169 33.8921 12.5528C34.6685 11.7887 35.7216 11.3594 36.8197 11.3594C37.9177 11.3594 38.9708 11.7887 39.7473 12.5528C40.5237 13.3169 40.9599 14.3533 40.9599 15.4339Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

