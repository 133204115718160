import { twMerge } from 'tailwind-merge';

export const IconSchoolCap = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43 18.2143L24 10.5L5 18.2143L24 25.9286L43 18.2143ZM43 18.2143V29.7857M12.6 21.3V31.7143C12.6 33.2488 13.8011 34.7204 15.939 35.8054C18.0769 36.8904 20.9765 37.5 24 37.5C27.0235 37.5 29.9231 36.8904 32.061 35.8054C34.1989 34.7204 35.4 33.2488 35.4 31.7143V21.3" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

