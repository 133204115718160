import { twMerge } from 'tailwind-merge';

export const IconHomeCheck = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.9774 30.7503V36.5074C39.9774 38.1642 38.6343 39.5074 36.9775 39.5074H12.9026C11.2457 39.5074 9.90259 38.1642 9.90259 36.5074V23.3315C9.90259 22.4797 10.2646 21.6682 10.8984 21.0991L22.9358 10.292C24.0759 9.26845 25.8041 9.26845 26.9442 10.292L32.4587 15.2429" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M8.02271 23.5301L22.9467 10.2643C24.0834 9.25395 25.7962 9.25395 26.9329 10.2643L33.3984 16.0114" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M23.0601 26.937L26.9692 30.8189C27.3591 31.2062 27.9885 31.2062 28.3785 30.8189L39.9772 19.3008" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

