import { twMerge } from 'tailwind-merge';

export const IconFlickr = ({ className, changeColorOnHover = false, color = "#005DB7", size = '48' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_circle]:fill-[#FCBC00] peer-hover:[&_rect]:stroke-[#FCBC00] group-hover:[&_circle]:fill-[#FCBC00] group-hover:[&_rect]:stroke-[#FCBC00] dark:[&_circle]:fill-[#A9C7FF] dark:[&_rect]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8.25" y="9.25" width="31.5" height="29.5" rx="6.75" stroke={color} strokeWidth="2.5" />
      <circle cx="30" cy="24" r="5" fill={color} />
      <circle cx="18" cy="24" r="5" fill={color} />
    </svg>
  );
}