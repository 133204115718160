/**
 * Breadcrumbs components.
 */

import React from 'react';

const VoltoFormFieldWrapper = (props) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-[16px]">{props.title}</div>
      {props.children}
    </div>
  );
};

export default VoltoFormFieldWrapper;
