import { Layouts } from './layouts';

export const gSchema = () => ({
  title: 'Post it',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'color',
        'layout',
        'title1',
        'href1',
        'title2',
        'href2',
        'title3',
        'href3',
        'title4',
        'href4',
        'title5',
        'href5',
        'title6',
        'href6',
        'title7',
        'href7',
        'title8',
        'href8',
        'title9',
        'href9',
        'title10',
        'href10',
        'title11',
        'href11',
        'title12',
        'href12',
        'title13',
        'href13',
        'title14',
        'href14',
        'title15',
        'href15',
        'title16',
        'href16',
        'title17',
        'href17',
        'title18',
        'href18',
        'title19',
        'href19',
        'title20',
        'href20',
        'title21',
        'href21',
        'title22',
        'href22',
        'title23',
        'href23',
        'title24',
        'href24',
        'title25',
        'href25',
        'title26',
        'href26',
        'title27',
        'href27',
        'title28',
        'href28',
        'title29',
        'href29',
        'title30',
        'href30',
        'title31',
        'href31',
        'title32',
        'href32',
        'title33',
        'href33',
        'title34',
        'href34',
        'title35',
        'href35',
        'title36',
        'href36',
        'title37',
        'href37',
        'title38',
        'href38',
        'title39',
        'href39',
        'title40',
        'href40',
        'title41',
        'href41',
        'title42',
        'href42',
        'title43',
        'href43',
        'title44',
        'href44',
        'title45',
        'href45',
        'title46',
        'href46',
        'title47',
        'href47',
        'title48',
        'href48',
        'title49',
        'href49',
        'title50',
        'href50',
        'title51',
        'href51',
        'title52',
        'href52',
      ],
    },
  ],
  properties: {
    color: {
      title: 'Escolha a cor',
      type: 'select_widget',
      choices: [
        ['#B9DDFF', 'Azul'],
        ['#FFEBAF', 'Amarelo'],
        ['#C5EBC0', 'Verde'],
        ['#FFCCD1', 'Rosa'],
        ['#C2A6E4', 'Roxo'],
      ],
    },
    layout: {
      title: 'Escolha o layout',
      type: 'select_widget',
      choices: Layouts,
    },
    //3 texto (title(n)) 1 chip(title(n)) 4 link (href(n))
    title1: {
      title: 'Texto - AP',
      widget: 'link',
    },
    href1: {
      title: 'Link:',
      mode: 'link',
    },
    title2: {
      title: 'Texto - AP',
      widget: 'link',
    },
    href2: {
      title: 'Link:',
      mode: 'link',
    },
    title3: {
      title: 'Texto - AP',
      widget: 'link',
    },
    href3: {
      title: 'Link:',
      mode: 'link',
    },
    title4: {
      title: 'Chip - AP',
      widget: 'link',
    },
    href4: {
      title: 'Link:',
      mode: 'link',
    },
    title5: {
      title: 'Texto - CM',
      widget: 'link',
    },
    href5: {
      title: 'Link:',
      mode: 'link',
    },
    title6: {
      title: 'Texto - CM',
      widget: 'link',
    },
    href6: {
      title: 'Link:',
      mode: 'link',
    },
    title7: {
      title: 'Texto - CM',
      widget: 'link',
    },
    href7: {
      title: 'Link:',
      mode: 'link',
    },
    title8: {
      title: 'Chip - CM',
      widget: 'link',
    },
    href8: {
      title: 'Link:',
      mode: 'link',
    },
    title9: {
      title: 'Texto - CP',
      widget: 'link',
    },
    href9: {
      title: 'Link:',
      mode: 'link',
    },
    title10: {
      title: 'Texto - CP',
      widget: 'link',
    },
    href10: {
      title: 'Link:',
      mode: 'link',
    },
    title11: {
      title: 'Texto - CP',
      widget: 'link',
    },
    href11: {
      title: 'Link:',
      mode: 'link',
    },
    title12: {
      title: 'Chip - CP',
      widget: 'link',
    },
    href12: {
      title: 'Link:',
      mode: 'link',
    },
    title13: {
      title: 'Texto - CT',
      widget: 'link',
    },
    href13: {
      title: 'Link:',
      mode: 'link',
    },
    title14: {
      title: 'Texto - CT',
      widget: 'link',
    },
    href14: {
      title: 'Link:',
      mode: 'link',
    },
    title15: {
      title: 'Texto - CT',
      widget: 'link',
    },
    href15: {
      title: 'Link:',
      mode: 'link',
    },
    title16: {
      title: 'Chip - CT',
      widget: 'link',
    },
    href16: {
      title: 'Link:',
      mode: 'link',
    },
    title17: {
      title: 'Texto - DV',
      widget: 'link',
    },
    href17: {
      title: 'Link:',
      mode: 'link',
    },
    title18: {
      title: 'Texto - DV',
      widget: 'link',
    },
    href18: {
      title: 'Link:',
      mode: 'link',
    },
    title19: {
      title: 'Texto - DV',
      widget: 'link',
    },
    href19: {
      title: 'Link:',
      mode: 'link',
    },
    title20: {
      title: 'Chip - DV',
      widget: 'link',
    },
    href20: {
      title: 'Link:',
      mode: 'link',
    },
    title21: {
      title: 'Texto - FB',
      widget: 'link',
    },
    href21: {
      title: 'Link:',
      mode: 'link',
    },
    title22: {
      title: 'Texto - FB',
      widget: 'link',
    },
    href22: {
      title: 'Link:',
      mode: 'link',
    },
    title23: {
      title: 'Texto - FB',
      widget: 'link',
    },
    href23: {
      title: 'Link:',
      mode: 'link',
    },
    title24: {
      title: 'Chip - FB',
      widget: 'link',
    },
    href24: {
      title: 'Link:',
      mode: 'link',
    },
    title25: {
      title: 'Texto - GP',
      widget: 'link',
    },
    href25: {
      title: 'Link:',
      mode: 'link',
    },
    title26: {
      title: 'Texto - GP',
      widget: 'link',
    },
    href26: {
      title: 'Link:',
      mode: 'link',
    },
    title27: {
      title: 'Texto - GP',
      widget: 'link',
    },
    href27: {
      title: 'Link:',
      mode: 'link',
    },
    title28: {
      title: 'Chip - GP',
      widget: 'link',
    },
    href28: {
      title: 'Link:',
      mode: 'link',
    },
    title29: {
      title: 'Texto - LD',
      widget: 'link',
    },
    href29: {
      title: 'Link:',
      mode: 'link',
    },
    title30: {
      title: 'Texto - LD',
      widget: 'link',
    },
    href30: {
      title: 'Link:',
      mode: 'link',
    },
    title31: {
      title: 'Texto - LD',
      widget: 'link',
    },
    href31: {
      title: 'Link:',
      mode: 'link',
    },
    title32: {
      title: 'Chip - LD',
      widget: 'link',
    },
    href32: {
      title: 'Link:',
      mode: 'link',
    },
    title33: {
      title: 'Texto - MD',
      widget: 'link',
    },
    href33: {
      title: 'Link:',
      mode: 'link',
    },
    title34: {
      title: 'Texto - MD',
      widget: 'link',
    },
    href34: {
      title: 'Link:',
      mode: 'link',
    },
    title35: {
      title: 'Texto - MD',
      widget: 'link',
    },
    href35: {
      title: 'Link:',
      mode: 'link',
    },
    title36: {
      title: 'Chip - MD',
      widget: 'link',
    },
    href36: {
      title: 'Link:',
      mode: 'link',
    },
    title37: {
      title: 'Texto - PB',
      widget: 'link',
    },
    href37: {
      title: 'Link:',
      mode: 'link',
    },
    title38: {
      title: 'Texto - PB',
      widget: 'link',
    },
    href38: {
      title: 'Link:',
      mode: 'link',
    },
    title39: {
      title: 'Texto - PB',
      widget: 'link',
    },
    href39: {
      title: 'Link:',
      mode: 'link',
    },
    title40: {
      title: 'Chip - PB',
      widget: 'link',
    },
    href40: {
      title: 'Link:',
      mode: 'link',
    },
    title41: {
      title: 'Texto - PG',
      widget: 'link',
    },
    href41: {
      title: 'Link:',
      mode: 'link',
    },
    title42: {
      title: 'Texto - PG',
      widget: 'link',
    },
    href42: {
      title: 'Link:',
      mode: 'link',
    },
    title43: {
      title: 'Texto - PG',
      widget: 'link',
    },
    href43: {
      title: 'Link:',
      mode: 'link',
    },
    title44: {
      title: 'Chip - PG',
      widget: 'link',
    },
    href44: {
      title: 'Link:',
      mode: 'link',
    },
    title45: {
      title: 'Texto - SH',
      widget: 'link',
    },
    href45: {
      title: 'Link:',
      mode: 'link',
    },
    title46: {
      title: 'Texto - SH',
      widget: 'link',
    },
    href46: {
      title: 'Link:',
      mode: 'link',
    },
    title47: {
      title: 'Texto - SH',
      widget: 'link',
    },
    href47: {
      title: 'Link:',
      mode: 'link',
    },
    title48: {
      title: 'Chip - SH',
      widget: 'link',
    },
    href48: {
      title: 'Link:',
      mode: 'link',
    },
    title49: {
      title: 'Texto - TD',
      widget: 'link',
    },
    href49: {
      title: 'Link:',
      mode: 'link',
    },
    title50: {
      title: 'Texto - TD',
      widget: 'link',
    },
    href50: {
      title: 'Link:',
      mode: 'link',
    },
    title51: {
      title: 'Texto - TD',
      widget: 'link',
    },
    href51: {
      title: 'Link:',
      mode: 'link',
    },
    title52: {
      title: 'Chip - TD',
      widget: 'link',
    },
    href52: {
      title: 'Link:',
      mode: 'link',
    },
  },
  required: ['color', 'layout'],
});
