import TextComponentView from './TextComponentView.jsx';
import TextComponentEdit from './TextComponentEdit';
import textComponentIcon from '../../assets/icons/textComponent.svg';

export default {
  id: 'textComponent',
  title: 'Text Component',
  group: 'text',
  view: TextComponentView,
  edit: TextComponentEdit,
  icon: textComponentIcon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
