import { twMerge } from 'tailwind-merge';

export const IconInstagram = ({ className, changeColorOnHover = false, color = "#005DB7", size = '48' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] peer-hover:[&:nth-child(3)]:fill-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF] dark:[&:nth-child(3)]:fill-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 9H17.3333C12.731 9 9 12.731 9 17.3333V30.6667C9 35.269 12.731 39 17.3333 39H30.6667C35.269 39 39 35.269 39 30.6667V17.3333C39 12.731 35.269 9 30.6667 9H24Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.0007 17.333C27.6826 17.333 30.6673 20.3178 30.6673 23.9997C30.6673 27.6816 27.6826 30.6663 24.0007 30.6663C20.3188 30.6663 17.334 27.6815 17.334 23.9997C17.334 20.3178 20.3188 17.333 24.0007 17.333Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.1673 14C33.6276 14 34.0007 14.3731 34.0007 14.8333C34.0007 15.2936 33.6276 15.6667 33.1673 15.6667C32.7071 15.6667 32.334 15.2936 32.334 14.8333C32.334 14.3731 32.7071 14 33.1673 14Z" fill={color} stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}