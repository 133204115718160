import { twMerge } from 'tailwind-merge';

export const IconHand = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.8222 10.825V22.45V12.375C27.8222 11.0909 28.9054 10.05 30.2416 10.05C31.5778 10.05 32.6611 11.0909 32.6611 12.375V22.45V17.025C32.6611 15.7409 33.7443 14.7 35.0805 14.7C36.4167 14.7 37.5 15.7409 37.5 17.025V30.2C37.5 35.3362 33.1671 39.5 27.8222 39.5H26.0038C23.545 39.5 21.1785 38.6007 19.3847 36.9847L11.3867 29.7792C10.2305 28.7376 10.201 26.9831 11.3217 25.9062C12.4173 24.8534 14.1935 24.8534 15.2891 25.9062L18.1443 28.65V15.475C18.1443 14.1909 19.2275 13.15 20.5638 13.15C21.9 13.15 22.9832 14.1909 22.9832 15.475V22.45V10.825C22.9832 9.54093 24.0665 8.5 25.4027 8.5C26.7389 8.5 27.8222 9.54093 27.8222 10.825Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

