import React from 'react';
import { CustomContainer } from '../general';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { twJoin } from 'tailwind-merge';

const HighlightButtonView = (props) => {
  const { data, isEditMode } = props;

  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  return (
    <CustomContainer
      size={data.container ? data.container : 'pesquisa-extensao'}
      className={twJoin(
        'mx-4 sm:mx-auto bg-dsYellow-50 dark:bg-[#001B3D] dark:text-[#F8FAFA] hover:bg-dsBlue-50 !py-4 !px-6 rounded hover:border-dsBlue-700 dark:hover:border-dsBlue-50 dark:hover:bg-[#003063] border-dsYellow-500 border-l-8 border-solid transition duration-300',
        data.link && 'cursor-pointer',
        isEditMode && 'pointer-events-none',
      )}
      href={urlHandler(data.link)}
    >
      {data.text ? (
        <div
          dangerouslySetInnerHTML={{ __html: data.text.data }}
          className="[&_*]:break-all [&_*]:whitespace-break-spaces "
        />
      ) : (
        <p>Insira um texto</p>
      )}
    </CustomContainer>
  );
};

export default HighlightButtonView;
