import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import { SubjectView } from './SubjectView';
import { SubjectData } from './SubjectData';

export const SubjectEdit = (props) => {
  const { selected } = props;
  return (
    <>
      <SubjectView {...props}></SubjectView>
      <SidebarPortal selected={selected}>
        <SubjectData {...props}></SubjectData>
      </SidebarPortal>
    </>
  );
};
