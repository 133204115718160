//@ts-check
import React from 'react';

/**
 *
 * @param {{hidden:boolean}} props
 */
export const NotFoundWindow = (props) => {
  return (
    <div
      className={`w-full h-full p-4 align-middle ${
        props.hidden ? 'hidden' : ''
      }`}
    >
      <h3 className="text-xl text-center font-bold">
        Midia Social não encontrada
      </h3>
    </div>
  );
};
