import { twMerge } from 'tailwind-merge';

export const IconWomen = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.7374 8.87511C26.9207 8.87511 30.312 12.4082 30.312 16.7664C30.312 21.1247 26.9207 24.6577 22.7374 24.6577C18.5541 24.6577 15.1628 21.1247 15.1628 16.7664C15.1628 12.4082 18.5541 8.87511 22.7374 8.87511Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.6311 24.6577L29.0496 39.1251" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M22.1064 35.1794L32.8371 31.8914" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  )
}

