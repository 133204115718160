import React, { useRef } from 'react';
import { Button, Input } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import { IconSearch } from '../../../../../../addons/utfpr_components/src/assets/icons/buttons/IconSearch';
import { IconMathSum } from '../../../../../../addons/utfpr_components/src/assets/icons/buttons/IconMathSum';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Pesquisar',
  },
});

const SearchInput = (props) => {
  const { data, searchText, setSearchText, isLive, onTriggerSearch } = props;
  const intl = useIntl();

  const searchInputRef = useRef();

  return (
    <div className="flex flex-row justify-between rounded-lg outline outline-1 outline-gray-400 focus-within:outline-2 dark:outline-gray-50 mb-2">
      {searchText && (
        <button
          className="ps-2 group"
          onClick={() => {
            onTriggerSearch('');
            setSearchText('');
          }}
        >
          <IconMathSum
            color="#9ca3af"
            className="rotate-45 group-hover:[&_path]:stroke-red-400 [&_path]:transition-all [&_path]:duration-300"
            size="24"
          ></IconMathSum>
        </button>
      )}

      <input
        id={`${props.id}-searchtext`}
        value={searchText}
        className="p-3.5 w-full my-auto bg-transparent focus-visible:outline-none dark:text-gray-50 dark:focus-visible:text-white placeholder-shown:ps-4"
        placeholder={
          data.searchInputPrompt || intl.formatMessage(messages.search)
        }
        fluid
        onKeyPress={(event) => {
          if (isLive || event.key === 'Enter') onTriggerSearch(searchText);
        }}
        onChange={(event) => {
          setSearchText(event.target.value);
          if (isLive) {
            onTriggerSearch(event.target.value);
          }
        }}
        ref={searchInputRef}
      />
      {isLive && (
        <button
          className="flex items-center justify-center pe-2 group"
          onClick={() => {
            searchInputRef.current.focus();
          }}
        >
          <IconSearch
            color="#9ca3af"
            size="24"
            className="dark:group-hover:[&_path]:stroke-white group-hover:[&_path]:stroke-black [&_path]:transition-all [&_path]:duration-300"
          ></IconSearch>
        </button>
      )}
    </div>
  );
};

export default SearchInput;
