import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import { ContactView } from './ContactView';
import { ContactData } from './ContactData';
import { IconsVisualizer } from '../GridButtons/ButtonsEdit'
import { IconsName } from '../../assets/icons/buttons';
import { IconArrowRight } from '../../assets/icons/buttons/IconArrowRight';
import { twJoin } from 'tailwind-merge';

export const ContactEdit = (props) => {
  const { selected } = props;

  const Maps = IconsName["Maps"];
  const Phone = IconsName["Phone"];

  return (
    <>
      <div className="p-4 border border-solid border-black rounded-md bg-slate-100 dark:bg-slate-700">
        <ContactView {...props} isEditMode={true} />
        < IconsVisualizer {...props} />
        <div className="mt-4 flex flex-col gap-y-4 p-4 bg-fuchsia-200">
          <h1 className="text-2xl underline text-purple-700">Atenção!</h1>
          <p>Para exibir informações relativo à contato, seguir o padrão presente no exemplo abaixo com as informações na seguinte ordem e padronização:</p>
          <ol className="flex flex-col gap-y-4">
            <li className="flex gap-x-2 items-center">< Maps size="32" changeColorOnHover className="shrink-0" /><p>Av. Sete de Setembro, 3165 Rebouças XXXXX-XXX Curitiba PR</p></li>
            <li className="flex gap-x-2 items-center">< Phone size="32" changeColorOnHover className="shrink-0" /><p>+YY (XX) XXXX-XXXX</p></li>
            <li className="flex flex-row gap-5 flex-wrap pl-0 sm:pl-12"><a className={twJoin("flex flex-row gap-x-2 items-center group pointer-events-none")} key={1} href={"#"}><p className="label_large-web_semibold text-[#005DB7] group-hover:text-[#FCBC00] group-hover:dark:text-[#FCBC00] dark:text-[#A9C7FF] transition duration-300">{"Link externo (opcional)"}</p><IconArrowRight className={twJoin("[&_*]:transition [&_*]:duration-300 shrink-0")} size="20" color="#005DB7" changeColorOnHover /></a><a className={twJoin("flex flex-row gap-x-2 items-center group pointer-events-none")} key={2} href={"#"}><p className="label_large-web_semibold text-[#005DB7] group-hover:text-[#FCBC00] group-hover:dark:text-[#FCBC00] dark:text-[#A9C7FF] transition duration-300">{"Link externo (opcional)"}</p><IconArrowRight className={twJoin("[&_*]:transition [&_*]:duration-300 shrink-0 -rotate-45")} size="20" color="#005DB7" changeColorOnHover /></a></li>
          </ol>
        </div>
      </div>
      <SidebarPortal selected={selected}>
        <ContactData {...props} />
      </SidebarPortal>
    </>
  );
};