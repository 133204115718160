import { twMerge } from 'tailwind-merge';

export const IconSpeaker = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.6667 16.2187C37.2138 16.2187 38.6975 16.8319 39.7915 17.9234C40.8854 19.0149 41.5 20.4954 41.5 22.039C41.5 23.5827 40.8854 25.0631 39.7915 26.1546C38.6975 27.2461 37.2138 27.8593 35.6667 27.8593M20.1111 16.2187V37.5599C20.1111 38.0744 19.9063 38.5679 19.5416 38.9318C19.1769 39.2956 18.6824 39.5 18.1667 39.5H16.2222C15.7065 39.5 15.2119 39.2956 14.8473 38.9318C14.4826 38.5679 14.2778 38.0744 14.2778 37.5599V27.8593M24 16.2187L32.7967 8.90444C33.0523 8.69202 33.3633 8.55668 33.6932 8.5143C34.0231 8.47191 34.3583 8.52423 34.6595 8.66513C34.9607 8.80603 35.2154 9.02966 35.3938 9.30983C35.5722 9.58999 35.6668 9.91508 35.6667 10.247V33.831C35.6668 34.1629 35.5722 34.488 35.3938 34.7682C35.2154 35.0483 34.9607 35.272 34.6595 35.4129C34.3583 35.5537 34.0231 35.6061 33.6932 35.5637C33.3633 35.5213 33.0523 35.386 32.7967 35.1735L24 27.8593H8.44444C7.92875 27.8593 7.43417 27.6549 7.06951 27.2911C6.70486 26.9272 6.5 26.4338 6.5 25.9192V18.1588C6.5 17.6442 6.70486 17.1507 7.06951 16.7869C7.43417 16.4231 7.92875 16.2187 8.44444 16.2187H24Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

