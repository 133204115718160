import React, { useState, useEffect } from 'react';
import PostItCartaoLayout from './layouts/Cartao';
import PostItPesquisaLayout from './layouts/Pesquisa';
import PostItCursoLayout from './layouts/Curso';
import PostItIniciacaoCientificaLayout from './layouts/IniciacaoCientifica';
import CampusLayout from './layouts/Campus';
import { CustomContainer } from '../general';
import { PosPostItLayout } from './layouts/Pos';

const ViewComponent = (props) => {
  const [labels, setLabels] = useState([]);
  const { data } = props;

  useEffect(() => {
    const label = data?.label || [];
    if (label && label.length > 4) {
      setLabels(label.slice(0, 4));
    } else {
      setLabels(label);
    }
  }, [data]);

  switch (data.layout) {
    case 'Pesquisa':
      return (
        <CustomContainer
          size="pesquisa-extensao"
          alignCenter={true}
          className=""
        >
          <PostItPesquisaLayout
            {...data}
            labels={labels}
            editable={props.editable}
            onChangeBlock={props.onChangeBlock}
            block={props.block}
          />
        </CustomContainer>
      );
    case 'Campus':
      return (
        <CustomContainer
          size="pesquisa-institucional"
          alignCenter={false}
          className="mx-auto"
        >
          <CampusLayout
            {...data}
            editable={props.editable}
            onChangeBlock={props.onChangeBlock}
            block={props.block}
          ></CampusLayout>
        </CustomContainer>
      );
    case 'Cartão':
      return (
        <div className="mx-auto w-[90%] sm:w-full  sm:max-w-[280px]">
          <PostItCartaoLayout {...data} labels={labels} />
        </div>
      );
    case 'Curso':
      return (
        <CustomContainer
          className="w-[90%]"
          alignCenter={true}
          size="pesquisa-extensao"
        >
          <PostItCursoLayout {...data} labels={labels} />
        </CustomContainer>
      );
    case 'Iniciação Científica':
      return (
        <CustomContainer
          className="w-[90%]"
          alignCenter={true}
          size="pesquisa-extensao"
        >
          <PostItIniciacaoCientificaLayout {...data} />
        </CustomContainer>
      );
    case 'Pos-graduação':
      return (
        <CustomContainer
          className="w-[90%]"
          alignCenter={true}
          size="pesquisa-extensao"
        >
          <PosPostItLayout {...props}></PosPostItLayout>
        </CustomContainer>
      );
  }
  if (props.editable) {
    return <p>Escolha o Layout</p>;
  }
  return (
    <div className="mx-auto w-[90%] sm:w-full  sm:max-w-[280px]">
      <PostItCartaoLayout {...data} labels={labels} />
    </div>
  );
};

export default ViewComponent;
