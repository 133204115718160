import { twMerge } from 'tailwind-merge';

export const IconSunshade = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.042 39.541C9.77945 36.0422 7.99335 31.4191 8.05604 26.6356C8.11872 21.8522 10.0254 17.2775 13.3784 13.8654C16.7315 10.4533 21.2722 8.4671 26.0538 8.32093C30.8355 8.17477 35.489 9.87991 39.0442 13.0808" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.0443 13.0808L13.042 39.5409" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.2607 32.1949C5.59091 12.4069 14.3342 5.60253 32.0845 20.1626" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27 27L39.9455 39.6877" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

