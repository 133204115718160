//@ts-check
import React, { createContext } from 'react';
import {
  NonFunctionalSetterTableData,
  defaultTableData,
  defaultSetSelectedCell,
  defaultSelectedCell,
} from '../const';

export const EditTableContext = createContext({
  table: defaultTableData,
  setTable: NonFunctionalSetterTableData,
  selectedCell: defaultSelectedCell,
  setSelectedCell: defaultSetSelectedCell,
});
