import SubscribeButtonView from './SubscribeButtonView';
import SubscribeButtonEdit from './SubscribeButtonEdit';
import icon from '@plone/volto/icons/list-bullet.svg';

export default {
  id: 'subscribeButton',
  title: 'Subscribe Button',
  group: 'utfprButtons',
  view: SubscribeButtonView,
  edit: SubscribeButtonEdit,
  icon: icon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};

