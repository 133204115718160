import React from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation, Mousewheel } from 'swiper/core';
import { CustomContainer } from '../general';

SwiperCore.use([Navigation, Mousewheel]);

const CarouselContainer = ({ children }) => {
  return (
    <CustomContainer size="full-width" className="relative" alignCenter={true}>
      <CustomContainer
        size="ensino-institucional"
        className="relative xl:static"
        alignCenter={true}
      >
        <Swiper
          spaceBetween={24}
          slidesPerView={2}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          navigation={true}
          style={{
            '--swiper-navigation-color': 'black',
            '--swiper-pagination-color': 'black',
            '--swiper-navigation-size': '30px',
          }}
          containerModifierClass="xl:!overflow-visible overflow-hidden"
        >
          {children}
        </Swiper>
      </CustomContainer>
    </CustomContainer>
  );
};

export default CarouselContainer;
