import { twMerge } from 'tailwind-merge';

export const IconHamburguer = ({ className = '', size = '32', color = "#464646" }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 18L20 18" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M4 12L20 12" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M4 6L20 6" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}