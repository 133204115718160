import React from 'react';

//@ts-check
export const IconFilterDown = ({ className }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 10.9629L4.875 14.3379L1.5 10.9629"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.875 14.3379L4.875 0.837891"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 3.08789L9.375 3.08789"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.375 7.58789L10.5 7.58789"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.125 12.0879L12.75 12.0879"
        stroke="#C3C6CF"
        stroke-width="1.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
