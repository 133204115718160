import { twMerge } from 'tailwind-merge';

export const ImagePlaceholder = ({
  className,
  changeColorOnHover = false,
  size = '48',
  color = '#005DB7',
}) => {
  return (
    <svg
      className={twMerge(
        changeColorOnHover &&
          'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]',
        className,
      )}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.11713 23.6204L11.4536 18.1987C11.8479 17.5579 12.782 17.5657 13.1655 18.213L14.2639 20.0666C14.6513 20.7202 15.5972 20.7202 15.9845 20.0666L19.695 13.8051C20.1179 13.0914 21.1757 13.1733 21.4838 13.9435L24.9342 22.5694"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M27.1894 1.54785H4.81061C3.15375 1.54785 1.81061 2.891 1.81061 4.54785V27.4521C1.81061 29.109 3.15375 30.4521 4.81061 30.4521H27.1894C28.8462 30.4521 30.1894 29.109 30.1894 27.4521V4.54785C30.1894 2.891 28.8462 1.54785 27.1894 1.54785Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
