import { IconArchive } from './IconArchive';
import { IconArrowLeft } from './IconArrowLeft';
import { IconArrowRight } from './IconArrowRight';
import { IconBook } from './IconBook';
import { IconBrowser } from './IconBrowser';
import { IconBlogger } from './IconBlogger';
import { IconBrain } from './IconBrain';
import { IconBulb } from './IconBulb';
import { IconCalendar } from './IconCalendar';
import { IconCarouselHorizontal } from './IconCarouselHorizontal';
import { IconCarouselVertical } from './IconCarouselVertical';
import { IconCash } from './IconCash';
import { IconChart } from './IconChart';
import { IconChat } from './IconChat';
import { IconCheck } from './IconCheck';
import { IconCheckbox } from './IconCheckbox';
import { IconCheckbox2 } from './IconCheckbox2';
import { IconCircleChevronLeft } from './IconCircleChevronLeft';
import { IconCircleChevronRight } from './IconCircleChevronRight';
import { IconChevronDown } from './IconChevronDown';

import { IconCircleInfo } from './IconCircleInfo';
import { IconCircleInfo2 } from './IconCircleInfo2';
import { IconCircleWarning } from './IconCircleWarning';
import { IconClock } from './IconClock';
import { IconCloudUpload } from './IconCloudUpload';
import { IconTemplate } from '../IconTemplate';
import { IconDataChart } from './IconDataChart';
import { IconDesktopDevice } from './IconDesktopDevice';
import { IconDiagonalDoubleArrow } from './IconDiagonalDoubleArrow';
import { IconDiagonalTopRightArrow } from './IconDiagonalTopRightArrow';
import { IconDocument } from './IconDocument';
import { IconDocumentWriting } from './IconDocumentWriting';
import { IconDoubleArrow } from './IconDoubleArrow';
import { IconDoubleArrowDownUp } from './IconDoubleArrowDownUp';
import { IconDoubleChevron } from './IconDoubleChevron';
import { IconDownload } from './IconDownload';
import { IconElectronicComponent } from './IconElectronicComponent';
import { IconEye } from './IconEye';
import { IconFacebook } from './IconFacebook';
import { IconFilledCircle } from './IconFilledCircle';
import { IconFolder } from './IconFolder';
import { IconFoodDish } from './IconFoodDish';
import { IconFlickr } from './IconFlickr';
import { IconGoogleChrome } from './IconGoogleChrome';
import { IconGoogleChrome2 } from './IconGoogleChrome2';
import { IconHand } from './IconHand';
import { IconHeadset } from './IconHeadset';
import { IconHeart } from './IconHeart';
import { IconHome } from './IconHome';
import { IconHomeCheck } from './IconHomeCheck';
import { IconImage } from './IconImage';
import { IconInstagram } from './IconInstagram';
import { IconInternet } from './IconInternet';
import { IconLaboratoryFlask } from './IconLaboratoryFlask';
import { IconLayoutColumns } from './IconLayoutColumns';
import { IconLayoutList } from './IconLayoutList';
import { IconLineCircle } from './IconLineCircle';
import { IconLinkedin } from './IconLinkedin';
import { IconMail } from './IconMail';
import { IconMaps } from './IconMaps';
import { IconMathDivide } from './IconMathDivide';
import { IconMathMinus } from './IconMathMinus';
import { IconMathSum } from './IconMathSum';
import { IconMicrophone } from './IconMicrophone';
import { IconMicrosoftOffice } from './IconMicrosoftOffice';
import { IconMobileDevice } from './IconMobileDevice';
import { IconMultipleUsers } from './IconMultipleUsers';
import { IconNotePen } from './IconNotePen';
import { IconPen } from './IconPen';
import { IconPenTool } from './IconPenTool';
import { IconPet } from './IconPet';
import { IconPhone } from './IconPhone';
import { IconPlone } from './IconPlone';
import { IconR } from './IconR';
import { IconRecycling } from './IconRecycling';
import { IconSchoolCap } from './IconSchoolCap';
import { IconSearch } from './IconSearch';
import { IconSei } from './IconSei';
import { IconShare } from './IconShare';
import { IconSisu } from './IconSisu';
import { IconSpeaker } from './IconSpeaker';
import { IconSquare } from './IconSquare';
import { IconSunshade } from './IconSunshade';
import { IconTable } from './IconTable';
import { IconTableLayoutGrid } from './IconTableLayoutGrid';
import { IconTool } from './IconTool';
import { IconTwitter } from './IconTwitter';
import { IconTiktok } from './IconTiktok';
import { IconUser } from './IconUser';
import { IconVerification } from './IconVerification';
import { IconVideoCamera } from './IconVideoCamera';
import { IconWhatsapp } from './IconWhatsapp';
import { IconWifi } from './IconWifi';
import { IconWomen } from './IconWomen';
import { IconWorkbag } from './IconWorkbag';
import { IconYoutube } from './IconYoutube';

// A ser usado nos schemas que precisarem desses ícones
export const IconChoices = [
  ['Archive', 'Archive'],
  ['Arrow Left', 'Arrow Left'],
  ['Arrow Right', 'Arrow Right'],
  ['Blogger', 'Blogger'],
  ['Book', 'Book'],
  ['Brain', 'Brain'],
  ['Browser', 'Browser'],
  ['Bulb', 'Bulb'],
  ['Calendar', 'Calendar'],
  ['Carousel Horizontal', 'Carousel Horizontal'],
  ['Carousel Vertical', 'Carousel Vertical'],
  ['Cash', 'Cash'],
  ['Chart', 'Chart'],
  ['Chat', 'Chat'],
  ['Check', 'Check'],
  ['Checkbox', 'Checkbox'],
  ['Checkbox 2', 'Checkbox 2'],
  ['Circle Chevron Left', 'Circle Chevron Left'],
  ['Circle Chevron Right', 'Circle Chevron Right'],
  ['Circle Info', 'Circle Info'],
  ['Circle Info 2', 'Circle Info 2'],
  ['Circle Warning', 'Circle Warning'],
  ['Clock', 'Clock'],
  ['Cloud Upload', 'Cloud Upload'],
  ['Data Chart', 'Data Chart'],
  ['Default', 'Default'],
  ['Desktop Device', 'Desktop Device'],
  ['Diagonal Double Arrow', 'Diagonal Double Arrow'],
  ['Diagonal Top Right Arrow', 'Diagonal Top Right Arrow'],
  ['Document', 'Document'],
  ['Document Writing', 'Document Writing'],
  ['Double Arrow', 'Double Arrow'],
  ['Double Arrow Down Up', 'Double Arrow Down Up'],
  ['Double Chevron', 'Double Chevron'],
  ['Download', 'Download'],
  ['Electronic Component', 'Electronic Component'],
  ['Eye', 'Eye'],
  ['Facebook', 'Facebook'],
  ['Filled Circle', 'Filled Circle'],
  ['Flickr', 'Flickr'],
  ['Folder', 'Folder'],
  ['Food Dish', 'Food Dish'],
  ['Google Chrome', 'Google Chrome'],
  ['Google Chrome 2', 'Google Chrome 2'],
  ['Hand', 'Hand'],
  ['Headset', 'Headset'],
  ['Heart', 'Heart'],
  ['Home', 'Home'],
  ['Home Check', 'Home Check'],
  ['Image', 'Image'],
  ['Instagram', 'Instagram'],
  ['Internet', 'Internet'],
  ['Laboratory Flask', 'Laboratory Flask'],
  ['Layout Columns', 'Layout Columns'],
  ['Layout List', 'Layout List'],
  ['Line Circle', 'Line Circle'],
  ['Linkedin', 'Linkedin'],
  ['Mail', 'Mail'],
  ['Maps', 'Maps'],
  ['Math Divide', 'Math Divide'],
  ['Math Minus', 'Math Minus'],
  ['Math Sum', 'Math Sum'],
  ['Microphone', 'Microphone'],
  ['Microsoft Office', 'Microsoft Office'],
  ['Mobile Device', 'Mobile Device'],
  ['Multiple Users', 'Multiple Users'],
  ['Note Pen', 'Note Pen'],
  ['Pen', 'Pen'],
  ['Pen Tool', 'Pen Tool'],
  ['Pet', 'Pet'],
  ['Phone', 'Phone'],
  ['Plone', 'Plone'],
  ['R', 'R'],
  ['Recycling', 'Recycling'],
  ['School Cap', 'School Cap'],
  ['Search', 'Search'],
  ['Sei', 'Sei'],
  ['Share', 'Share'],
  ['Sisu', 'Sisu'],
  ['Speaker', 'Speaker'],
  ['Square', 'Square'],
  ['Sunshade', 'Sunshade'],
  ['Table', 'Table'],
  ['Table Layout Grid', 'Table Layout Grid'],
  ['Tiktok', 'Tiktok'],
  ['Tool', 'Tool'],
  ['Twitter', 'Twitter'],
  ['User', 'User'],
  ['Verification', 'Verification'],
  ['Video Camera', 'Video Camera'],
  ['Wifi', 'Wifi'],
  ['Whatsapp', 'Whatsapp'],
  ['Women', 'Women'],
  ['Workbag', 'Workbag'],
  ['Youtube', 'Youtube'],
];

// Correspondência entre o nome do ícone e o componente
export const IconsName = {
  Archive: IconArchive,
  'Arrow Left': IconArrowLeft,
  'Arrow Right': IconArrowRight,
  Blogger: IconBlogger,
  Book: IconBook,
  Brain: IconBrain,
  Browser: IconBrowser,
  Bulb: IconBulb,
  Calendar: IconCalendar,
  'Carousel Horizontal': IconCarouselHorizontal,
  'Carousel Vertical': IconCarouselVertical,
  Cash: IconCash,
  Chart: IconChart,
  Chat: IconChat,
  Check: IconCheck,
  ChevronDown: IconChevronDown,
  Checkbox: IconCheckbox,
  'Checkbox 2': IconCheckbox2,
  'Circle Chevron Left': IconCircleChevronLeft,
  'Circle Chevron Right': IconCircleChevronRight,
  'Circle Info': IconCircleInfo,
  'Circle Info 2': IconCircleInfo2,
  'Circle Warning': IconCircleWarning,
  Clock: IconClock,
  'Cloud Upload': IconCloudUpload,
  'Data Chart': IconDataChart,
  Default: IconTemplate,
  'Desktop Device': IconDesktopDevice,
  'Diagonal Double Arrow': IconDiagonalDoubleArrow,
  'Diagonal Top Right Arrow': IconDiagonalTopRightArrow,
  Document: IconDocument,
  'Document Writing': IconDocumentWriting,
  'Double Arrow': IconDoubleArrow,
  'Double Arrow Down Up': IconDoubleArrowDownUp,
  'Double Chevron': IconDoubleChevron,
  Download: IconDownload,
  'Electronic Component': IconElectronicComponent,
  Eye: IconEye,
  Facebook: IconFacebook,
  'Filled Circle': IconFilledCircle,
  Flickr: IconFlickr,
  Folder: IconFolder,
  'Food Dish': IconFoodDish,
  'Google Chrome': IconGoogleChrome,
  'Google Chrome 2': IconGoogleChrome2,
  Hand: IconHand,
  Headset: IconHeadset,
  Heart: IconHeart,
  Home: IconHome,
  'Home Check': IconHomeCheck,
  Image: IconImage,
  Internet: IconInternet,
  Instagram: IconInstagram,
  'Laboratory Flask': IconLaboratoryFlask,
  'Layout Columns': IconLayoutColumns,
  'Layout List': IconLayoutList,
  'Line Circle': IconLineCircle,
  Linkedin: IconLinkedin,
  Mail: IconMail,
  Maps: IconMaps,
  'Math Divide': IconMathDivide,
  'Math Minus': IconMathMinus,
  'Math Sum': IconMathSum,
  Microphone: IconMicrophone,
  'Microsoft Office': IconMicrosoftOffice,
  'Mobile Device': IconMobileDevice,
  'Multiple Users': IconMultipleUsers,
  'Note Pen': IconNotePen,
  Pen: IconPen,
  'Pen Tool': IconPenTool,
  Pet: IconPet,
  Phone: IconPhone,
  Plone: IconPlone,
  R: IconR,
  Recycling: IconRecycling,
  'School Cap': IconSchoolCap,
  Search: IconSearch,
  Sei: IconSei,
  Share: IconShare,
  Sisu: IconSisu,
  Speaker: IconSpeaker,
  Square: IconSquare,
  Sunshade: IconSunshade,
  Table: IconTable,
  'Table Layout Grid': IconTableLayoutGrid,
  Tiktok: IconTiktok,
  Tool: IconTool,
  Twitter: IconTwitter,
  User: IconUser,
  Verification: IconVerification,
  'Video Camera': IconVideoCamera,
  Whatsapp: IconWhatsapp,
  Wifi: IconWifi,
  Women: IconWomen,
  Workbag: IconWorkbag,
  Youtube: IconYoutube,
};
