//@ts-check
import { ImgCell } from './ImgCell';
import { TextCell } from './TextCell';

/**@type {import("../types").CellConfig}*/
const CellTypesConfig = {
  text: TextCell,
  img: ImgCell,
};

export { CellTypesConfig };
