import { twMerge } from 'tailwind-merge';

export const IconMicrosoftOffice = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2815 35.223C17.3552 34.6305 17.9246 33.1945 19.0052 33.3978L25.75 34.6667V13.3333L17 16.8889V25.7778L10 29.3333V15.1111L25.75 8L38 11.5556V34.6667L25.75 40L18.2815 35.223Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

