import { twMerge } from 'tailwind-merge';

export const IconBlogger = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {

  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3333 39H30.6667C32.8768 39 34.9964 38.122 36.5592 36.5592C38.122 34.9964 39 32.8768 39 30.6667V25.6667C39 24.3406 38.4732 23.0688 37.5355 22.1311C36.5979 21.1934 35.3261 20.6667 34 20.6667H32.3333V17.3333C32.3333 15.1232 31.4554 13.0036 29.8926 11.4408C28.3298 9.87797 26.2101 9 24 9H17.3333C15.1232 9 13.0036 9.87797 11.4408 11.4408C9.87797 13.0036 9 15.1232 9 17.3333V30.6667C9 32.8768 9.87797 34.9964 11.4408 36.5592C13.0036 38.122 15.1232 39 17.3333 39Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.666 18.167C15.666 17.504 15.9294 16.8681 16.3982 16.3992C16.8671 15.9304 17.503 15.667 18.166 15.667H23.166C23.8291 15.667 24.4649 15.9304 24.9338 16.3992C25.4026 16.8681 25.666 17.504 25.666 18.167C25.666 18.83 25.4026 19.4659 24.9338 19.9348C24.4649 20.4036 23.8291 20.667 23.166 20.667H18.166C17.503 20.667 16.8671 20.4036 16.3982 19.9348C15.9294 19.4659 15.666 18.83 15.666 18.167ZM15.666 29.8337C15.666 29.1706 15.9294 28.5347 16.3982 28.0659C16.8671 27.5971 17.503 27.3337 18.166 27.3337H29.8327C30.4957 27.3337 31.1316 27.5971 31.6004 28.0659C32.0693 28.5347 32.3327 29.1706 32.3327 29.8337C32.3327 30.4967 32.0693 31.1326 31.6004 31.6014C31.1316 32.0703 30.4957 32.3337 29.8327 32.3337H18.166C17.503 32.3337 16.8671 32.0703 16.3982 31.6014C15.9294 31.1326 15.666 30.4967 15.666 29.8337Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}