import { twMerge } from 'tailwind-merge';

export const IconChart = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6213 32.8501V25.2644H35.7999V32.8501" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M24.4214 15.1501V25.2644" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M24.4215 15.1501C26.2835 15.1501 27.7929 13.6406 27.7929 11.7787C27.7929 9.91667 26.2835 8.40723 24.4215 8.40723C22.5595 8.40723 21.05 9.91667 21.05 11.7787C21.05 13.6406 22.5595 15.1501 24.4215 15.1501Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.6214 39.593C14.4834 39.593 15.9929 38.0835 15.9929 36.2215C15.9929 34.3595 14.4834 32.8501 12.6214 32.8501C10.7594 32.8501 9.25 34.3595 9.25 36.2215C9.25 38.0835 10.7594 39.593 12.6214 39.593Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M35.3785 39.593C37.2405 39.593 38.7499 38.0835 38.7499 36.2215C38.7499 34.3595 37.2405 32.8501 35.3785 32.8501C33.5165 32.8501 32.0071 34.3595 32.0071 36.2215C32.0071 38.0835 33.5165 39.593 35.3785 39.593Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}