import { twMerge } from 'tailwind-merge';

export const IconMaps = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.696 19.7251C12.696 25.9731 19.8484 39.579 24 39.579C28.0694 39.579 35.304 25.932 35.304 19.7251C35.304 16.7271 34.113 13.8519 31.9931 11.732C29.8732 9.61209 26.998 8.42114 24 8.42114C21.002 8.42114 18.1268 9.61209 16.0069 11.732C13.887 13.8519 12.696 16.7271 12.696 19.7251Z" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.6659 18.4748C27.7361 19.0057 27.6833 19.5456 27.5113 20.0528C27.3393 20.5599 27.0529 21.0207 26.6743 21.3994C26.2956 21.778 25.8348 22.0644 25.3277 22.2364C24.8205 22.4084 24.2806 22.4612 23.7497 22.391C22.9851 22.2853 22.276 21.9327 21.7302 21.387C21.1844 20.8412 20.8319 20.132 20.7262 19.3675C20.6559 18.8366 20.7088 18.2967 20.8808 17.7895C21.0527 17.2823 21.3392 16.8216 21.7178 16.4429C22.0965 16.0642 22.5572 15.7778 23.0644 15.6059C23.5716 15.4339 24.1115 15.381 24.6424 15.4513C25.405 15.5619 26.1114 15.9161 26.6562 16.4609C27.2011 17.0058 27.5553 17.7122 27.6659 18.4748Z" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

