/**
 * Search widget component.
 * @module components/theme/SearchWidget/SearchWidget
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { GrClose } from 'react-icons/gr';
import { IconSearch } from '.././../../../addons/utfpr_components/src/assets/icons/buttons/IconSearch';
import './styles.css'
import { twMerge } from 'tailwind-merge';

const messages = defineMessages({
  search: {
    id: 'Pesquise aqui',
    defaultMessage: 'Pesquise aqui',
  },
  searchSite: {
    id: 'Pesquise aqui',
    defaultMessage: 'Pesquise aqui',
  },
});

/**
 * SearchWidget component class.
 * @class SearchWidget
 * @extends Component
 */
class SearchWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
    };
    this.withCloseIcon = this.props.withCloseIcon ?? false;
    this.className = this.props.className ?? '';
    this.setFn = this.props.setFn ?? null;
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event, { value }) {
    this.setState({
      text: value,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const path =
      this.props.pathname?.length > 0
        ? `&path=${encodeURIComponent(this.props.pathname)}`
        : '';
    this.props.history.push(
      `/search?SearchableText=${encodeURIComponent(this.state.text)}${path}`,
    );
    // reset input value
    this.setState({
      text: '',
    });
    if (this.setFn) {
      this.setFn();
    }
    event.preventDefault();
  }

  setNewState(e) {
    e.preventDefault();
    if (this.props.setSearchOpen) {
      this.props.setSearchOpen(!this.props.searchOpen)
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {

    return (
      <Form action="/search" onSubmit={this.onSubmit} className={twMerge('h-14 rounded-lg bg-transparent', this.className)}>
        <Form.Field className={`w-full h-full flex justify-between items-center ${!this.withCloseIcon && 'pl-2 sm:pl-5'}`}>
          {this.withCloseIcon && <button aria-label={this.props.intl.formatMessage(messages.search)} className="h-full w-fit px-4 flex justify-center items-center">
            <IconSearch size={28} color="#0D1117" className="[&_path]:stroke-[#0D1117]" />
          </button>}
          <Input
            id="search-input"
            autoFocus={false}
            aria-label={this.props.intl.formatMessage(messages.search)}
            onChange={this.onChangeText}
            name="SearchableText"
            value={this.state.text}
            transparent
            autoComplete="off"
            placeholder={this.props.intl.formatMessage(messages.searchSite)}
            title={this.props.intl.formatMessage(messages.search)}
          />
          {this.withCloseIcon && <button onClick={(e) => this.setNewState(e)} className="h-full w-fit px-4 flex justify-center items-center">
            < GrClose size={20} />
          </button>}
          {!this.withCloseIcon && <button aria-label={this.props.intl.formatMessage(messages.search)} className="h-full w-fit px-4 flex justify-center items-center">
            <IconSearch size={28} color="#0D1117" className="[&_path]:stroke-[#0D1117] dark:[&_path]:stroke-[#F8FAFA]" />
          </button>}
        </Form.Field>
      </Form>
    );
  }
}

export default compose(withRouter, injectIntl)(SearchWidget);
