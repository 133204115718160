import { twMerge } from 'tailwind-merge';

export const IconUser = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 39.5V36.0556C13.5 34.2285 14.2375 32.4763 15.5503 31.1844C16.863 29.8925 18.6435 29.1667 20.5 29.1667H27.5C29.3565 29.1667 31.137 29.8925 32.4497 31.1844C33.7625 32.4763 34.5 34.2285 34.5 36.0556V39.5M17 15.3889C17 17.2159 17.7375 18.9682 19.0503 20.2601C20.363 21.552 22.1435 22.2778 24 22.2778C25.8565 22.2778 27.637 21.552 28.9497 20.2601C30.2625 18.9682 31 17.2159 31 15.3889C31 13.5618 30.2625 11.8096 28.9497 10.5177C27.637 9.22579 25.8565 8.5 24 8.5C22.1435 8.5 20.363 9.22579 19.0503 10.5177C17.7375 11.8096 17 13.5618 17 15.3889Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

