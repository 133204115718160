export const getSchema = (props) => ({
  title: 'Duas colunas',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
  ],

  properties: {},
  required: [],
});
