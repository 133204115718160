import { useEffect } from 'react';
import { searchContent } from '@plone/volto/actions';
import { useSelector, useDispatch } from 'react-redux';

export default function getPageContent(
  path,
  portal_type,
  contentName,
  start_on = 0,
  end_on = 25,
) {
  const dispatch = useDispatch();

  const result = useSelector((state) => {
    return state.search.subrequests?.[contentName]?.items;
  });

  const content = useSelector((state) => {
    return state.workflow.transition;
  });

  async function getContentFn() {
    dispatch(
      searchContent(
        `${path}`,
        {
          portal_type: [portal_type],
          b_start: start_on,
          b_size: end_on,
          fullobjects: true,
        },
        contentName,
      ),
    );
  }

  useEffect(() => {
    let active = true;
    if (active) {
      getContentFn();
    }
    return () => {
      active = false;
    };
  }, [dispatch, content]);

  if (result?.length) {
    return result;
  } else {
    return [];
  }
}
