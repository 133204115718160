import React from 'react';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { GoFile } from 'react-icons/go';
import { MdOutlineFileDownload } from 'react-icons/md';
import { CustomContainer } from 'addons/utfpr_components/src/components/general';

const EditalView = (props) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  function joinCampisText(campi) {
    if (campi.length === 1) return campi[0].title;

    /**@type {string} */
    let joinedText = campi[0].title;

    for (let i = 1; i < campi.length - 1; i++)
      joinedText += ', ' + campi[i].title;

    joinedText += ' e ' + campi[campi.length - 1].title;

    return joinedText;
  }

  return (
    <CustomContainer
      className="w-full flex flex-col gap-8"
      size="ensino-institucional"
      alignCenter={true}
    >
      <div className="flex flex-col justify-start items-start gap-[10px] w-full mt-6">
        <h1 className="text-black dark:text-[#f8fafa]">
          {props.content?.title || ''}
        </h1>
        <div className="text-[13px] text-[#7A7A7A]">
          Publicado {new Date(props.content?.created).toLocaleString()}, última
          modificação {new Date(props.content?.modified).toLocaleString()}
        </div>
      </div>
      <div className="flex flex-col justify-start items-start w-full gap-5">
        <div className="text-black dark:text-[#f8fafa] text-[17px] font-bold pl-3 ml-[-0.75rem] border-solid !border-l-[3px] border-dsYellow-500">
          {props.content?.title || ''}
        </div>
        <div className="text-[14px] text-black dark:text-[#f8fafa] leading-6 tracking-wide">
          {props.content?.description || ''}
        </div>
        {props.content?.campi.length > 0 && (
          <span className=" dark:text-[#f8fafa]">
            Campi: {joinCampisText(props.content?.campi)}
          </span>
        )}
        {props.content?.link_sei_edital && (
          <a
            href={urlHandler(props.content?.link_sei_edital)}
            className="text-[#0169CD] dark:text-[#A9C7FF] font-bold cursor-pointer dark:hover:text-[#ffbe00] hover:text-[#ffbe00]"
          >
            <GoFile className="inline h-6 w-6 mr-3" />
            {props.content?.title}
          </a>
        )}
        {props.content?.arquivo_edital && (
          <a
            href={urlHandler(props.content?.arquivo_edital?.download)}
            className="text-[#0169CD] dark:text-[#A9C7FF] font-bold cursor-pointer dark:hover:text-[#ffbe00] hover:text-[#ffbe00]"
          >
            <MdOutlineFileDownload className="inline h-6 w-6 mr-3" />
            {props.content?.arquivo_edital?.filename || ''}
          </a>
        )}
        {props.content?.items.length > 0 && (
          <div className="flex flex-col gap-1">
            <span className="text-black dark:text-[#f8fafa] text-[17px] font-bold mb-2">
              Arquivos relacionados ao edital
            </span>
            {props.content.items.map((item) => {
              return (
                <a
                  className="text-[13px] text-[#0169CD] dark:text-[#A9C7FF] dark:hover:text-[#ffbe00] hover:text-[#ffbe00]"
                  href={urlHandler(item.url)}
                >
                  {item.title}
                </a>
              );
            })}
          </div>
        )}
      </div>
    </CustomContainer>
  );
};

export default EditalView;
