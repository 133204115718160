import React from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { BlockDataForm } from '@plone/volto/components';
import { schemas } from './Schemas'

const GridButtonsBlockData = (props) => {
  const { onChangeBlock, data } = props;

  const DefineSchema = () => {
    let schema;
    switch (data.layout) {
      case '0':
        schema = schemas.grid({ ...props });
        break;
      case '1':
        schema = schemas.group({ ...props });
        break;
      case '2':
        schema = schemas.groupDescription({ ...props });
        break;
      case '3':
        schema = schemas.flex({ ...props });
        break;
      default:
        schema = schemas.grid({ ...props });
    }
    return schema;
  }

  const schema = DefineSchema();

  return (
    <Segment.Group raised>
      <Form>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
          block={props.block}
          onChangeBlock={onChangeBlock}
        />
      </Form>
    </Segment.Group>
  );
};
export default GridButtonsBlockData;
