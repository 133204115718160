//@ts-check
import React, { useState, useContext } from 'react';
import { CellTypesConfig } from '../..';
import { EditTableContext } from '../../../../store';
import { setCell } from '../../helpers';
/**
 *
 * @typedef {"cellType" | "cellOptions"} ModifyPropT
 */

/**
 * @param {object} props
 * @param {import('../../../types').CellProps} props.cell - celula que sera modificada
 * @param {boolean} props.isCellSelected - se a celula estiver selecionada o menu vai aparecer
 * @param {*} props.children - edição dos atributos do tipo da celula
 *
 * # IMPORTANTE!
 *
 * esse componente deve ser usado dentro das celulas do cellType no modo de edição.
 *
 * a parte de escolher o  tipo da celula é criado a partir do CellTypes/index.js
 */
export const CellOptionsMenu = (props) => {
  // sistema para mudar o tipo de dado editado
  const [modifyProp, setModifyProp] = useState('type'),
    [selectedType, setSelectedType] = useState(props.cell.type);

  const { table, setTable, selectedCell } = useContext(EditTableContext);

  const formatedCellTypesConfig = formatCellTypesConfig(CellTypesConfig);

  return (
    <div
      className={`${
        isSelectedCell(props.cell, selectedCell) ? '' : 'hidden'
      } w-fit min-w-[200px] absolute [.td-left_&]:left-0 [.td-right_&]:right-0 z-50 bg-slate-100 bottom-full rounded-t-md h-fit`}
    >
      <div className="w-full p-2 flex justify-around gap-4 [&_button]:rounded-md bg-zinc-300 [&_button]:p-2 rounded-t-md">
        <button
          onClick={() => {
            setModifyProp('type');
          }}
          className={`${
            modifyProp == 'type' ? 'bg-blue-500 text-slate-50' : 'bg-slate-100 '
          } `}
          title="Tipo da celula"
        >
          Tipo
        </button>
        <button
          onClick={() => {
            setModifyProp('options');
          }}
          className={`${
            modifyProp == 'options'
              ? 'bg-blue-500 text-slate-50'
              : 'bg-slate-100 '
          } `}
          title="Configuração da célula"
        >
          Opt
        </button>
      </div>
      <div
        className={`${
          modifyProp == 'options' ? 'hidden' : ''
        } p-2 grid grid-cols-3`}
      >
        {formatedCellTypesConfig.map((cellType) => {
          return (
            <button
              key={cellType.type}
              onClick={() => {
                //copiando a celula
                const cell = { ...props.cell };
                //trocando os valores
                cell.type = cellType.type;
                cell.attributes = {
                  ...cellType.defaultAttributes,
                  ...cell.attributes,
                };
                //settando o tipo selecionado
                setSelectedType(cellType.type);
                //settando a celula
                setCell({
                  setTable: setTable,
                  table: table,
                  cell: cell,
                });
              }}
              className={`${
                cellType.type == selectedType
                  ? '[&_path]:stroke-blue-500'
                  : ' [&_path]:stroke-slate-700'
              } [&_svg]:w-[42px] [&_svg]:h-[42px]`}
              title={cellType.type}
            >
              <cellType.Icon></cellType.Icon>
            </button>
          );
        })}
      </div>
      <div className={`${modifyProp == 'type' ? 'hidden' : ''} p-2 pt-2 h-fit`}>
        {props.children}
      </div>
    </div>
  );
};

/**
 * @typedef {object} FormatedCellTypes
 * @prop {keyof import('../../../types').CellConfig} type
 * @prop {any} Icon
 * @prop {any} defaultAttributes
 *
 * @param {import('../../../types').CellConfig} cellConfig
 * @returns {FormatedCellTypes[]}
 */
function formatCellTypesConfig(cellConfig) {
  const cellTypes = Object.keys(cellConfig);

  const renderedIcons = [];

  for (let i = 0; i < cellTypes.length; i++)
    renderedIcons.push({
      type: cellTypes[i],
      Icon: cellConfig[cellTypes[i]].Icon,
      defaultAttributes: cellConfig[cellTypes[i]].defaultAttributes,
    });

  //@ts-ignore
  return renderedIcons;
}

/**
 *
 * @param {import('../../../types').CellProps} cell
 * @param {{col:number,row:number}|null} selectedCell
 */
function isSelectedCell(cell, selectedCell) {
  if (!selectedCell) return false;

  return (
    cell.position.row == selectedCell.row &&
    cell.position.col == selectedCell.col
  );
}
