import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import HighlightButtonView from './View';
import HighlightButtonBlockData from './Data';

const HighlighButtonEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <HighlightButtonView {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <HighlightButtonBlockData {...props} />
      </SidebarPortal>
    </>
  );
};

export default HighlighButtonEdit;