//@ts-check
import React, { useContext } from 'react';
import { CellTypesConfig } from './CellTypes/';
import { EditTableContext } from '../store';
/**
 *
 * @param {import('./types').CellProps} props
 */
export const CellEdit = (props) => {
  // selecionar celula
  const { setSelectedCell } = useContext(EditTableContext);

  if (CellTypesConfig[props.type]) {
    // getting cell edit
    const Edit = CellTypesConfig[props.type].Edit;

    return (
      <div
        className="w-full h-full"
        onClick={() => {
          setSelectedCell({ col: props.position.col, row: props.position.row });
        }}
      >
        <Edit {...props}></Edit>
      </div>
    );
  }

  // um retorno padrão

  return (
    <div
      className="w-full h-full"
      onClick={() => {
        setSelectedCell({ col: props.position.col, row: props.position.row });
      }}
    >
      <CellTypesConfig.text.Edit {...props}></CellTypesConfig.text.Edit>
    </div>
  );
};
