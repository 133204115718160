import { twMerge } from 'tailwind-merge';

export const IconLayoutColumns = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1608_80)">
        <path d="M7.99951 12C7.99951 10.9391 8.42094 9.92172 9.17108 9.17157C9.92123 8.42143 10.9386 8 11.9995 8H35.9995C37.0604 8 38.0778 8.42143 38.8279 9.17157C39.5781 9.92172 39.9995 10.9391 39.9995 12V36C39.9995 37.0609 39.5781 38.0783 38.8279 38.8284C38.0778 39.5786 37.0604 40 35.9995 40H11.9995C10.9386 40 9.92123 39.5786 9.17108 38.8284C8.42094 38.0783 7.99951 37.0609 7.99951 36V12Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24 8V40" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1608_80">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

