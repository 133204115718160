import { twMerge } from 'tailwind-merge';

export const IconBulb = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {

  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 8.63281C18.3393 8.63281 13.7505 13.2554 13.7505 18.9576C13.7505 21.5092 14.6693 23.8446 16.1919 25.6464C16.3189 25.7967 16.4365 25.955 16.5365 26.1244L20.4936 32.8231C21.0333 33.7367 22.0155 34.2973 23.0766 34.2973H25.4019C26.52 34.2973 27.5454 33.6754 28.0622 32.6839L31.4399 26.2035C31.5554 25.9818 31.7 25.7769 31.8599 25.5847C33.3514 23.7914 34.2495 21.4801 34.2495 18.9576C34.2495 13.2554 29.6606 8.63281 24 8.63281Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.6074 21.0225L24.0001 25.1524L27.807 21.0225" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M24.0002 25.4475V33.4123" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M21.8755 39.3674H26.782" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  )
}