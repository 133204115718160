import getPageContent from '../../services/searchContent'

export const getChoices = () => {

  const content = getPageContent('/icones/onu','Image', 'Image',0,20)

    const choices = content.map((object) => {
      const name = object.title;
      const image = object.image.download
      
  
      return [image,name]
    });

    return choices;
}