import HiperLinkView from './HiperLinkView.jsx';
import HiperLinkEdit from './HiperLinkEdit';
import hiperlinkIcon from '../../assets/icons/hiperlink.svg';

export default {
  id: 'hiperLink',
  title: 'HiperLink',
  group: 'utfprButtons',
  view: HiperLinkView,
  edit: HiperLinkEdit,
  icon: hiperlinkIcon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
