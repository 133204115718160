import { twMerge } from 'tailwind-merge';

export const IconChat = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1608_8)">
        <path d="M41 11.5H7C5.89543 11.5 5 12.3954 5 13.5V38.7556C5 40.7463 7.59148 41.5147 8.67668 39.8459L12.0084 34.7222C12.3774 34.1548 13.0083 33.8125 13.6851 33.8125H41C42.1046 33.8125 43 32.9171 43 31.8125V13.5C43 12.3954 42.1046 11.5 41 11.5Z" stroke={color} strokeWidth="2.5" />
        <path d="M24.543 23.1875V23.2088" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.8572 23.1875V23.2088" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33.2285 23.1875V23.2088" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1608_8">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}