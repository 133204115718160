import React from 'react';
import { Segment, Form } from 'semantic-ui-react'
import { BlockDataForm, ObjectListWidget } from '@plone/volto/components'
import { geralSchema, ContactSchema, LinkSchema } from './schema';
import { IconChoices } from '../../../src/assets/icons/buttons'

export const ContactData = (props) => {
    const { data, onChangeBlock } = props;

    const schema = geralSchema({ ...props });

    const SchemaExtender = (schema, value) => {
        if (value.iconOption) {
            const schemaCopy = structuredClone(schema);
            const index = schemaCopy.fieldsets[0].fields.indexOf('iconOption')
            schemaCopy.fieldsets[0].fields.splice(index + 1, 0, 'icon')
            schemaCopy.properties.icon = {
                widget: 'select',
                title: 'Ícone',
                choices: IconChoices,
            }
            return schemaCopy;
        } else {
            return schema;
        }
    }

    return (
        <Segment.Group raised>
            <div>
                <Form>
                    <BlockDataForm
                        schema={schema}
                        title={schema.title}
                        onChangeField={(id, value) => {
                            props.onChangeBlock(props.block, {
                                ...props.data,
                                [id]: value,
                            });
                        }}
                        formData={props.data}
                        block={props.block}
                        onChangeBlock={onChangeBlock}
                    />
                    {data.urlOption && <div className="p-4 bg-lime-100 border border-solid border-lime-700 rounded-md"><ObjectListWidget
                        id="links"
                        title="Adicionar link"
                        block={props.block}
                        fieldSet={'default'}
                        schema={LinkSchema()}
                        onChange={(id, value) => {
                            props.onChangeBlock(props.block, { ...props.data, [id]: value });
                        }}
                        value={props.data.links}
                    /></div>}
                    <div className="p-4 bg-indigo-100 border border-solid border-indigo-700 rounded-md"><ObjectListWidget
                        id="contact"
                        title="Adicionar bloco de texto"
                        block={props.block}
                        fieldSet={'default'}
                        schema={ContactSchema()}
                        schemaExtender={SchemaExtender}
                        onChange={(id, value) => {
                            props.onChangeBlock(props.block, { ...props.data, [id]: value });
                        }}
                        value={props.data.contact}
                    /></div>
                </Form>
            </div>
        </Segment.Group>
    );
};