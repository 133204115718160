import React, { useState } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import GridButtonsView from './ButtonsView';
import GridButtonsBlockData from './Data';
import { IconsName } from '../../assets/icons/buttons';

export const IconsVisualizer = (props) => {

  const [open, setOpen] = useState(false);

  const { data } = props;

  const iconColor = {
    'black': '#000000',
    'blue': '#005DB7',
  }

  return (
    <div className="flex flex-col gap-y-4 mt-8">
      <button className="w-[200px] rounded-xl p-4 border-solid border-2 hover:font-bold text-amber-700 border-amber-700 bg-amber-100" onClick={() => setOpen(!open)}>Abrir/fechar correspondência entre ícones e seus nomes</button>
      {open &&
        <div className="flex flex-wrap gap-x-4 bg-amber-50 border-solid border-amber-700 border-2 rounded-xl p-4">{Object.entries(IconsName).map(([key, value]) => {
          return (
            <div className="flex flex-col gap-y-2 ">
              <div className="flex justify-center items-center">
                {value({ color: data.color ? iconColor[data.color] : '#000000', className: "w-[35px] h-[35px]" })}
              </div>
              <p className="break-all sm:break-normal text-center font-bold text-amber-900 max-w-[120px]">
                {key}
              </p>
            </div>
          )
        })}</div>}
    </div>
  )
}

const GridButtonsEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <GridButtonsView {...props} isEditMode={true} />
      < IconsVisualizer {...props} />
      <SidebarPortal selected={selected}>
        <GridButtonsBlockData {...props} />
      </SidebarPortal>
    </>
  );
};

export default GridButtonsEdit;
