import { twMerge } from 'tailwind-merge';

export const IconHome = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2916 21.051L21.8472 10.1761C23.0256 8.96204 24.9742 8.96204 26.1526 10.1761L36.7081 21.051C37.2515 21.6108 37.5554 22.3603 37.5554 23.1405V37.0416C37.5554 38.6984 36.2123 40.0416 34.5554 40.0416H13.4443C11.7875 40.0416 10.4443 38.6984 10.4443 37.0416V23.1405C10.4443 22.3603 10.7483 21.6108 11.2916 21.051Z" stroke={color} strokeWidth="2.5" />
      <path d="M8.75 23.5138L21.8577 10.1434C23.0339 8.94367 24.9661 8.94367 26.1423 10.1434L39.25 23.5138" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  )
}

