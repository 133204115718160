import { IconRedirect } from '../../../../../addons/utfpr_components/src/assets/icons/buttons/IconRedirect';
import { DarkModeButton } from '../../../../../addons/utfpr_components/src/components/DarkModeButton/DarkModeButton'
import { IconSun } from '../../../../../addons/utfpr_components/src/assets/icons/IconSun';
import { IconMoon } from '../../../../../addons/utfpr_components/src/assets/icons/IconMoon';
import { useDarkModeContext } from '../../../../../store/DarkModeStore';
import { useContext } from 'react';

export const ProfileMobile = () => {

  const { darkMode, setDarkMode } = useContext(useDarkModeContext);

  const toggleDarkMode = () => {
    if (darkMode === 'on') {
      setDarkMode('off');
    } else {
      setDarkMode('on');
    }
  }

  return (
    <div className="flex flex-col items-center max-w-sm mx-auto">
      <img className="rounded-full h-20 w-20" src="https://picsum.photos/200/300" />
      <h1 className="mt-5 title_large-web_bold text-[#464646] dark:text-[#F8FAFA]">Aluno</h1>
      <h1 className="mt-2 label_large-medium text-[#7A7A7A] dark:text-[#F8FAFA]">Curso</h1>
      <div className="w-full flex flex-col gap-y-5 mt-5">
        <select defaultValue={"Eu sou"} className={`border-[#C1C1C1] border-solid border bg-[#F8FAFA] font-dsButton px-3 py-2 rounded-md w-full`}>
          <option className="font-extrabold" disabled>Eu sou</option>
          <option value="estudante">Estudante</option>
          <option value="servidor">Servidor</option>
          <option value="terceirizado">Terceirizado</option>
          <option value="egresso">Egresso</option>
          <option value="futuroAluno">Futuro aluno</option>
        </select>
        <select defaultValue={"Cidade"} className={`border-[#C1C1C1] border-solid border bg-[#F8FAFA] font-dsButton px-3 py-2 w-full rounded-md`}>
          <option className="font-extrabold" disabled>Cidade</option>
          <option value="apucarana">Apucarana</option>
          <option value="campo-mourao">Campo Mourão</option>
          <option value="cornelio-procopio">Cornélio Procópio</option>
          <option value="curitiba">Curitiba</option>
          <option value="dois-vizinhos">Dois Vizinhos</option>
          <option value="francisco-beltrao">Francisco Beltrão</option>
          <option value="guarapuava">Guarapuava</option>
          <option value="londrina">Londrina</option>
          <option value="medianeira">Medianeira</option>
          <option value="pato-branco">Pato Branco</option>
          <option value="ponta-grossa">Ponta Grossa</option>
          <option value="reitoria">Reitoria</option>
          <option value="santa-helena">Santa Helena</option>
          <option value="toledo">Toledo</option>
        </select>
      </div>
      <hr className="w-full border-t border-solid border-black/20 my-7" />
      <div className="w-full flex flex-col items-start gap-y-5">
        <a className="flex items-center gap-x-4" href="/alunos/portal-do-aluno"><IconRedirect size="32" className="[&_path]:stroke-[#2E3132]" /><p className="title_small-mobile_medium dark:text-[#F8FAFA]">Ir para o portal do aluno</p></a>
        <div className="w-full flex justify-between items-center">
          <button className="flex items-center gap-x-4" onClick={toggleDarkMode}>
            {darkMode === 'on' ? <IconMoon className="dark:[&_path]:stroke-[#A9C7FF]" /> : <IconSun className="dark:[&_path]:stroke-red-600" />}
            <p className="title_small-mobile_medium dark:text-[#F8FAFA]">{darkMode === 'on' ? 'Modo escuro' : 'Modo claro'}</p>
          </button>
          <DarkModeButton className="w-16 h-9" darkMode={darkMode} setDarkMode={setDarkMode} /></div>
      </div>
    </div>
  )
}