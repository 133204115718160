//@ts-check
import React from 'react';

import { useState } from 'react';
//@ts-ignore
import ContainerEdit from '@plone/volto/components/manage/Blocks/Container/Edit';
import { TwoColumnsPropertiesContext } from './twoColumnsPropertiesContext';

export const TwoColumnsEdit = (props) => {
  const [selectedBlock, setSelectedBlock] = useState(null);
  return (
    <TwoColumnsPropertiesContext.Provider value={props.blocksConfig}>
      <div
        className="w-full min-h-[20rem] [&>div>fieldset>div]:grid [&>div>fieldset>div]:gap-16 [&>div>fieldset>div]:grid-cols-1 sm:[&>div>fieldset>div]:grid-cols-2 [&>div>fieldset>div>div>div>.remove-block-button]:!inline-block"
        onClick={(e) => {
          if (!e) setSelectedBlock(null);
        }}
        role="presentation"
      >
        <ContainerEdit
          {...props}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}
          direction="horizontal"
        />
      </div>
    </TwoColumnsPropertiesContext.Provider>
  );
};
