//@ts-check
import React, { useState } from 'react';
import { CustomContainer } from '../general/';
import { SocialMediaWindow } from './SocialMediaWindow/SocialMediaWindow';
import { SocialMediaBar } from './SocialMediaBar/SocialMediaBar';

/**
 *
 * @typedef {import("./type").SocialMediaData} SocialMediaData
 * @typedef {import('./type').SocialMediaWindowType} SocialMediaWindowType
 * @typedef {import('../general/type').VoltoBlockProps<SocialMediaData>} SocialMediaBlockProps
 *
 * @type {React.FC<SocialMediaBlockProps>}
 */
export const SocialMediaView = (props) => {
  /** @type {[SocialMediaWindowType,React.Dispatch<SocialMediaWindowType>]} */
  const [card, setCard] = useState(
    props.data.showFacebook
      ? 'facebook'
      : props.data.showInstagram
      ? 'instagram'
      : props.data.showX
      ? 'x'
      : props.data.showYoutube
      ? 'youtube'
      : undefined,
  );

  return (
    <CustomContainer
      size="pesquisa-extensao"
      alignCenter={true}
      className=" flex flex-col gap-4 "
    >
      {/** component size MUST be defined by custom container */}
      {/** upper bar */}
      <SocialMediaBar
        {...props.data}
        setSelected={setCard}
        selected={card}
      ></SocialMediaBar>
      {/** lower window */}
      <SocialMediaWindow
        editable={props.editable}
        type={card}
        xPostLink={props.data.xPostLink}
      ></SocialMediaWindow>
    </CustomContainer>
  );
};
