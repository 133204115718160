// Function to format date
export const formatDate = (date, format = 'dd/MM/yyyy HH:mm') => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const hours = d.getHours();
  const minutes = d.getMinutes();

  const formattedDate = format
    .replace('yyyy', year)
    .replace('MM', month.toString().padStart(2, '0'))
    .replace('dd', day.toString().padStart(2, '0'))
    .replace('HH', hours.toString().padStart(2, '0'))
    .replace('mm', minutes.toString().padStart(2, '0'));

  return formattedDate;
};

// Function to get month and date
export const getMonthAndDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString('default', { month: 'short' });
  const day = d.getDate();

  const formattedDate = `${day} ${month}`;

  return formattedDate;
};

export const getFullDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString('default', { month: 'short' });
  const day = d.getDate();
  const year = d.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
};