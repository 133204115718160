//@ts-check
import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * @typedef {"center"|"left"|"right"} Align
 * @typedef CustomContainer
 * @prop {React.ReactNode} children
 * @prop {React.HTMLAttributes<HTMLDivElement>["className"]} className
 * @prop {boolean|undefined} alignCenter
 * @returns
 * @param {CustomContainer} props
 */
export const PesquisaInstitucionalContainer = (props) => {
  return (
    <div
      className={twMerge(
        `custom-container pesquisa-institucional-container w-full px-4 [&_.custom-container]:px-0 sm:px-0 sm:w-[514.487725px] md:w-[610.487725px] lg:w-[593.987725px] xl:w-[785.987725px] 2xl:w-[977.987725px] transition-[0.5s]  ${
          props.alignCenter
            ? ' mx-auto [&_.pesquisa-institucional-container]:static relative sm:left-[48.7561375px] md:left-[64.7561375px] lg:left-[62.0061375px] xl:left-[94.0061375px] 2xl:left-[126.0061375px] '
            : ''
        }`,
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
