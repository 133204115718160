import { twMerge } from 'tailwind-merge';

export const IconHeadset = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 26.4474V21.5526C11 18.0909 12.3696 14.7709 14.8076 12.323C17.2456 9.87518 20.5522 8.5 24 8.5C27.4478 8.5 30.7544 9.87518 33.1924 12.323C35.6304 14.7709 37 18.0909 37 21.5526V26.4474M11 26.4474C11 25.5819 11.3424 24.7519 11.9519 24.14C12.5614 23.528 13.388 23.1842 14.25 23.1842H15.875C16.737 23.1842 17.5636 23.528 18.1731 24.14C18.7826 24.7519 19.125 25.5819 19.125 26.4474V31.3421C19.125 32.2075 18.7826 33.0375 18.1731 33.6495C17.5636 34.2615 16.737 34.6053 15.875 34.6053H14.25C13.388 34.6053 12.5614 34.2615 11.9519 33.6495C11.3424 33.0375 11 32.2075 11 31.3421V26.4474ZM37 26.4474C37 25.5819 36.6576 24.7519 36.0481 24.14C35.4386 23.528 34.612 23.1842 33.75 23.1842H32.125C31.263 23.1842 30.4364 23.528 29.8269 24.14C29.2174 24.7519 28.875 25.5819 28.875 26.4474V31.3421C28.875 32.2075 29.2174 33.0375 29.8269 33.6495C30.4364 34.2615 31.263 34.6053 32.125 34.6053H33.75M37 26.4474V31.3421C37 32.2075 36.6576 33.0375 36.0481 33.6495C35.4386 34.2615 34.612 34.6053 33.75 34.6053M33.75 34.6053C33.75 37.3088 29.3852 39.5 24 39.5" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

