import { twMerge } from 'tailwind-merge';

export const IconShare = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0588 8.00011C17.3979 8.00011 19.2941 9.89632 19.2941 12.2354C19.2941 14.5745 17.3979 16.4707 15.0588 16.4707C12.7197 16.4707 10.8235 14.5745 10.8235 12.2354C10.8235 9.89632 12.7197 8.00011 15.0588 8.00011Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.1765 31.5294C15.5155 31.5294 17.4118 33.4256 17.4118 35.7647C17.4118 38.1038 15.5155 40 13.1765 40C10.8374 40 8.94116 38.1038 8.94116 35.7647C8.94116 33.4256 10.8374 31.5294 13.1765 31.5294Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.8234 22.1175C37.1625 22.1175 39.0587 24.0138 39.0587 26.3528C39.0587 28.6919 37.1625 30.5881 34.8234 30.5881C32.4843 30.5881 30.5881 28.6919 30.5881 26.3528C30.5881 24.0138 32.4843 22.1175 34.8234 22.1175Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.8235 14.5881L31.0588 23.5293" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
      <path d="M17.8823 33.8823L31.0588 28.7058" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  )
}

