//@ts-check
import React from 'react';
import { IconYoutube } from '../../../assets/icons/buttons/IconYoutube';
import { IconFacebook } from '../../../assets/icons/buttons/IconFacebook';
import { IconInstagram } from '../../../assets/icons/buttons/IconInstagram';
import { IconTwitter } from '../../../assets/icons/buttons/IconTwitter';

/**
 * @typedef {{className: any,
 * changeColorOnHover?: boolean,
 * color?: string,
 * size?: string,}}IconProps
 *
 *
 * @typedef {import('../type').SocialMediaWindowType} SocialMediaWindowType
 *
 * @typedef SocialMediaBarProps
 * @prop {boolean} showFacebook
 * @prop {boolean} showInstagram
 * @prop {boolean} showX
 * @prop {boolean} showYoutube
 * @prop {(arg:any)=>any} setSelected
 * @prop {SocialMediaWindowType} selected
 */

/**
 * @type {React.FC<SocialMediaBarProps>}
 */
export const SocialMediaBar = (props) => {
  return (
    <div className="w-full flex justify-between">
      {props.showFacebook ? (
        <BarTab
          value="facebook"
          name="facebook"
          setSelected={props.setSelected}
          selected={props.selected}
          icon={IconFacebook}
        ></BarTab>
      ) : (
        ''
      )}
      {props.showInstagram ? (
        <BarTab
          value="instagram"
          name="instagram"
          setSelected={props.setSelected}
          selected={props.selected}
          icon={IconInstagram}
        ></BarTab>
      ) : (
        ''
      )}
      {props.showX ? (
        <BarTab
          value="x"
          name="twitter"
          setSelected={props.setSelected}
          selected={props.selected}
          icon={IconTwitter}
        ></BarTab>
      ) : (
        ''
      )}
      {props.showYoutube ? (
        <BarTab
          value="youtube"
          name="youtube"
          setSelected={props.setSelected}
          selected={props.selected}
          icon={IconYoutube}
        ></BarTab>
      ) : (
        ''
      )}
    </div>
  );
};

/**
 *
 * @type {React.FC<{
 * icon:(props:IconProps)=>React.JSX.Element
 * value:SocialMediaWindowType,
 * name:string,
 * setSelected:(arg:any)=>any,
 * selected:SocialMediaWindowType
 * }>}
 */
const BarTab = (props) => {
  return (
    <button
      className={`${
        props.selected == props.value ? 'border-b-4' : ''
      } border-[#FFBE00] w-full p-4 text-center [&_path]:stroke-[rgba(0,0,0,0.87)] dark:text-[rgb(248,250,250)] hover:text-[#FFBE00] dark:[&:hover_path]:stroke-[#FFBE00] [&:hover_path]:stroke-[#FFBE00] [&_*]:mx-auto flex flex-col`}
      onClick={() => {
        props.setSelected(props.value);
      }}
    >
      {props.icon({ changeColorOnHover: true, color: '#000', className: '' })}
      <span className="capitalize">{props.name}</span>
    </button>
  );
};
