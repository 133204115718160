import { twMerge } from 'tailwind-merge';

export const IconWifi = ({ className, changeColorOnHover = false, size = '48', color = '#005DB7' }) => {
  return (
    <svg className={twMerge(changeColorOnHover && 'peer-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-[#A9C7FF]', className)} width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 36.1234H24.0294M15.689 27.5466C17.8934 25.2723 20.8829 23.9947 24 23.9947C27.1171 23.9947 30.1066 25.2723 32.311 27.5466M7.375 18.9667C9.55819 16.7135 12.15 14.9262 15.0026 13.7069C17.8551 12.4875 20.9124 11.8599 24 11.8599C27.0876 11.8599 30.1449 12.4875 32.9974 13.7069C35.85 14.9262 38.4418 16.7135 40.625 18.9667" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.5508 36.1233V36.1401" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

