/**
 * View title/description block.
 * @module volto-slate/blocks/Title/TitleBlockView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { CustomContainer } from 'addons/utfpr_components/src/components/general/CustomContainers/CustomContainer';

/**
 * View title block component.
 * @class View
 * @extends Component
 */
const TitleBlockView = ({ properties, metadata, path }) => {
  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
      className="flex flex-col justify-start items-start gap-[10px] mx-auto mt-6 mb-[30px]"
    >
      <h1 className="text-black dark:text-[#F8FAFA]" id="main-title">
        {(metadata || properties)['title'] || ''}
      </h1>
      <div className="text-[13px] text-[#7A7A7A]">
        Publicado {new Date(properties.created).toLocaleString()}, última
        modificação {new Date(properties.modified).toLocaleString()}
      </div>
    </CustomContainer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TitleBlockView.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  metadata: PropTypes.objectOf(PropTypes.any),
};

export default TitleBlockView;
