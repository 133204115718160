//@ts-check
import React, { useEffect } from 'react';
/**
 *
 * @param {{hidden:boolean}}props
 */
export const FacebookWindow = (props) => {
  useEffect(() => {
    if (document.getElementById('fb-root')) return;

    // these steps are needed so the div goes after body
    const fbDiv = createFacebookDiv();
    const fbScript = createFacebookScript();

    document.body.appendChild(fbDiv);

    document.body.appendChild(fbScript);

    return () => {
      document.body.removeChild(fbDiv);
      document.body.removeChild(fbScript);
    };
  }, []);
  // this code was generated via https://developers.facebook.com/docs/plugins/page-plugin/?locale=pt_BR
  /*
    facebook plugin does not have a reponsivity system , so i just generated mutiple cards with diferent sizes
  */
  return (
    <div
      className={`fb-page w-[500px] mx-auto my-auto ${
        props.hidden ? '!hidden' : ''
      }`}
      data-href="https://www.facebook.com/UTFPR/?locale=pt_BR"
      data-tabs=""
      data-width={'480px'}
      data-height={'270px'}
      data-small-header="false"
      data-adapt-container-width="false"
      data-hide-cover="false"
      data-show-facepile="false"
    >
      <blockquote
        cite="https://www.facebook.com/UTFPR/?locale=pt_BR"
        className="fb-xfbml-parse-ignore"
      >
        <a href="https://www.facebook.com/UTFPR/?locale=pt_BR">
          UTFPR - Universidade Tecnológica Federal do Paraná
        </a>
      </blockquote>
    </div>
  );
};
function createFacebookDiv() {
  const fbDiv = document.createElement('div');

  fbDiv.id = 'fb-root';

  return fbDiv;
}

function createFacebookScript() {
  const fbScript = document.createElement('script');

  fbScript.src =
    'https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v19.0';

  fbScript.defer = true;

  fbScript.crossOrigin = 'anonymous';

  fbScript.async = true;

  fbScript.nonce = 'VW8UAD84';

  return fbScript;
}
