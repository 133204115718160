//@ts-check
import React from 'react';
//@ts-ignore
import config from '@plone/volto/registry';
//@ts-ignore
import { RenderBlocks } from '@plone/volto/components';

export const SingleColumnView = (props) => {
  const { data, path, className } = props;
  const metadata = props.metadata || props.properties;
  const blocksConfig =
    config.blocks.blocksConfig[data['@type']].blocksConfig ||
    props.blocksConfig;
  const location = {
    pathname: path,
  };
  return (
    <div className="w-full flex flex-col gap-4">
      <RenderBlocks
        {...props}
        metadata={metadata}
        content={data}
        location={location}
        blocksConfig={blocksConfig}
        isContainer
      />
    </div>
  );
};
