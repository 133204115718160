//@ts-check

import {
  getAuthToken,
  getBaseUrl,
  //@ts-ignore
} from '@plone/volto/helpers';
//@ts-ignore
import config from '@plone/volto/registry';
import { readAsDataURL } from 'promise-file-reader';
/**
 *
 * @param {FileList} files
 * @param {URL} page
 */
export async function uploadImage(files, page) {
  const token = getAuthToken();

  const apiPath =
    config['_data'].settings.apiPath + '/++api++' + getBaseUrl(page.pathname);

  const imgURL = await readAsDataURL(files[0]).then(async (data) => {
    const fields = data.match(/^data:(.*);(.*),(.*)$/);

    const body = {
      '@type': 'Image',
      title: files[0].name,
      image: {
        data: fields[3],
        encoding: fields[2],
        'content-type': fields[1],
        filename: files[0].name,
      },
    };

    try {
      const res = await fetch(apiPath, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (res.ok) return getImageURL(files[0].name, page);

      return '';
    } catch (e) {
      console.error(e);
      return '';
    }
  });

  return imgURL;
}

/**
 *
 * @param {string} fileName
 * @param {URL} page
 */
function getImageURL(fileName, page) {
  const baseURL = config['_data'].settings.apiPath + getBaseUrl(page.pathname);

  const dots = fileName.toLowerCase().split('.');

  if (dots.length < 2) return '';

  let cleanName = dots[0];

  for (let i = 1; i < dots.length - 1; i++) cleanName += '-' + dots[i];

  cleanName += '.' + dots[dots.length - 1];

  return baseURL + '/' + cleanName;
}
