//@ts-check
/**
 * View video block.
 * @module components/manage/Blocks/Video/View
 */

import React from 'react';
import PropTypes from 'prop-types';
//@ts-ignore
import Body from '@plone/volto/components/manage/Blocks/Video/Body';
//@ts-ignore
import { withBlockExtensions } from '@plone/volto/helpers';
import { CustomContainer } from '../../../../../addons/utfpr_components/src/components/general';

/**
 * View video block class.
 * @class View
 * @typedef VideoPropsData
 * @prop {undefined|'right'|'left'|'full'} align
 *  - undefined = center
 *  - 'right' = right
 *  - 'left' = left
 *  - 'center' = center
 * @typedef {object} VideoProps
 * @prop {VideoPropsData} data
 * @prop {boolean} isEditMode
 * @prop {string} className
 * @param {VideoProps} props
 */
const View = (props) => {
  const { data, className } = props;

  return (
    <CustomContainer
      size={translate(data.align)}
      alignCenter={true}
      className={`${data.align == 'left' ? 'grid grid-cols-2 ' : ''} ${
        data.align == 'right' ? 'grid grid-cols-2' : ''
      }`}
    >
      <div className={`${data.align == 'right' ? 'col-start-2' : ''}`}>
        <Body data={data} />
      </div>
    </CustomContainer>
  );
};

/**
 * @returns {(import('../../../../../addons/utfpr_components/src/components/general/CustomContainers/types').Sizes)}
 * - size of a container
 * - align using classes of the video
 * @param {VideoPropsData['align']}sizeAndAlign
 */
export function translate(sizeAndAlign) {
  switch (sizeAndAlign) {
    case 'full':
      return 'full-width';
    case 'left':
      return 'ensino-institucional';
    case 'right':
      return 'ensino-institucional';
  }

  return 'ensino-institucional';
}

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
